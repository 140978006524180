//TODO: overzicht maken van alle ids van textures
export enum DecorTexture {
  placeholder = "placeholder",
  placeholderTexture = "placeholderTexture",
  texture01 = "texture01",
  texture02 = "texture02",
  texture03 = "texture03",
  texture04 = "texture04",
  texture05 = "texture05",
  texture06 = "texture06",
  texture07 = "texture07",
  texture08 = "texture08",
  texture09 = "texture09",
  texture10 = "texture10",
  texture11 = "texture11",
  texture12 = "texture12",
  texture13 = "texture13",
  texture14 = "texture14",
  texture15 = "texture15",
  texture16 = "texture16",
  texture17 = "texture17",
  texture18 = "texture18",
  texture19 = "texture19",
  texture20 = "texture20",
  texture21 = "texture21",
  texture22 = "texture22",
  texture23 = "texture23",
}
