import { AquariumType } from "../@types/aquarium";
import { ImageOption } from "../@types/configurator";
import rimlessImg from "../images/aquariumtypes/rimless.png";
import lightcoverImg from "../images/aquariumtypes/lightcover.png";
import fullglassImg from "../images/aquariumtypes/fullglass.png";
import turtleImg from "../images/aquariumtypes/turtles.png";
import { AllTextObject } from "../@types/language";

export const aquariumTypeOptions = (
  allText: AllTextObject
): Array<ImageOption> => {
  return [
    {
      id: AquariumType.rimless,
      img: rimlessImg,
      title: allText.tid_st2_07,
    },
    {
      id: AquariumType.lightCover,
      img: lightcoverImg,
      title: allText.tid_st2_08,
    },
    {
      id: AquariumType.fullGlass,
      img: fullglassImg,
      title: allText.tid_st2_09,
    },
    {
      id: AquariumType.turtle,
      img: turtleImg,
      title: allText.tid_st2_10,
    },
  ];
};
