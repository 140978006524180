import React from "react";
import { SiliconColor } from "../../@types/aquarium";
import { AllTextObject } from "../../@types/language";
import { changeSiliconColor } from "../aquariumComponents/siliconStrips";
import { changeSiliconOpacity } from "../aquariumComponents/tank";

type Props = {
  allText: AllTextObject;
  checked: SiliconColor;
  setValue: React.Dispatch<React.SetStateAction<SiliconColor>>;
};

function SiliconColorMenu({ allText, checked, setValue }: Props) {
  return (
    <div className="window-paint-menu-container">
      <div
        className={
          "paint-option-container " +
          (checked === SiliconColor.black ? "paint-option-active" : "")
        }
        onClick={() => {
          setValue(SiliconColor.black)
          changeSiliconOpacity(false)
        }}
      >
        <input
          type="radio"
          name="silicon-color-option"
          className="window-paint-option"
          id="silicon-color-option-black"
        />
        <label htmlFor="silicon-color-option-black">{allText.tid_gen_14}</label>
        <div className="window-paint-preview-black"></div>
      </div>
      <div
        className={
          "paint-option-container " +
          (checked === SiliconColor.transparent ? "paint-option-active" : "")
        }
        onClick={() => {
          setValue(SiliconColor.transparent)
          changeSiliconOpacity(true)
        }}
      >
        <input
          type="radio"
          name="silicon-color-option"
          className="window-paint-option"
          id="silicon-color-option-transparent"
        />
        <label htmlFor="silicon-color-option-transparent">{allText.tid_gen_15}</label>
        <div className="window-paint-preview-transparent"></div>
      </div>
    </div>
  );
}

export default SiliconColorMenu;
