import Header from "../components/organisms/Header";
import { AllTextObject } from "../@types/language";
import "../styles/landing.css";
import {
  BigText,
  SubTitle,
  Text,
  Title,
} from "../components/atoms/StyledComponents";
import { CaretRight, Cube, Gear, Info } from "@phosphor-icons/react";
import landing_animation from "../images/landing-coral-gif.gif";
import footer_image from "../images/document-footer-image.png";
import Footer from "../components/organisms/Footer";
import { Link } from "react-router-dom";

type Props = { allText: AllTextObject };

function Landing({ allText }: Props) {
  return (
    <div>
      <Header allText={allText} />
      <main className="landing-main-container">
        <div className="landing-banner-container">
          <div className="landing-banner-animation-container">
            <figure>
              <img src={landing_animation} alt="coral animation" />
            </figure>
          </div>
          <section className="landing-banner-section">
            <div className="landing-grid-item landing-grid-item-2">
              <Title>{allText.tid_lan_01}</Title>
              <Text>{allText.tid_lan_02}</Text>
              <div>
                <div className="landing-banner-info-item">
                  <CaretRight size={32} color="var(--neutral-100)" />
                  <BigText>{allText.tid_lan_03}</BigText>
                </div>
                <div className="landing-banner-info-item">
                  <CaretRight size={32} color="var(--neutral-100)" />
                  <BigText>{allText.tid_lan_04}</BigText>
                </div>
                <div className="landing-banner-info-item">
                  <CaretRight size={32} color="var(--neutral-100)" />
                  <BigText>{allText.tid_lan_05}</BigText>
                </div>
              </div>
              <div className="landing-banner-icon">
                <Cube size={196} color="var(--primary-700)" weight="thin" />
              </div>
            </div>
            <Link
              to="configurator"
              className="landing-grid-item landing-grid-item-3"
            >
              <Title>{allText.tid_lan_06}</Title>
              <Text>{allText.tid_lan_07}</Text>
              <div className="landing-banner-icon">
                <Gear size={196} color="var(--green-600)" weight="thin" />
              </div>
            </Link>
            <a
              href="https://www.aqualife-aquarium.com/webshop"
              target="_blank"
              className="landing-grid-item landing-grid-item-4"
              rel="noreferrer"
            >
              <Title>{allText.tid_lan_08}</Title>
              <Text>{allText.tid_lan_09}</Text>
              <div className="landing-banner-icon">
                <Gear size={196} color="var(--ocean-600)" weight="thin" />
              </div>
            </a>
            <div className="landing-grid-item landing-grid-item-5">
              <Text>{allText.tid_lan_10}</Text>
              <div className="landing-banner-icon">
                <Info size={196} color="var(--primary-700)" weight="thin" />
              </div>
            </div>
            <div
              className="landing-grid-item landing-grid-item-6"
              style={{ padding: 0 }}
            >
              <iframe
                title="Bedrijfsvideo Aqualife"
                src="https://www.youtube.com/embed/6jvojEDlWL8"
                frameBorder="0"
                allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen={true}
                height={"100%"}
              ></iframe>
            </div>
          </section>
        </div>
        <section className="landing-info-section">
          <div className="landing-info-content-container">
            <SubTitle color="var(--neutral-900)">{allText.tid_lan_11}</SubTitle>
            <Text color="var(--neutral-900)">{allText.tid_lan_12}</Text>
            <Text color="var(--neutral-900)">{allText.tid_lan_13}</Text>
            <Text color="var(--neutral-900)">{allText.tid_lan_14}</Text>
            <Text color="var(--neutral-900)">{allText.tid_lan_15}</Text>
          </div>
          <figure className="landing-info-figure">
            <img
              src={footer_image}
              alt="Footer-image"
              className="landing-info-img"
            />
          </figure>
        </section>
        <Footer allText={allText} />
      </main>
    </div>
  );
}

export default Landing;
