import React, { useEffect } from "react";
import { SubTitle, Text } from "../atoms/StyledComponents";
import {
  ArrowLeft,
  CheckCircle,
  WarningCircle,
  CircleDashed,
  Envelope,
  Phone,
  Storefront,
} from "@phosphor-icons/react";
import {
  setOpenHelpModal,
  setOpenProductInfoModal,
} from "../../redux/configuratorSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";
import StepStatusIcon from "../atoms/StepStatusIcon";
import { AllTextObject } from "../../@types/language";

type Props = { allText: AllTextObject };

function HelpModal({ allText }: Props) {
  const showHelpModal = useSelector(
    (state: RootState) => state.configurator.showHelpModal
  );
  const dispatch = useDispatch();
  return (
    <>
      {showHelpModal && (
        <div className="textures-modal-container">
          <div className="textures-modal-content-container">
            <div className="textures-modal-header">
              <ArrowLeft
                size={32}
                weight="bold"
                onClick={() => dispatch(setOpenHelpModal(false))}
                className="texture-modal-arrow"
              />
              <SubTitle color="var(--neutral-800)">
                {allText.tid_hlp_01}
              </SubTitle>
            </div>
            <div className="product-info-content-container">
              <div className="help-text-container">
                <h3>{allText.tid_hlp_02}</h3>
                <Text color="var(--neutral-900)" weight={400}>
                  {allText.tid_hlp_03}
                </Text>
                <ul className="help-modal-list">
                  <li>
                    <CircleDashed
                      size={24}
                      weight="bold"
                      color="var(--primary-300)"
                    />
                    {allText.tid_hlp_05}
                  </li>
                  <li>
                    <CheckCircle
                      size={24}
                      weight="bold"
                      color="var(--green-500)"
                    />
                    {allText.tid_hlp_06}
                  </li>
                  <li>
                    <WarningCircle
                      size={24}
                      weight="bold"
                      color="var(--red-500)"
                    />
                    {allText.tid_hlp_07}
                  </li>
                </ul>
                <Text color="var(--neutral-900)" weight={400}>
                  {allText.tid_hlp_04}
                </Text>
              </div>
              <div className="help-text-container">
                <h3>{allText.tid_hlp_08}</h3>
                <Text color="var(--neutral-900)" weight={400}>
                  {allText.tid_hlp_09}
                </Text>
                <ul className="help-modal-list">
                  <li>
                    <Envelope />
                    <a href={`mailto:${allText.tid_hlp_10}`}>
                      {allText.tid_hlp_10}
                    </a>
                  </li>
                  <li>
                    <Phone />
                    <a href={`tel:${allText.tid_hlp_11}`}>
                      {allText.tid_hlp_11}
                    </a>
                  </li>
                  <li>
                    <Storefront />
                    {allText.tid_hlp_12}
                  </li>
                </ul>
                <h3>{allText.tid_hlp_13}</h3>
                <Text color="var(--neutral-900)" weight={400}>
                  {allText.tid_hlp_14}
                </Text>
                <ul className="help-modal-list">
                  <li>{allText.tid_hlp_15}</li>
                  <li>{allText.tid_hlp_16}</li>
                  <li>{allText.tid_hlp_17}</li>
                  <li>{allText.tid_hlp_18}</li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default HelpModal;
