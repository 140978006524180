import {
  DownloadSimple,
  Gear,
  LineVertical,
  MagicWand,
  PhoneIncoming,
  Truck,
} from "@phosphor-icons/react";
import React from "react";
import { SmallerText } from "../atoms/StyledComponents";
import { AllTextObject } from "../../@types/language";

type Props = { allText: AllTextObject; step: number };

function OrderProcess({ allText, step }: Props) {
  return (
    <div className="order-process-container">
      <div
        className="order-process-step"
        style={{ opacity: step > 0 ? 1 : 0.5 }}
      >
        <DownloadSimple size={40} weight="thin" color="var(--neutral-100)" />
        <SmallerText weight={600}>{allText.tid_var_07}</SmallerText>
      </div>
      <LineVertical
        size={40}
        weight="thin"
        color="var(--neutral-100)"
        opacity={step > 1 ? 1 : 0.5}
      />
      <div
        className="order-process-step"
        style={{ opacity: step > 1 ? 1 : 0.5 }}
      >
        <Gear size={40} weight="thin" color="var(--neutral-100)" />
        <SmallerText weight={600}>{allText.tid_var_08}</SmallerText>
      </div>
      <LineVertical
        size={40}
        weight="thin"
        color="var(--neutral-100)"
        opacity={step > 2 ? 1 : 0.5}
      />
      <div
        className="order-process-step"
        style={{ opacity: step > 2 ? 1 : 0.5 }}
      >
        <MagicWand size={40} weight="thin" color="var(--neutral-100)" />
        <SmallerText weight={600}>{allText.tid_var_09}</SmallerText>
      </div>
      <LineVertical
        size={40}
        weight="thin"
        color="var(--neutral-100)"
        opacity={step > 3 ? 1 : 0.5}
      />
      <div
        className="order-process-step"
        style={{ opacity: step > 3 ? 1 : 0.5 }}
      >
        <PhoneIncoming size={40} weight="thin" color="var(--neutral-100)" />
        <SmallerText weight={600}>{allText.tid_var_10}</SmallerText>
      </div>
      <LineVertical
        size={40}
        weight="thin"
        color="var(--neutral-100)"
        opacity={step > 4 ? 1 : 0.5}
      />
      <div
        className="order-process-step"
        style={{ opacity: step > 4 ? 1 : 0.5 }}
      >
        <Truck size={40} weight="thin" color="var(--neutral-100)" />
        <SmallerText weight={600}>{allText.tid_var_11}</SmallerText>
      </div>
    </div>
  );
}

export default OrderProcess;
