import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import React, { useMemo, useState } from "react";
import { OfferteData } from "../../@types/user";
import { Check, X } from "@phosphor-icons/react";
import { getIsAdvancedPaidIcon, getIsPaidIcon } from "../../utils/dashboard";
import { AG_GRID_LOCALE_NL } from "@ag-grid-community/locale";
import ReminderButton from "../atoms/ReminderButton";
import ReactCountryFlag from "react-country-flag";

type Props = {
  offerte: OfferteData;
  setShowOfferteDetails: React.Dispatch<
    React.SetStateAction<OfferteData | null>
  >;
};

function GridDetails({ offerte, setShowOfferteDetails }: Props) {
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: false,
      resizable: false,
      suppressHeaderFilterButton: true,
    };
  }, []);

  const OfferteLinkButton = () => {
    return <button className="send-reminder-button">Offertelink</button>;
  };

  const expandCollapseButton = () => {
    return (
      <X
        size={18}
        weight="bold"
        className="grid-cross"
        onClick={() => setShowOfferteDetails(null)}
      />
    );
  };
  const renderCountryFlag = (countryCode: any) => {
    return (
      <ReactCountryFlag
        countryCode={countryCode}
        svg
        style={{
          width: "1.5em",
          height: "1.5em",
        }}
      />
    );
  };

  const [rowData, setRowData] = useState<OfferteData[]>([offerte]);
  const [colDefsMaster, setColDefsMaster] = useState<any>([
    { field: "offerteNumber", headerName: "#" },
    {
      field: "countryFlag",
      headerName: "",
      flex: 1,
      cellRenderer: (p: any) => renderCountryFlag(p.data.country),
      suppressHeaderFilterButton: true,
    },
    { field: "firstname", headerName: "Voornaam", flex: 2 },
    { field: "lastname", headerName: "Achternaam", flex: 2 },
    { field: "email", headerName: "Email", flex: 3 },
    {
      field: "price",
      headerName: "Prijs",
      valueFormatter: (p: any) => "€" + p.value.toLocaleString(),
      flex: 2,
    },
    {
      field: "isPaid",
      headerName: "Besteld?",
      cellRenderer: (p: any) => getIsPaidIcon(p.data.isPaid),
    },
    { field: "date", headerName: "Datum", flex: 2 },
    {
      field: "reminderSend",
      headerName: "Reminder",
      cellRenderer: (p: any) => (
        <ReminderButton reminderDate={p.data.reminderSend} />
      ),
      flex: 2,
    },
    { field: "", cellRenderer: expandCollapseButton },
  ]);

  const [colDefsDetails, setColDefsDetails] = useState<any>([
    { field: "phonenumber", headerName: "Telefoonnr.", flex: 3 },
    { field: "postalcode", headerName: "Postcode", flex: 2 },
    { field: "city", headerName: "Woonplaats", flex: 2 },
    { field: "street", headerName: "Straatnaam", flex: 2 },
    { field: "houseNumber", headerName: "huisnr.", flex: 2 },
    { field: "country", headerName: "Land", flex: 2 },
    {
      field: "advancePaymentPaid",
      headerName: "Voorschot",
      flex: 2,
      cellRenderer: (p: any) => getIsAdvancedPaidIcon(p.data.isPaid),
    },
    {
      field: "offerteLink",
      headerName: "Offertelink",
      cellRenderer: OfferteLinkButton,
      flex: 2,
    },
  ]);
  return (
    <div className="grid-details-container">
      <div className="grid-details-content-container">
        <div style={{ height: 93 }} className="master-grid">
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefsMaster}
            pagination={false}
            defaultColDef={defaultColDef}
            enableCellTextSelection={true}
            localeText={AG_GRID_LOCALE_NL}
          />
        </div>
        <div style={{ height: 44 }} className="details-grid">
          <AgGridReact
            rowData={rowData}
            columnDefs={colDefsDetails}
            pagination={false}
            defaultColDef={defaultColDef}
            headerHeight={18}
            rowHeight={25}
            enableCellTextSelection={true}
            localeText={AG_GRID_LOCALE_NL}
          />
        </div>
      </div>
    </div>
  );
}

export default GridDetails;
