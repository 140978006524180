import { FacebookLogo, Question } from "@phosphor-icons/react";
import React from "react";
import { Text } from "../atoms/StyledComponents";
import HIB_logo from "../../images/handmade-in-belgium.png";
import { useDispatch } from "react-redux";
import { setOpenHelpModal } from "../../redux/configuratorSlice";
import { AllTextObject } from "../../@types/language";

type Props = {allText: AllTextObject};

function ConfiguratorMenuFooter({allText}: Props) {
  const dispatch = useDispatch();

  const handleOpenHelpModal = () => {
    dispatch(setOpenHelpModal(true));
  };

  return (
    <div className="configurator-footer-container">
      <div onClick={() => handleOpenHelpModal()} className="configurator-footer-help-button">
        <Question size={24} />
        <Text color="var(--neutral-500)">{allText.tid_con_07}</Text>
      </div>
      <div>
        <figure className="configurator-footer-figure">
          <img
            className="configurator-footer-img"
            src={HIB_logo}
            alt="Handmade in Belgium logo"
          />
        </figure>
        <Text color="var(--neutral-500)">{allText.tid_con_08}</Text>
      </div>
      <a href="https://www.facebook.com/AqualifeBelgium/" target="_blank">
        <FacebookLogo size={24} />
      </a>
    </div>
  );
}

export default ConfiguratorMenuFooter;
