import { SelectOption } from "../@types/configurator";
import { UserRole } from "../@types/user";

export const userRoleOptions: SelectOption[] = [
  {
    value: UserRole.owner,
    title: "Beheerder",
  },
  {
    value: UserRole.resellerOne,
    title: "Verdeler 1",
  },
  {
    value: UserRole.resellerTwo,
    title: "verdeler 2",
  },
];
