import React from "react";
import { WindowPaintOption } from "../../@types/aquarium";
import { AllTextObject } from "../../@types/language";

type Props = {
  allText: AllTextObject;
  checked: WindowPaintOption;
  setValue: React.Dispatch<React.SetStateAction<WindowPaintOption>>;
};

function PaintColorMenu({ allText, checked, setValue }: Props) {
  return (
    <div className="window-paint-menu-container">
      <div
        className={
          "paint-option-container " +
          (checked === WindowPaintOption.black ? "paint-option-active" : "")
        }
        onClick={() => setValue(WindowPaintOption.black)}
      >
        <input
          type="radio"
          name="window-paint-option"
          className="window-paint-option"
          id="window-paint-option-black"
        />
        <label htmlFor="window-paint-option-black">{allText.tid_gen_14}</label>
        <div className="window-paint-preview-black"></div>
      </div>
      <div
        className={
          "paint-option-container " +
          (checked === WindowPaintOption.blue ? "paint-option-active" : "")
        }
        onClick={() => setValue(WindowPaintOption.blue)}
      >
        <input
          type="radio"
          name="window-paint-option"
          className="window-paint-option"
          id="window-paint-option-blue"
        />
        <label htmlFor="window-paint-option-blue">{allText.tid_gen_23}</label>
        <div className="window-paint-preview-blue"></div>
      </div>
    </div>
  );
}

export default PaintColorMenu;
