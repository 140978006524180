import { useState } from "react";
import { Circle } from "@phosphor-icons/react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { setActiveLanguage, setAllText } from "../../redux/languageSlice";
import { languageOptions } from "../../data/languages";
import { AllTextObject, LanguageOption } from "../../@types/language";
import { getAllTextForLanguage } from "../../utils/textRegulator";

type Props = { allText: AllTextObject; size: number };

function LanguageSelector({ allText, size }: Props) {
  const activeLanguage = useSelector(
    (state: RootState) => state.language.activeLanguage
  );
  const allTextFull = useSelector(
    (state: RootState) => state.language.allTextFull
  );
  const dispatch = useDispatch();

  const [showMenu, setShowMenu] = useState(false);

  const changeLanguage = (newLanguage: string) => {
    dispatch(setActiveLanguage(newLanguage));
    dispatch(setAllText(getAllTextForLanguage(allTextFull, newLanguage)));
  };

  const renderLanguagePreview = (activeLanguage: string): LanguageOption => {
    return languageOptions.filter(
      (language) => language.name === activeLanguage
    )[0];
  };

  const [selectedLanguage, setSelectedLanguage] = useState(
    renderLanguagePreview(activeLanguage)
  );

  return (
    <div id="language-selector">
      {selectedLanguage && (
        <figure
          style={{ width: size, height: size }}
          onClick={() => setShowMenu(!showMenu)}
        >
          <Circle size={size} className="language-selector-circle" />
          <img
            src={selectedLanguage.image}
            alt={`${selectedLanguage.name}`}
            width={`${size - size * 0.25}px`}
          />
        </figure>
      )}
      {showMenu && (
        <div id="language-selector-menu">
          {languageOptions.map((language) => {
            return (
              <figure
                key={language.name}
                style={{ width: size, height: size }}
                onClick={() => {
                  setShowMenu(!showMenu);
                  changeLanguage(language.name);
                  setSelectedLanguage(language);
                }}
              >
                <Circle size={size} className="language-selector-circle" />
                <img
                  src={language.image}
                  alt={language.name}
                  width={`${size - size * 0.25}px`}
                />
              </figure>
            );
          })}
        </div>
      )}
    </div>
  );
}

export default LanguageSelector;
