import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ArrowLeft } from "@phosphor-icons/react";
import { SubTitle } from "../atoms/StyledComponents";
import { Aquarium, FilterLocation } from "../../@types/aquarium";
import {
  setOpenFilterLocationModal,
} from "../../redux/configuratorSlice";
import ConfiguratorImageOption from "./ConfiguratorImageOption";
import { setFilterLocation } from "../../redux/aquariumSlice";
import { filterLocationOptions } from "../../data/aquarium";
import { changeFilterLocation } from "../aquariumComponents/tank";
import { checkFilterLocation } from "../../utils/configuratorRules";
import { AllTextObject } from "../../@types/language";

type Props = {allText: AllTextObject};

function FilterLocationModal({allText}: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const dispatch = useDispatch();
  const showModal = useSelector(
    (state: RootState) => state.configurator.openFilterLocationModal
  );

  const [selectedFilterLocation, setSelectedFilterLocation] =
    useState<FilterLocation | null>(aquarium.filter.filterLocation);

  useEffect(() => {
    dispatch(setFilterLocation(selectedFilterLocation));
    dispatch(setOpenFilterLocationModal(false));
    if (selectedFilterLocation) {
      changeFilterLocation(selectedFilterLocation);
    }
  }, [selectedFilterLocation]);

  useEffect(() => {
    setSelectedFilterLocation(aquarium.filter.filterLocation);
  }, [aquarium]);

  const isFilterLocation = (item: FilterLocation | any): item is FilterLocation => {
    return typeof item !== "undefined";
  };

  return (
    <div
      className="textures-modal-container"
      style={showModal ? { display: "flex" } : { display: "none" }}
    >
      <div className="textures-modal-content-container">
        <div className="textures-modal-header">
          <ArrowLeft
            size={32}
            weight="bold"
            onClick={() => dispatch(setOpenFilterLocationModal(false))}
            className="texture-modal-arrow"
          />
          <SubTitle color="var(--neutral-800)">
            {allText.tid_st4_22}
          </SubTitle>
        </div>
        <div className="optionlist-container optionlist-container-texturemodal">
          {filterLocationOptions(allText)
            .filter((option) => {
              if (isFilterLocation(option.id)) {
              const checkObj: Aquarium = {
                ...aquarium,
                filter: { ...aquarium.filter, filterLocation: option.id },
              };
              return !checkFilterLocation(checkObj);
            }})
            .map((option) => (
              <ConfiguratorImageOption
                name="type-aquarium-option"
                option={option}
                value={selectedFilterLocation}
                setValue={setSelectedFilterLocation}
                key={"type-aquarium-option-" + option.id}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default FilterLocationModal;
