import React, { useState } from "react";
import {
  SmallerText,
  StepItemExtraTitle,
  StepItemTitle,
  Text,
} from "../atoms/StyledComponents";
import { Question, WarningCircle, X } from "@phosphor-icons/react";

type Props = {
  children: JSX.Element;
  title: string;
  helptext: string;
  errortext: string;
  hasIssue: boolean;
  extraTitle?: string;
};

function StepItem({
  children,
  title,
  helptext,
  errortext,
  hasIssue,
  extraTitle,
}: Props) {
  const [showHelptext, setShowHelptext] = useState(false);
  return (
    <div
      className={
        "step-item-container " + (hasIssue ? "step-item-container-issue" : "")
      }
    >
      <div className="step-item-header">
        <StepItemTitle>{title}</StepItemTitle>
        {extraTitle && <StepItemExtraTitle>{extraTitle}</StepItemExtraTitle>}
        <Question
          className="step-item-question"
          size={20}
          onClick={() => setShowHelptext(!showHelptext)}
        />
      </div>
      {showHelptext && (
        <div className="step-item-helptext-container">
          <SmallerText color="var(--primary-600)">{helptext}</SmallerText>
          <X
            size={20}
            color="var(--primary-600)"
            className="step-item-helptext-cross"
            onClick={() => setShowHelptext(!showHelptext)}
          />
        </div>
      )}

      {hasIssue && (
        <div className="step-item-issue-container">
          <WarningCircle
            color="var(--red-500)"
            size={20}
            weight="bold"
            className="step-item-issue-icon"
          />
          <SmallerText color="var(--red-500)" className="step-item-errortext">
            {errortext}
          </SmallerText>
        </div>
      )}
      {children}
    </div>
  );
}

export default StepItem;
