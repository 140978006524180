import { ArrowLeft, Plus, Trash } from "@phosphor-icons/react";
import { useEffect, useMemo, useState } from "react";
import { AllTextObject } from "../@types/language";
import { Text, Title } from "../components/atoms/StyledComponents";
import Header from "../components/organisms/Header";
import { ColDef } from "ag-grid-enterprise";
import { superUsers } from "../data/usersDummyData";
import { SuperUser, UserRole, UserRoleOptions } from "../@types/user";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import "../styles/dataGrid.css";
import { AG_GRID_LOCALE_NL } from "@ag-grid-community/locale";
import { AgGridReact } from "ag-grid-react";
import PrimaryButton from "../components/atoms/PrimaryButton";
import SecondaryButton from "../components/atoms/SecondaryButton";
import AddUser from "../components/molecules/AddUser";
import { checkRole as checkRoleApi, getAllUsers } from "../services/api/api";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../redux/store";

type Props = { allText: AllTextObject };

function AdminUsersScreen({ allText }: Props) {
  const dispatch = useDispatch();
  const isAdmin = useSelector(
    (state: RootState) => state.configurator.checkRole
  );
  const defaultColDef = useMemo(() => {
    return {
      flex: 1,
      filter: true,
      resizable: false,
      suppressHeaderFilterButton: true,
      suppressMovable: true,
    };
  }, []);
  const [rowData, setRowData] = useState<SuperUser[] | null>(null);
  const [showDeleteModal, setShowDeleteModal] = useState<any | null>(null);
  const [showAddUserForm, setShowAddUserForm] = useState<boolean>(false);

  useEffect(() => {
    checkRoleApi(dispatch);
    // TODO DAAN: een get users route maken
    // setRowData(getSuperUsersFromDatabase())
    getAllUsers(setRowData);
  }, []);

  useEffect(() => {
    if (isAdmin !== UserRole.owner && isAdmin !== undefined) {
      window.location.href = "/";
    }
  }, [isAdmin]);

  const colDefs: ColDef<any>[] = [
    {
      field: "firstname",
      headerName: "Voornaam",
      suppressHeaderFilterButton: false,
      editable: true,
      flex: 4,
    },
    {
      field: "lastname",
      headerName: "Achternaam",
      suppressHeaderFilterButton: false,
      editable: true,
      flex: 4,
    },
    {
      field: "email",
      headerName: "Email",
      flex: 4,
      suppressHeaderFilterButton: false,
      editable: true,
    },
    {
      field: "company",
      headerName: "Bedrijf",
      suppressHeaderFilterButton: false,
      editable: true,
      flex: 4,
    },
    {
      field: "role",
      headerName: "Rol",
      suppressHeaderFilterButton: false,
      editable: true,
      cellEditor: "agSelectCellEditor",
      cellEditorParams: {
        values: UserRoleOptions,
      },
      flex: 4,
    },
    {
      field: "deleteProductButton",
      headerName: "",
      flex: 1,
      cellRenderer: (p: any) => (
        <Trash
          size={18}
          color="var(--red-500)"
          className="products-menu-delete-icon"
          onClick={() => setShowDeleteModal(p.data)}
        />
      ),
    },
  ];

  const onRowDataChanged = (e: any) => {
    if (rowData) {
      const updateEmail = e.data.email;
      const newRowData = rowData.map((row: SuperUser) =>
        row.email === updateEmail ? (row = e.data) : row
      );
      setRowData(newRowData);
      //TODO DAAN: sla hier de nieuwe gegevens van de gebruiker op in de database
      //Het json bestand ziet eruit zoals het hele SuperUser type
    }
  };

  const deleteRow = (userEmail: any) => {
    if (rowData) {
      const newRowData = rowData.filter((row) => row.email !== userEmail);
      setRowData(newRowData);
      setShowDeleteModal(null);
      //TODO DAAN: delete hier de gebruiker uit de database
      //Stuur enkel het email door van het te verwijderen item
      //in de backend/api gaat deze die dan uit de database verwijderen
    }
  };
  return (
    <>
      <Header allText={allText} />
      {isAdmin === UserRole.owner && (
        <main className="dashboard-page" id="admin-products-page">
          <a href="/dashboard" className="dashboard-goback-arrow">
            <ArrowLeft color="var(--primary-700)" size={32} weight="bold" />
          </a>
          <div className="dashboard-users-header">
            <Title color="var(--primary-700)">Gebruikers</Title>
            <Plus
              color="var(--primary-600)"
              size={28}
              weight="bold"
              className="add-product-button"
              onClick={() => setShowAddUserForm(!showAddUserForm)}
              style={{ rotate: showAddUserForm ? "z 45deg" : "z 0deg" }}
            />
          </div>
          {showAddUserForm && (
            <AddUser setShowAddUserForm={setShowAddUserForm} />
          )}
          {rowData && (
            <div className="ag-theme-quartz master-grid-container master-grid users-grid">
              {showDeleteModal && (
                <div className="grid-details-container delete-product-modal-container">
                  <div className="grid-details-content-container delete-product-modal-content-container">
                    <Text color="var(--primary-700)" weight={500}>
                      Weet je zeker dat je gebruiker {showDeleteModal.firstname}{" "}
                      van {showDeleteModal.company} wilt verwijderen?
                    </Text>
                    <div className="delete-product-modal-buttons">
                      <PrimaryButton
                        title="verwijder"
                        action={() => deleteRow(showDeleteModal.email)}
                      />
                      <SecondaryButton
                        title="annuleer"
                        action={() => setShowDeleteModal(null)}
                      />
                    </div>
                  </div>
                </div>
              )}
              <AgGridReact<SuperUser>
                rowData={rowData}
                columnDefs={colDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={20}
                paginationPageSizeSelector={[20, 35, 50]}
                rowSelection="single"
                suppressMenuHide={true}
                localeText={AG_GRID_LOCALE_NL}
                domLayout="autoHeight"
                suppressHorizontalScroll={false}
                onCellValueChanged={(e) => onRowDataChanged(e)}
              />
            </div>
          )}
        </main>
      )}
    </>
  );
}

export default AdminUsersScreen;
