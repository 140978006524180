import React from "react";
import Header from "../components/organisms/Header";
import { AllTextObject } from "../@types/language";
import "../styles/orderMessage.css";
import landing_animation from "../images/landing-coral-gif.gif";
import {
  GradientSubTitle,
  SmallerText,
  Text,
} from "../components/atoms/StyledComponents";
import { useSelector } from "react-redux";
import { RootState } from "../redux/store";
import Socials from "../components/molecules/Socials";
import OrderProcess from "../components/molecules/OrderProcess";
import OverviewActionButton from "../components/molecules/OverviewActionButton";

type Props = { allText: AllTextObject };

function OrderFailedScreen({ allText }: Props) {
  const user = useSelector((state: RootState) => state.user);
  const isMobile = window.innerWidth < 1024;

  const handlePlaceOrder = () => {
    //TODO DAAN: Molly hier opnieuw starten
  };
  return (
    <div className="order-message-screen-container">
      <Header allText={allText} />
      <main className="landing-banner-container overview-container order-message-screen">
        <div className="order-message-container">
          <div className="order-message-animation-container landing-banner-animation-container">
            <figure>
              <img src={landing_animation} alt="coral animation" />
            </figure>
          </div>
          <div className="order-message-screen-content-container">
            <div
              className="order-message-screen-content-column"
              style={{ width: "90%", maxWidth: "500px", marginRight: "auto" }}
            >
              <GradientSubTitle color="var(--primary-gradient-200)">
              {allText.tid_var_12}
              </GradientSubTitle>
              <Text>{allText.tid_var_13}</Text>
              <OverviewActionButton
                action={handlePlaceOrder}
                text={allText.tid_var_14}
                subtext={allText.tid_var_15}
                color="var(--green-gradient-500)"
                disabled={false}
              />
              <Socials />
            </div>
          </div>
        </div>
      </main>
    </div>
  );
}

export default OrderFailedScreen;
