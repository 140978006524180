import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { ArrowLeft } from "@phosphor-icons/react";
import { SubTitle } from "../atoms/StyledComponents";
import { setOpenTexturesModal } from "../../redux/configuratorSlice";
import ConfiguratorImageOption from "./ConfiguratorImageOption";
import { DecorTexture } from "../../@types/textures";
import { setAquariumTexture } from "../../redux/aquariumSlice";
import { decorTextures } from "../../data/textures";
import { AllTextObject } from "../../@types/language";
import { checkAquariumTexture } from "../../utils/configuratorRules";
import { Aquarium } from "../../@types/aquarium";

type Props = { allText: AllTextObject };

function TexturesModal({ allText }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const dispatch = useDispatch();
  const showModal = useSelector(
    (state: RootState) => state.configurator.openTexturesModal
  );
  const textures = decorTextures(allText);

  const [selectedTexture, setSelectedTexture] = useState<DecorTexture | null>(
    aquarium.aquariumTexture
  );

  useEffect(() => {
    dispatch(setAquariumTexture(selectedTexture));
    dispatch(setOpenTexturesModal(false));
  }, [selectedTexture]);

  useEffect(() => {
    setSelectedTexture(aquarium.aquariumTexture);
  }, [aquarium]);

  const isDecorTexture = (item: DecorTexture | any): item is DecorTexture => {
    return typeof item !== "undefined";
  };

  return (
    <div
      className="textures-modal-container"
      style={showModal ? { display: "flex" } : { display: "none" }}
    >
      <div className="textures-modal-content-container">
        <div className="textures-modal-header">
          <ArrowLeft
            size={32}
            weight="bold"
            onClick={() => dispatch(setOpenTexturesModal(false))}
            className="texture-modal-arrow"
          />
          <SubTitle color="var(--neutral-800)">{allText.tid_con_06}</SubTitle>
        </div>
        <div className="optionlist-container optionlist-container-texturemodal">
          {textures

            .filter((option) => {
              if (isDecorTexture(option.id)) {
                const checkObj: Aquarium = {
                  ...aquarium,
                  aquariumTexture: option.id,
                };
                return !checkAquariumTexture(checkObj);
              }
            })
            .map((option) => (
              <ConfiguratorImageOption
                name="type-aquarium-option"
                option={option}
                value={selectedTexture}
                setValue={setSelectedTexture}
                key={"type-aquarium-option-" + option.id}
              />
            ))}
        </div>
      </div>
    </div>
  );
}

export default TexturesModal;
