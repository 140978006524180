import * as THREE from 'three';
import { makePlank, makeRectangle, makeCircle } from './helper';
import { FilterLocation } from '../../@types/aquarium';

const scale = 25;
const PI = 3.14159265359;

const filterWidth = 24;
const filterDepth = 24;
const filterHeight = 48;
const filterAquariumDiff = 5;
const landingWidth = 10;
const landingDepth = 10;
const landingthickness = 0.6;
const landingHoleDiameter = 6;
const boreHoleWidthDiff = 15;
const boreHoleDepthDiff = 10;
const boreHoleDiameter = 3.2;

let currFilterLocation: FilterLocation
let leftBore = false;
let rightBore = false;

export function makeExternalFilter(scene: THREE.Scene, width: number, height: number, depth: number, glassWidth: number, filterPlanks: THREE.Mesh[][]){
    if(filterPlanks[0].length > 0){
        for(let i = 0; i < 3; i++){
            scene.remove(filterPlanks[0][i]);
        }
    }
    filterPlanks[0][0] = makePlank(scene, 0,-height/scale/2-filterHeight/scale/2-filterAquariumDiff/scale,0, filterWidth/scale, filterHeight/scale, filterDepth/scale, 0x444444);
    scene.remove(filterPlanks[0][0])
    filterPlanks[0][1] = makeCircle(scene, -width/scale/2+glassWidth+boreHoleWidthDiff/scale, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+boreHoleDepthDiff/scale, boreHoleDiameter/scale/2, 0x000000)
    filterPlanks[0][1].rotateX(PI*270/180)
    filterPlanks[0][2] = makeCircle(scene, width/scale/2-glassWidth-boreHoleWidthDiff/scale, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+boreHoleDepthDiff/scale, boreHoleDiameter/scale/2, 0x000000)
    filterPlanks[0][2].rotateX(PI*270/180)
    if(!leftBore)
        scene.remove(filterPlanks[0][1]);

    if(!rightBore)
        scene.remove(filterPlanks[0][2]);
}

export function changeExternalLocation(scene: THREE.Scene, width: number, height: number, filterHeight: number, depth: number, glassWidth: number, filterPlanks: THREE.Mesh[][], filterLocation: FilterLocation){
    currFilterLocation = filterLocation
    let amountPlanks = filterPlanks[0].length
    for(let i = amountPlanks-1; i >= 3; i--){
        scene.remove(filterPlanks[0][i])
        filterPlanks[0].splice(i);
    }

    switch(filterLocation){
        case(FilterLocation.leftBack):{
            //front landingplank
            filterPlanks[0].push(makePlank(scene, -width/scale/2+landingWidth/scale/2+glassWidth, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000))
            //right landingplank
            filterPlanks[0].push(makePlank(scene, -width/scale/2+landingWidth/scale+glassWidth-landingthickness/scale/2, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //left hole
            let hole = makeCircle(scene, -width/scale/2+landingWidth/scale/2+glassWidth, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000);
            hole.rotateX(PI*270/180)
            filterPlanks[0].push(hole);
            break;
        }
        case(FilterLocation.leftMid):{
            //back
            filterPlanks[0].push(makePlank(scene,-width/scale/2+landingWidth/scale/2+glassWidth, -(height-filterHeight)/scale/2, -landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //right
            filterPlanks[0].push(makePlank(scene,-width/scale/2+landingWidth/scale+glassWidth-landingthickness/scale/2,-(height-filterHeight)/scale/2,0, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //front
            filterPlanks[0].push(makePlank(scene,-width/scale/2+landingWidth/scale/2+glassWidth,-(height-filterHeight)/scale/2, landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //left hole
            let hole = makeCircle(scene, -width/scale/2+landingWidth/scale/2+glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000);
            hole.rotateX(PI*270/180)
            filterPlanks[0].push(hole);
            break;
        }
        case(FilterLocation.midBack):{
            //left
            filterPlanks[0].push(makePlank(scene, -landingWidth/scale/2+landingthickness/scale/2, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //front
            filterPlanks[0].push(makePlank(scene, 0, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //right
            filterPlanks[0].push(makePlank(scene, landingWidth/scale/2-landingthickness/scale/2, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //left hole
            let hole = makeCircle(scene, 0, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000)
            hole.rotateX(PI*270/180)
            filterPlanks[0].push(hole);
            break;
        }
        case(FilterLocation.midMid):{
            //left
            filterPlanks[0].push(makePlank(scene, -landingWidth/scale/2+landingthickness/scale/2, -(height-filterHeight)/scale/2,0, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //front
            filterPlanks[0].push(makePlank(scene, 0,-(height-filterHeight)/scale/2, landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //right
            filterPlanks[0].push(makePlank(scene, landingWidth/scale/2-landingthickness/scale/2, -(height-filterHeight)/scale/2, 0, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //back
            filterPlanks[0].push(makePlank(scene, 0,-(height-filterHeight)/scale/2, -landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //left hole
            let hole = makeCircle(scene, 0, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000)
            hole.rotateX(PI*270/180)
            filterPlanks[0].push(hole);
            break;
        }
        case(FilterLocation.rightBack):{
            //front landingplank
            filterPlanks[0].push(makePlank(scene, width/scale/2-landingWidth/scale/2-glassWidth, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000))
            //left landingplank
            filterPlanks[0].push(makePlank(scene, width/scale/2-landingWidth/scale-glassWidth+landingthickness/scale/2, -(height-filterHeight)/scale/2, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //left hole
            let hole = makeCircle(scene, width/scale/2-landingWidth/scale/2-glassWidth, -height/scale/2+glassWidth/2+0.001, -depth/scale/2+glassWidth/2+landingDepth/scale/2, landingHoleDiameter/scale/2, 0x000000)
            hole.rotateX(PI*270/180)
            filterPlanks[0].push(hole);
            break;
        }
        case(FilterLocation.rightMid):{
            //back
            filterPlanks[0].push(makePlank(scene,width/scale/2-landingWidth/scale/2-glassWidth, -(height-filterHeight)/scale/2, -landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //left
            filterPlanks[0].push(makePlank(scene,width/scale/2-landingWidth/scale-glassWidth+landingthickness/scale/2,-(height-filterHeight)/scale/2,0, landingthickness/scale, filterHeight/scale, landingDepth/scale-landingthickness/scale, 0x000000));
            //front
            filterPlanks[0].push(makePlank(scene,width/scale/2-landingWidth/scale/2-glassWidth,-(height-filterHeight)/scale/2 , landingDepth/scale/2, landingWidth/scale, filterHeight/scale, landingthickness/scale, 0x000000));
            //left hole
            let hole = makeCircle(scene, width/scale/2-landingWidth/scale/2-glassWidth, -height/scale/2+glassWidth/2+0.001, 0, landingHoleDiameter/scale/2, 0x000000)
            hole.rotateX(PI*270/180)
            filterPlanks[0].push(hole)
            break;
        }
    }
}

//toggles the left bore, if there isn't a bore, make it, else delete it
// this circle is always right before the right bore circle in the filterPlanks list
export function makeLeftBore(scene: THREE.Scene, filterPlanks: THREE.Mesh[][], drawHole: boolean){
    leftBore = drawHole;
    if(leftBore){
        scene.add(filterPlanks[0][1])
    }else{
        scene.remove(filterPlanks[0][1])
    }
}

//toggles the right bore, if there isn't a bore, make it, else delete it
//this circle is always last in the filterPlanks list
export function makeRightBore(scene: THREE.Scene, filterPlanks: THREE.Mesh[][], drawHole: boolean){
    rightBore = drawHole;
    if(rightBore){//bore has to be deleted
        scene.add(filterPlanks[0][2])
    }else{//bore has to be added
        scene.remove(filterPlanks[0][2])
    }
}

export function changeExternalWidth(widthDiff: number, filterPlanks: THREE.Mesh[][]){
    
    if(currFilterLocation === FilterLocation.leftBack || currFilterLocation === FilterLocation.leftMid){
        for(let i = 3; i < filterPlanks[0].length; i++){
            filterPlanks[0][i].position.x -= widthDiff/scale/2
        }
    }else if(currFilterLocation === FilterLocation.rightBack || currFilterLocation === FilterLocation.rightMid){
        for(let i = 1; i < filterPlanks[0].length; i++){
            filterPlanks[0][i].position.x += widthDiff/scale/2
        }
    }

    filterPlanks[0][1].position.x -= widthDiff/scale/2
    filterPlanks[0][2].position.x += widthDiff/scale/2
}

export function changeExternalHeight(scene: THREE.Scene, currHeight: number, newHeight: number, filterPlanks: THREE.Mesh[][]){
    for(let i = 0; i < filterPlanks[0].length; i++){
        if(i > 2 && i < filterPlanks[0].length-1){
            filterPlanks[0][i].geometry.scale(1,newHeight/currHeight,1)
        }else{
            filterPlanks[0][i].position.y -= (newHeight-currHeight)/scale/2
        }
    }
}

export function changeExternalDepth(depthDiff: number, filterPlanks: THREE.Mesh[][]){
    filterPlanks[0][0].position.z -= depthDiff/scale/2;
    filterPlanks[0][1].position.z -= depthDiff/scale/2;
    filterPlanks[0][2].position.z -= depthDiff/scale/2;
    if(currFilterLocation === FilterLocation.leftBack || currFilterLocation === FilterLocation.midBack || currFilterLocation === FilterLocation.rightBack){
        for(let i = 3; i < filterPlanks[0].length; i++){
            filterPlanks[0][i].position.z -= depthDiff/scale/2
        }
    }
}