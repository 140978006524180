import React, { useEffect, useState } from "react";
import { Text } from "../atoms/StyledComponents";
import "../../styles/rangeSlider.css";
import { useDebounce } from "use-debounce";

type Props = {
  title: string;
  aquariumDimension: number;
  isValid: boolean;
  setValue: React.Dispatch<React.SetStateAction<number>>;
  minValue: number;
  maxValue: number;
  id: string;
  stepIssue?: boolean;
};

function DimensionSlider({
  title,
  aquariumDimension,
  isValid,
  minValue,
  maxValue,
  setValue,
  id,
  stepIssue,
}: Props) {
  const [actualValue, setActualValue] = useState<number>(aquariumDimension);
  const [debounceValue] = useDebounce(actualValue, 500);

  useEffect(() => {
    setActualValue(aquariumDimension);
  }, [aquariumDimension]);

  useEffect(() => {
    setValue(debounceValue);
  }, [debounceValue]);

  return (
    <div className="dimension-slider-container">
      <div className="dimension-slider-header">
        <h4 className="dimension-slider-title">{title}</h4>
        <div className="dimension-slider-unit">
          <input
            className={
              "dimension-slider-number-input " +
              (isValid
                ? ""
                : stepIssue
                ? "dimension-slider-number-input-false dimension-slider-number-input-false-special"
                : "dimension-slider-number-input-false")
            }
            id={id + "input"}
            type="number"
            min={minValue}
            max={maxValue}
            value={actualValue}
            step="1"
            onChange={(e) => {
              setActualValue(parseInt(e.target.value));
            }}
          />
          <Text color="var(--primary-700)">cm</Text>
        </div>
      </div>
      <input
        className="dimension-slider-range-input"
        id={id + "slider"}
        type="range"
        min={minValue}
        max={maxValue}
        value={aquariumDimension}
        step="1"
        onChange={(e) => setValue(parseInt(e.target.value))}
      />
    </div>
  );
}

export default DimensionSlider;
