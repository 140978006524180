import * as THREE from "three";
import { colorPlankSide, makePlank, makeRectangle } from "./helper";
import { FullGlassCoverPanels } from "../../@types/aquarium";
import { store } from "../../redux/store";

const getWidthStripValues = () => {
  return store.getState().configurator.widthStripValues;
};

const PI = 3.14159265359;
const scale = 25;
const lengthstripsDepth = 5;
export const lengthstripsHeight = 1;
const lengthstripLengthDiff = 4;
export const widthstripsDepth = 12;
const widthstripsHeight = 1;

export const minLengthstripWidth = 60;
export const minLengthstripDepth = 60;
export const minSideWithstripDepth = 70;

const nonSlidingTriangleWidthHeight = 8; // for the triangle in the corner of the cover
const maxNonSlidingPanelWidth = 30;

const slidingGlassSliderWidthHeight = 1; // for the black beam of the sliding glass
const slidingGlassWidth = 0.6;

let currWidth: number;
let currHeight: number;
let currDepth: number;
let glassWidth: number;
let amountWidthStrips = 0;
let amountCoverPanels = 0;
let currPanelKind = FullGlassCoverPanels.noPanels;

export function makeFullGlassAquarium(
  scene: THREE.Scene,
  width: number,
  height: number,
  depth: number,
  newGlassWidth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  for (let i = aquariumTypePlanks.length - 1; i >= 0; i--) {
    scene.remove(aquariumTypePlanks[i]);
    aquariumTypePlanks.splice(i);
  }
  amountWidthStrips = getAmountWidthStrips(width, depth);

  let lengthstripsHeightdiff =
    lengthstripsHeight / scale + widthstripsHeight / scale;
  //lengthstrips
  aquariumTypePlanks[0] = colorPlankSide(
    scene,
    0,
    height / (scale * 2) - lengthstripsHeightdiff,
    depth / (scale * 2) - newGlassWidth - lengthstripsDepth / scale / 2,
    width / scale - 2 * newGlassWidth - (2 * lengthstripLengthDiff) / scale,
    lengthstripsHeight / scale,
    lengthstripsDepth / scale,
    [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0xdefcff],
    [0.3, 0.3, 0.3, 0.3, 1, 0.3]
  );
  aquariumTypePlanks[1] = colorPlankSide(
    scene,
    0,
    height / (scale * 2) - lengthstripsHeightdiff,
    -depth / (scale * 2) + newGlassWidth + lengthstripsDepth / scale / 2,
    width / scale - 2 * newGlassWidth - (2 * lengthstripLengthDiff) / scale,
    lengthstripsHeight / scale,
    lengthstripsDepth / scale,
    [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000],
    [0.3, 0.3, 0.3, 0.3, 0.3, 1]
  );
  //sidewidthstrips
  aquariumTypePlanks[2] = colorPlankSide(
    scene,
    width / scale / 2 - glassWidth - lengthstripsDepth / scale / 2,
    height / (scale * 2) - lengthstripsHeight / scale,
    0,
    lengthstripsDepth / scale,
    lengthstripsHeight / scale,
    depth / scale - 2 * newGlassWidth,
    [0x000000, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0x000000],
    [1, 0.3, 0.3, 0.3, 1, 1]
  );
  aquariumTypePlanks[3] = colorPlankSide(
    scene,
    -(width / scale / 2 - glassWidth - lengthstripsDepth / scale / 2),
    height / (scale * 2) - lengthstripsHeight / scale,
    0,
    lengthstripsDepth / scale,
    lengthstripsHeight / scale,
    depth / scale - 2 * newGlassWidth,
    [0xdefcff, 0x000000, 0xdefcff, 0xdefcff, 0x000000, 0x000000],
    [0.3, 1, 0.3, 0.3, 1, 1]
  );

  stripsVisibleInvisible(scene, width, depth, aquariumTypePlanks); //hide certain strips if width or depth sin't big enough
  //widthstrips
  for (let i = 0; i < amountWidthStrips; i++) {
    aquariumTypePlanks[i + 4] = colorPlankSide(
      scene,
      -width / scale / 2 +
        ((i + 1) * (width / scale - 2 * newGlassWidth)) /
          (amountWidthStrips + 1),
      height / (scale * 2) -
        widthstripsHeight / scale / 2 -
        lengthstripsHeight / scale / 2,
      0,
      widthstripsDepth / scale,
      widthstripsHeight / scale,
      depth / scale - 2 * newGlassWidth,
      [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0x000000],
      [0.3, 0.3, 0.3, 0.3, 1, 1]
    );
  }

  currDepth = depth;
  currWidth = width;
  currHeight = height;
  glassWidth = newGlassWidth;
}

export function changeFullGlassDepth(
  scene: THREE.Scene,
  depth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //length strips
  aquariumTypePlanks[0].position.z += (depth - currDepth) / scale / 2;
  aquariumTypePlanks[1].position.z += (currDepth - depth) / scale / 2;

  //widthstrips
  aquariumTypePlanks[2].geometry.scale(
    1,
    1,
    (depth / scale - 2 * glassWidth) / (currDepth / scale - 2 * glassWidth)
  );
  aquariumTypePlanks[3].geometry.scale(
    1,
    1,
    (depth / scale - 2 * glassWidth) / (currDepth / scale - 2 * glassWidth)
  );

  //widthstrips
  for (let i = 0; i < amountWidthStrips; i++) {
    aquariumTypePlanks[i + 4].geometry.scale(
      1,
      1,
      (depth - 2 * glassWidth) / (currDepth - 2 * glassWidth)
    );
  }
  stripsVisibleInvisible(scene, currWidth, depth, aquariumTypePlanks);
  let newAmountWidthStrips = getAmountWidthStrips(currWidth, depth);

  //change the amount of widthstrips
  if (newAmountWidthStrips !== amountWidthStrips) {
    amountWidthStrips = newAmountWidthStrips;
    for (let i = aquariumTypePlanks.length - 1; i >= 4; i--) {
      scene.remove(aquariumTypePlanks[i]);
      aquariumTypePlanks.splice(i);
    }
    amountCoverPanels = 0;
    for (let i = 0; i < amountWidthStrips; i++) {
      aquariumTypePlanks.splice(
        i + 4,
        0,
        colorPlankSide(
          scene,
          -currWidth / scale / 2 +
            ((i + 1) * (currWidth / scale - 2 * glassWidth)) /
              (amountWidthStrips + 1),
          currHeight / (scale * 2) -
            widthstripsHeight / scale / 2 -
            lengthstripsHeight / scale / 2,
          0,
          widthstripsDepth / scale,
          widthstripsHeight / scale,
          depth / scale - 2 * glassWidth,
          [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0x000000],
          [0.3, 0.3, 0.3, 0.3, 1, 1]
        )
      );
    }
  } else {
    for (let i = 0; i < amountWidthStrips; i++) {
      aquariumTypePlanks[i + 4].position.x =
        -currWidth / scale / 2 +
        ((i + 1) * (currWidth / scale - 2 * glassWidth)) /
          (amountWidthStrips + 1);
    }
  }
  currDepth = depth;
  setGlassPanels(scene, currPanelKind, aquariumTypePlanks);
}

//when width of the aquarium changes (so NOT glasswidth)
export function changeFullGlassWidth(
  scene: THREE.Scene,
  width: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //change the width of the lengthstrips
  aquariumTypePlanks[0].geometry.scale(
    (width / scale - 2 * glassWidth - (2 * lengthstripLengthDiff) / scale) /
      (currWidth / scale -
        2 * glassWidth -
        (2 * lengthstripLengthDiff) / scale),
    1,
    1
  );
  aquariumTypePlanks[1].geometry.scale(
    (width / scale - 2 * glassWidth - (2 * lengthstripLengthDiff) / scale) /
      (currWidth / scale -
        2 * glassWidth -
        (2 * lengthstripLengthDiff) / scale),
    1,
    1
  );

  //widthstrips
  aquariumTypePlanks[2].position.x += (width - currWidth) / scale / 2;
  aquariumTypePlanks[3].position.x += (currWidth - width) / scale / 2;

  let newAmountWidthStrips = getAmountWidthStrips(width, currDepth);
  stripsVisibleInvisible(scene, width, currDepth, aquariumTypePlanks);
  //change the amount of widthstrips
  if (newAmountWidthStrips !== amountWidthStrips) {
    amountWidthStrips = newAmountWidthStrips;
    for (let i = aquariumTypePlanks.length - 1; i >= 4; i--) {
      scene.remove(aquariumTypePlanks[i]);
      aquariumTypePlanks.splice(i);
    }
    amountCoverPanels = 0;
    for (let i = 0; i < amountWidthStrips; i++) {
      aquariumTypePlanks.splice(
        i + 4,
        0,
        colorPlankSide(
          scene,
          -width / scale / 2 +
            ((i + 1) * (width / scale - 2 * glassWidth)) /
              (amountWidthStrips + 1),
          currHeight / (scale * 2) -
            widthstripsHeight / scale / 2 -
            lengthstripsHeight / scale / 2,
          0,
          widthstripsDepth / scale,
          widthstripsHeight / scale,
          currDepth / scale - 2 * glassWidth,
          [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0x000000],
          [0.3, 0.3, 0.3, 0.3, 1, 1]
        )
      );
    }
  } else {
    for (let i = 0; i < amountWidthStrips; i++) {
      aquariumTypePlanks[i + 4].position.x =
        -width / scale / 2 +
        ((i + 1) * (width / scale - 2 * glassWidth)) / (amountWidthStrips + 1);
    }
  }
  currWidth = width;
  setGlassPanels(scene, currPanelKind, aquariumTypePlanks);
}

export function changeFullGlassHeight(
  height: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //all the planks have to be moved up a bit
  //this only works because all the planks rise as fast as the top of the aquarium
  for (let i = 0; i < aquariumTypePlanks.length; i++) {
    aquariumTypePlanks[i].position.y += (height - currHeight) / scale / 2;
  }
  currHeight = height;
}

// change glasswidth of the aquarium
export function changeFullGlassGlassWidth(
  scene: THREE.Scene,
  newGlassWidth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //lengthstrips
  aquariumTypePlanks[0].geometry.scale(
    (currWidth / scale - 2 * newGlassWidth) /
      (currWidth / scale - 2 * glassWidth),
    1,
    1
  );
  aquariumTypePlanks[1].geometry.scale(
    (currWidth / scale - 2 * newGlassWidth) /
      (currWidth / scale - 2 * glassWidth),
    1,
    1
  );
  aquariumTypePlanks[0].position.z += newGlassWidth - glassWidth;
  aquariumTypePlanks[1].position.z += glassWidth - newGlassWidth;

  //widthstrips
  aquariumTypePlanks[2].geometry.scale(
    1,
    1,
    (currDepth / scale - 2 * newGlassWidth) /
      (currDepth / scale - 2 * glassWidth)
  );
  aquariumTypePlanks[3].geometry.scale(
    1,
    1,
    (currDepth / scale - 2 * newGlassWidth) /
      (currDepth / scale - 2 * glassWidth)
  );
  aquariumTypePlanks[2].position.x += newGlassWidth - glassWidth;
  aquariumTypePlanks[3].position.x += glassWidth - newGlassWidth;

  //widthstrips
  for (let i = 0; i < amountWidthStrips; i++) {
    aquariumTypePlanks[i + 4].geometry.scale(
      (currDepth / scale - 2 * newGlassWidth) /
        (currDepth / scale - 2 * glassWidth),
      1,
      1
    );
  }

  glassWidth = newGlassWidth;
  setGlassPanels(scene, currPanelKind, aquariumTypePlanks);
}

//shows or hides the lengthstrips and widthstrips if the depth and height are high enough
function stripsVisibleInvisible(
  scene: THREE.Scene,
  width: number,
  depth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  if (width < minLengthstripWidth && depth < minLengthstripDepth) {
    //lengthstrips
    scene.remove(aquariumTypePlanks[0]);
    scene.remove(aquariumTypePlanks[1]);
  } else {
    scene.add(aquariumTypePlanks[0]);
    scene.add(aquariumTypePlanks[1]);
  }

  if (depth < minSideWithstripDepth) {
    //widthstrips
    scene.remove(aquariumTypePlanks[2]);
    scene.remove(aquariumTypePlanks[3]);
  } else {
    scene.add(aquariumTypePlanks[2]);
    scene.add(aquariumTypePlanks[3]);
  }
}

//check in the jsonfile how many widthstrips are needed with a certain width and depth
function getAmountWidthStrips(width: number, depth: number) {
  //check where depth is at (between which values is depth)
  let horizontalIndex = getWidthStripValues()["horizontalValues"].length-1;
  for (let i = 0; i < getWidthStripValues()["horizontalValues"].length; i++) {
    if (depth < Number(getWidthStripValues()["horizontalValues"][i])) {
      horizontalIndex = i + 1;
      break;
    }
  }

  //check where width is at (between which values is width)
  let verticalIndex = getWidthStripValues()["verticalValues"].length;
  for (let i = 0; i < getWidthStripValues()["verticalValues"].length; i++) {
    if (width < Number(getWidthStripValues()["verticalValues"][i])) {
      verticalIndex = i + 1;
      break;
    }
  }

  //check how many widthstrips there should be
  for (let i = 0; i < getWidthStripValues()["amount_strips"].length; i++) {
    if (
      verticalIndex === Number(getWidthStripValues()["amount_strips"][i][0]) &&
      horizontalIndex === Number(getWidthStripValues()["amount_strips"][i][1])
    ) {
      return Number(getWidthStripValues()["amount_strips"][i][2]);
    }
  }
  return 0;
}

//draws the options of the fullglass aquarium
export function setGlassPanels(
  scene: THREE.Scene,
  panels: FullGlassCoverPanels,
  aquariumTypePlanks: THREE.Mesh[]
) {
  currPanelKind = panels;
  //take the length because this is changing when plank are removed from the aquariumtypeplanks list
  let amountPlanks = aquariumTypePlanks.length;

  //remove all the planks
  for (let i = amountPlanks - 1; i >= amountPlanks - amountCoverPanels; i--) {
    scene.remove(aquariumTypePlanks[i]);
    aquariumTypePlanks.splice(i);
  }
  amountCoverPanels = 0;

  switch (panels) {
    case FullGlassCoverPanels.nonSliding: {
      makeNonSlidingGlass(scene, aquariumTypePlanks);
      break;
    }
    case FullGlassCoverPanels.sliding: {
      makeSlidingGlass(scene, aquariumTypePlanks);
      break;
    }
  }
}

// draw sliding glass option
function makeSlidingGlass(
  scene: THREE.Scene,
  aquariumTypePlanks: THREE.Mesh[]
) {
  let xOfSegments = getXOfSegments(); // get the x values of the segments
  // left glasspanel, this one has 1 triangle out of his corner
  let leftLowerGlass = makeSidepanel(
    scene,
    [
      [
        xOfSegments[1] - slidingGlassSliderWidthHeight / scale,
        currDepth / scale / 2 - glassWidth,
      ],
      [
        xOfSegments[0] +
          slidingGlassSliderWidthHeight / scale +
          nonSlidingTriangleWidthHeight / scale,
        currDepth / scale / 2 - glassWidth,
      ],
      [
        xOfSegments[0] + slidingGlassSliderWidthHeight / scale,
        currDepth / scale / 2 -
          glassWidth -
          nonSlidingTriangleWidthHeight / scale,
      ],
      [xOfSegments[0] + slidingGlassSliderWidthHeight / scale, 0],
      [xOfSegments[1] - slidingGlassSliderWidthHeight / scale, 0],
    ],
    slidingGlassWidth / scale,
    0xdefcff,
    0.6
  );
  leftLowerGlass.rotateX((PI * 90) / 180);
  leftLowerGlass.position.y =
    currHeight / (scale * 2) -
    widthstripsHeight / scale +
    slidingGlassSliderWidthHeight / scale / 2 +
    slidingGlassWidth / scale / 2;
  aquariumTypePlanks.push(leftLowerGlass);
  //left black plank
  aquariumTypePlanks.push(
    makePlank(
      scene,
      xOfSegments[0] + slidingGlassSliderWidthHeight / scale / 2,
      currHeight / (scale * 2) -
        widthstripsHeight / scale +
        slidingGlassSliderWidthHeight / scale / 2,
      0,
      slidingGlassSliderWidthHeight / scale,
      slidingGlassSliderWidthHeight / scale,
      currDepth / scale - 2 * glassWidth,
      0x000000
    )
  );
  //right black plank
  aquariumTypePlanks.push(
    makePlank(
      scene,
      xOfSegments[1] - slidingGlassSliderWidthHeight / scale / 2,
      currHeight / (scale * 2) -
        widthstripsHeight / scale +
        slidingGlassSliderWidthHeight / scale / 2,
      0,
      slidingGlassSliderWidthHeight / scale,
      slidingGlassSliderWidthHeight / scale,
      currDepth / scale - 2 * glassWidth,
      0x000000
    )
  );
  //lower glass
  aquariumTypePlanks.push(
    makePlank(
      scene,
      (xOfSegments[0] + xOfSegments[1]) / 2,
      currHeight / (scale * 2) -
        widthstripsHeight / scale +
        slidingGlassSliderWidthHeight / scale / 2 +
        slidingGlassWidth / scale / 2,
      (-currDepth / 2 / scale + glassWidth) / 2,
      xOfSegments[1] -
        xOfSegments[0] -
        (2 * slidingGlassSliderWidthHeight) / scale,
      slidingGlassWidth / scale,
      currDepth / scale / 2 - glassWidth - 0.02,
      0xdefcff,
      0.6
    )
  );

  amountCoverPanels += 4;

  for (let i = 2; i < xOfSegments.length; i += 2) {
    //left black plank
    aquariumTypePlanks.push(
      makePlank(
        scene,
        xOfSegments[i] + slidingGlassSliderWidthHeight / scale / 2,
        currHeight / (scale * 2) -
          widthstripsHeight / scale +
          slidingGlassSliderWidthHeight / scale / 2,
        0,
        slidingGlassSliderWidthHeight / scale,
        slidingGlassSliderWidthHeight / scale,
        currDepth / scale - 2 * glassWidth,
        0x000000
      )
    );
    //right black plank
    aquariumTypePlanks.push(
      makePlank(
        scene,
        xOfSegments[i + 1] - slidingGlassSliderWidthHeight / scale / 2,
        currHeight / (scale * 2) -
          widthstripsHeight / scale +
          slidingGlassSliderWidthHeight / scale / 2,
        0,
        slidingGlassSliderWidthHeight / scale,
        slidingGlassSliderWidthHeight / scale,
        currDepth / scale - 2 * glassWidth,
        0x000000
      )
    );
    //upper glass
    aquariumTypePlanks.push(
      makePlank(
        scene,
        (xOfSegments[i] + xOfSegments[i + 1]) / 2,
        currHeight / (scale * 2) -
          widthstripsHeight / scale +
          slidingGlassSliderWidthHeight / scale / 2 -
          slidingGlassWidth / scale / 2,
        (currDepth / 2 / scale - glassWidth) / 2,
        xOfSegments[i + 1] -
          xOfSegments[i] -
          (2 * slidingGlassSliderWidthHeight) / scale,
        slidingGlassWidth / scale,
        currDepth / scale / 2 - glassWidth - 0.02,
        0xdefcff,
        0.6
      )
    );
    //lower glass
    aquariumTypePlanks.push(
      makePlank(
        scene,
        (xOfSegments[i] + xOfSegments[i + 1]) / 2,
        currHeight / (scale * 2) -
          widthstripsHeight / scale +
          slidingGlassSliderWidthHeight / scale / 2 +
          slidingGlassWidth / scale / 2,
        (-currDepth / 2 / scale + glassWidth) / 2,
        xOfSegments[i + 1] -
          xOfSegments[i] -
          (2 * slidingGlassSliderWidthHeight) / scale,
        slidingGlassWidth / scale,
        currDepth / scale / 2 - glassWidth - 0.02,
        0xdefcff,
        0.6
      )
    );

    amountCoverPanels += 4;
  }
}

//draw the non sliding glass option
function makeNonSlidingGlass(
  scene: THREE.Scene,
  aquariumTypePlanks: THREE.Mesh[]
) {
  let xValues = getNonSlidingGlassXvalues(); //get the x values of where the glasspanels should go

  //most left panel has 2 triangles as corners
  let leftSidePanel = makeSidepanel(
    scene,
    [
      [xValues[1], currDepth / scale / 2 - glassWidth],
      [
        xValues[0] + nonSlidingTriangleWidthHeight / scale,
        currDepth / scale / 2 - glassWidth,
      ],
      [
        xValues[0],
        currDepth / scale / 2 -
          glassWidth -
          nonSlidingTriangleWidthHeight / scale,
      ],
      [
        xValues[0],
        -currDepth / scale / 2 +
          glassWidth +
          nonSlidingTriangleWidthHeight / scale,
      ],
      [
        xValues[0] + nonSlidingTriangleWidthHeight / scale,
        -currDepth / scale / 2 + glassWidth,
      ],
      [xValues[1], -currDepth / scale / 2 + glassWidth],
    ],
    widthstripsHeight / scale,
    0xdefcff,
    0.6
  );
  leftSidePanel.rotateX((PI * 90) / 180);
  leftSidePanel.position.y =
    currHeight / (scale * 2) - widthstripsHeight / scale / 2;
  aquariumTypePlanks.push(leftSidePanel);
  amountCoverPanels += 1;

  //make the other panels
  for (let i = 2; i < xValues.length - 2; i += 2) {
    aquariumTypePlanks.push(
      makePlank(
        scene,
        (xValues[i] + xValues[i + 1]) / 2,
        currHeight / (scale * 2) - widthstripsHeight / scale,
        0,
        xValues[i + 1] - xValues[i] - 0.01,
        widthstripsHeight / scale,
        currDepth / scale - 2 * glassWidth,
        0xdefcff,
        0.6
      )
    );
    amountCoverPanels += 1;
  }
  let xValLength = xValues.length;
  // most right panel has 2 triangles as corners
  let rightSidePanel = makeSidepanel(
    scene,
    [
      [xValues[xValLength - 2], currDepth / scale / 2 - glassWidth],
      [xValues[xValLength - 2], -(currDepth / scale / 2 - glassWidth)],
      [
        xValues[xValLength - 1] - nonSlidingTriangleWidthHeight / scale,
        -(currDepth / scale / 2 - glassWidth),
      ],
      [
        xValues[xValLength - 1],
        -(currDepth / scale / 2 - glassWidth) +
          nonSlidingTriangleWidthHeight / scale,
      ],
      [
        xValues[xValLength - 1],
        currDepth / scale / 2 -
          glassWidth -
          nonSlidingTriangleWidthHeight / scale,
      ],
      [
        xValues[xValLength - 1] - nonSlidingTriangleWidthHeight / scale,
        currDepth / scale / 2 - glassWidth,
      ],
    ],
    widthstripsHeight / scale,
    0xdefcff,
    0.6
  );
  rightSidePanel.rotateX((PI * 90) / 180);
  rightSidePanel.position.y =
    currHeight / (scale * 2) - widthstripsHeight / scale / 2;
  aquariumTypePlanks.push(rightSidePanel);
  amountCoverPanels += 1;
  // the lengthstrips should show, even if the width isn't big enough, because the panels need support
  scene.add(aquariumTypePlanks[0]);
  scene.add(aquariumTypePlanks[1]);
}

// returns a list of x values that correspond to the holes between the widthstrips
function getXOfSegments() {
  let amountStrips = getAmountWidthStrips(currWidth, currDepth); //get the amount of widthstrips
  let xOfSegments: number[] = [];

  if (currDepth > minSideWithstripDepth) {
    //check if the sidewidthstrips are there
    xOfSegments[0] =
      -currWidth / scale / 2 + glassWidth + lengthstripsDepth / scale;
  } else {
    xOfSegments[0] = -currWidth / scale / 2 + glassWidth;
  }

  if (amountStrips > 0) {
    for (let i = 0; i < amountStrips; i++) {
      //push the x values of the segments
      xOfSegments.push(
        -currWidth / scale / 2 +
          ((i + 1) * (currWidth / scale - 2 * glassWidth)) /
            (amountWidthStrips + 1) -
          widthstripsDepth / scale / 2
      );
      xOfSegments.push(
        -currWidth / scale / 2 +
          ((i + 1) * (currWidth / scale - 2 * glassWidth)) /
            (amountWidthStrips + 1) +
          widthstripsDepth / scale / 2
      );
    }
  }
  //last x value is the negative of the first one
  xOfSegments.push(-xOfSegments[0]);
  return xOfSegments;
}

//returns a list of x values that correspond to where the glass panels of the non sliding glass option should go
// the list looks like this: left x value, right x values, left x value, right x value,...
// there can be values that are the same of 2 panels are right next to each other
function getNonSlidingGlassXvalues() {
  let xValues: number[] = [];

  let xOfSegments = getXOfSegments(); //get x values of the segments
  for (let i = 0; i < xOfSegments.length; i += 2) {
    let widthOfSegment = xOfSegments[i + 1] - xOfSegments[i];
    let amountPanels =
      Math.floor((widthOfSegment * scale) / maxNonSlidingPanelWidth) + 1; //amount of glasspanels in that segment

    //calculate the x values of the glasspanels in this segment
    for (let j = 0; j < amountPanels; j++) {
      xValues.push(xOfSegments[i] + (j * widthOfSegment) / amountPanels);
      xValues.push(xOfSegments[i] + ((j + 1) * widthOfSegment) / amountPanels);
    }
  }
  return xValues;
}

// makes a panel with a shape defined by path, so path contains coordinates and the panelshape follows these coordinates
function makeSidepanel(
  scene: THREE.Scene,
  path: number[][],
  depth: number,
  color: THREE.ColorRepresentation,
  opacity = 1
) {
  let shape = new THREE.Shape();
  shape.moveTo(path[0][0], path[0][1]);
  for (let i = 1; i < path.length; i++) {
    shape.lineTo(path[i][0], path[i][1]);
  }
  shape.lineTo(path[0][0], path[0][1]);

  let shapeGeo = new THREE.ExtrudeGeometry(shape, {
    depth: depth,
    bevelEnabled: false,
  });
  let shapeMat = new THREE.MeshPhongMaterial({
    color: color,
    shininess: 50,
    emissive: color,
  });
  if (opacity < 1) {
    shapeMat.transparent = true;
    shapeMat.opacity = opacity;
  }
  let shapeMesh = new THREE.Mesh(shapeGeo, shapeMat);

  scene.add(shapeMesh);
  return shapeMesh;
}

export const amountStripsFullGlass = () => {
  return amountWidthStrips;
};
