import { Dresser } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { ImageOption, Steps } from "../../../@types/configurator";
import { RootState } from "../../../redux/store";
import ConfiguratorStep from "../../templates/ConfiguratorStep";
import StepItem from "../../templates/StepItem";
import {
  doorTypeOptions,
  electraRuimteOptions,
  furnitureTypeOptions,
} from "../../../data/aquarium";
import ConfiguratorImageOption from "../../molecules/ConfiguratorImageOption";
import {
  Aquarium,
  AquariumType,
  DoorType,
  ElectraRuimte,
  FilterOptions,
  FurnitureDimensions,
  FurnitureOptions,
  FurnitureType,
} from "../../../@types/aquarium";
import {
  setDoorType,
  setElectraRuimte,
  setExtraAquaswitch,
  setFurnitureType,
} from "../../../redux/aquariumSlice";
import FurnitureHeightSliders from "../../molecules/FurnitureHeightSliders";
import IconToggle from "../../molecules/IconToggle";
import {
  setFurnitureDimensions as setFurnitureDimensionsRedux,
  setBetonPlex as setBetonPlexRedux,
  setSeeThrough as setSeeThroughRedux,
  setBackWall34 as setBackWall34Redux,
  setRemovableSideLeft as setRemovableSideLeftRedux,
  setRemovableSideRight as setRemovableSideRightRedux,
  setWaterproofPlate as setWaterproofPlateRedux,
} from "../../../redux/aquariumSlice";
import { setOpenTexturesModal } from "../../../redux/configuratorSlice";
import { DecorTexture } from "../../../@types/textures";
import { decorTexturePlaceholder, decorTextures } from "../../../data/textures";
import ImageSelectionButton from "../../molecules/ImageSelectionButton";
import { checkElectraRuimte, checkFurnitureType } from "../../../utils/configuratorRules";
import { AllTextObject } from "../../../@types/language";
import { getAquariumWeight } from "../../aquariumComponents/tank";

type Props = {
  allText: AllTextObject;
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function FurnitureStep({ allText, setOpenStep }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const stepItemInfoOverview = useSelector(
    (state: RootState) => state.configurator.stepItemInfoOverview
  );
  const dispatch = useDispatch();

  const [selectedFurnitureType, setSelectedFurnitureType] =
    useState<FurnitureType | null>(aquarium.furniture.furnitureType);

  const [furnitureDimensions, setFurnitureDimensions] =
    useState<FurnitureDimensions>(aquarium.furniture.dimensions);

  const [betonPlex, setBetonPlex] = useState<boolean>(
    aquarium.furniture.betonPlex
  );
  const [seeThrough, setSeeThrough] = useState<boolean>(
    aquarium.furniture.seeThrough
  );
  const [backWall34, setBackWall34] = useState<boolean>(
    aquarium.furniture.backWall34
  );
  const [electraRuimteLocation, setElectraRuimteLocation] =
    useState<ElectraRuimte | null>(aquarium.furniture.electraRuimte);
  const [removableSideLeft, setRemovableSideLeft] = useState<boolean>(
    aquarium.furniture.removableSideLeft
  );
  const [removableSideRight, setRemovableSideRight] = useState<boolean>(
    aquarium.furniture.removableSideRight
  );
  const [selectedDoorType, setSelectedDoorType] = useState<DoorType | null>(
    aquarium.furniture.doorType
  );
  const [aquaswitch, setAquaswitch] = useState<boolean>(
    aquarium.furniture.aquaswitch
  );

  const isWaterproofPlate = () => {
    return getAquariumWeight() >= 80;
  };
  const [waterproofPlate, setWaterproofPlate] = useState<boolean>(
    aquarium.aquariumType === AquariumType.rimless
      ? aquarium.extra.waterproofPlate
      : true
  );
  const [disableWaterproofPlate, setDisableWaterproofPlate] = useState<boolean>(
    isWaterproofPlate()
  );

  const [aquariumTexture, setAquariumTexture] = useState<DecorTexture | null>(
    aquarium.aquariumTexture
  );
  const getTextureObj = (textureID: DecorTexture): ImageOption => {
    const decorTexture = decorTextures(allText).find(
      (item) => item.id === textureID
    );
    return decorTexture ? decorTexture : decorTexturePlaceholder(allText);
  };
  const [selectedTexture, setSelectedTexture] = useState<ImageOption>(
    getTextureObj(
      aquarium.aquariumTexture
        ? aquarium.aquariumTexture
        : DecorTexture.placeholder
    )
  );

  console.log(getAquariumWeight());

  // Reset aquarium
  useEffect(() => {
    setAquariumTexture(aquarium.aquariumTexture);
    setSelectedFurnitureType(aquarium.furniture.furnitureType);
    setFurnitureDimensions(aquarium.furniture.dimensions);
    setBetonPlex(aquarium.furniture.betonPlex);
    setSeeThrough(aquarium.furniture.seeThrough);
    setBackWall34(aquarium.furniture.backWall34);
    setElectraRuimteLocation(aquarium.furniture.electraRuimte);
    setRemovableSideLeft(aquarium.furniture.removableSideLeft);
    setRemovableSideRight(aquarium.furniture.removableSideRight);
    setSelectedDoorType(aquarium.furniture.doorType);
    setAquaswitch(aquarium.furniture.aquaswitch);
    setWaterproofPlate(aquarium.extra.waterproofPlate);
  }, [aquarium]);

  useEffect(() => {
    if (
      selectedFurnitureType !== aquarium.furniture.furnitureType &&
      (selectedFurnitureType === FurnitureType.decor ||
        selectedFurnitureType === FurnitureType.aluminium)
    ) {
      dispatch(setBetonPlexRedux(true));
    } else {
      dispatch(setBetonPlexRedux(false));
    }
    dispatch(setFurnitureType(selectedFurnitureType));

    if (selectedFurnitureType) {
    }
  }, [selectedFurnitureType]);

  useEffect(() => {
    dispatch(setFurnitureDimensionsRedux(furnitureDimensions));
  }, [furnitureDimensions]);

  useEffect(() => {
    dispatch(setBetonPlexRedux(betonPlex));
  }, [betonPlex]);

  useEffect(() => {
    dispatch(setSeeThroughRedux(seeThrough));
  }, [seeThrough]);

  useEffect(() => {
    dispatch(setBackWall34Redux(backWall34));
  }, [backWall34]);

  useEffect(() => {
    dispatch(setElectraRuimte(electraRuimteLocation));
  }, [electraRuimteLocation]);

  useEffect(() => {
    dispatch(setRemovableSideLeftRedux(removableSideLeft));
  }, [removableSideLeft]);
  useEffect(() => {
    dispatch(setRemovableSideRightRedux(removableSideRight));
  }, [removableSideRight]);
  useEffect(() => {
    dispatch(setDoorType(selectedDoorType));
  }, [selectedDoorType]);
  useEffect(() => {
    dispatch(setWaterproofPlateRedux(waterproofPlate));
  }, [waterproofPlate]);
  useEffect(() => {
    setSelectedTexture(
      aquariumTexture
        ? getTextureObj(aquariumTexture)
        : decorTexturePlaceholder(allText)
    );
  }, [aquariumTexture]);
  useEffect(() => {
    dispatch(setExtraAquaswitch(aquaswitch));
  }, [aquaswitch]);
  const openTextureMenu = () => {
    dispatch(setOpenTexturesModal(true));
  };
  const isElectraRuimte = (
    item: ElectraRuimte | any
  ): item is ElectraRuimte => {
    return typeof item !== "undefined";
  };

  const isFurnitureType = (item: FurnitureType | any): item is FurnitureType => {
      return typeof item !== "undefined";
    };

  return (
    <ConfiguratorStep
      allText={allText}
      title={allText.tid_gen_07}
      step={5}
      stepId={Steps.furniture}
      symbol={
        <Dresser
          className="configurator-step-header-symbol"
          size={80}
          color="var(--primary-300)"
        />
      }
      setOpenStep={setOpenStep}
    >
      <>
        <StepItem
          title={allText.tid_st5_01}
          helptext={allText.tid_st5_02}
          errortext={allText.tid_st5_03}
          hasIssue={stepItemInfoOverview.furniture.furnitureType.hasIssue}
        >
          <div className="optionlist-container">
            {furnitureTypeOptions(allText)
              .filter((option) => {
                if (isFurnitureType(option.id)) {
                  const checkObj: Aquarium = {
                    ...aquarium,
                    furniture: { ...aquarium.furniture, furnitureType: option.id },
                  };
                  return !checkFurnitureType(checkObj);
                }
              })
              .map((option) => (
                <ConfiguratorImageOption
                  name="furniture-type-option"
                  option={option}
                  value={selectedFurnitureType}
                  setValue={setSelectedFurnitureType}
                  key={"furniture-type-option-" + option.id}
                />
              ))}
          </div>
        </StepItem>
        {(selectedFurnitureType === FurnitureType.frameOnly ||
          selectedFurnitureType === FurnitureType.decor ||
          selectedFurnitureType === FurnitureType.aluminium ||
          selectedFurnitureType === FurnitureType.marine) && (
          <StepItem
            title={allText.tid_st5_09}
            helptext={allText.tid_st5_10}
            errortext={allText.tid_st5_11}
            hasIssue={false}
          >
            <FurnitureHeightSliders
              allText={allText}
              furnitureDimensions={furnitureDimensions}
              setFurnitureDimensions={setFurnitureDimensions}
            />
          </StepItem>
        )}
        {(selectedFurnitureType === FurnitureType.decor ||
          selectedFurnitureType === FurnitureType.aluminium ||
          selectedFurnitureType === FurnitureType.marine) && (
          <StepItem
            title={allText.tid_con_03}
            helptext={allText.tid_con_04}
            errortext={allText.tid_con_05}
            hasIssue={stepItemInfoOverview.furniture.aquariumTexture.hasIssue}
          >
            <div className="optionlist-container">
              <div
                className="optionlist-button-container"
                onClick={() => openTextureMenu()}
              >
                <ImageSelectionButton
                  name="aquarium-texture-option"
                  option={selectedTexture}
                  value={aquariumTexture}
                  setValue={setAquariumTexture}
                  key={"aquarium-texture-option-" + selectedTexture.id}
                />
              </div>
            </div>
          </StepItem>
        )}{" "}
        <StepItem
          title={allText.tid_st5_15}
          helptext={allText.tid_st5_16}
          errortext={allText.tid_st5_17}
          hasIssue={false}
        >
          <>
            {(selectedFurnitureType === FurnitureType.frameOnly ||
              selectedFurnitureType === FurnitureType.decor ||
              selectedFurnitureType === FurnitureType.aluminium) && (
              <IconToggle
                checked={betonPlex}
                setValue={setBetonPlex}
                text={allText.tid_st5_18}
              />
            )}
            <IconToggle
              checked={waterproofPlate}
              setValue={setWaterproofPlate}
              text={allText.tid_st5_19}
              disabled={disableWaterproofPlate}
            />
            {(selectedFurnitureType === FurnitureType.aluminium ||
              selectedFurnitureType === FurnitureType.decor ||
              selectedFurnitureType === FurnitureType.marine) && (
              <>
                <IconToggle
                  checked={seeThrough}
                  setValue={setSeeThrough}
                  text={allText.tid_st5_20}
                  disabled={backWall34}
                />
                {selectedFurnitureType !== FurnitureType.marine && (
                  <IconToggle
                    checked={backWall34}
                    setValue={setBackWall34}
                    text={allText.tid_st5_21}
                    disabled={seeThrough}
                  />
                )}
              </>
            )}
          </>
        </StepItem>
        {selectedFurnitureType === FurnitureType.aluminium && (
          <StepItem
            title={allText.tid_st5_22}
            helptext={allText.tid_st5_23}
            errortext={allText.tid_st5_24}
            hasIssue={false}
          >
            <div className="optionlist-container">
              {doorTypeOptions(allText).map((option) => (
                <ConfiguratorImageOption
                  name="door-type-option"
                  option={option}
                  value={selectedDoorType}
                  setValue={setSelectedDoorType}
                  key={"door-type-option-" + option.id}
                />
              ))}
            </div>
          </StepItem>
        )}
        {(selectedFurnitureType === FurnitureType.decor ||
          selectedFurnitureType === FurnitureType.aluminium) &&
          (aquarium.aquariumDimensions.width >= 100 ||
            (aquarium.aquariumDimensions.width >= 100 &&
              (aquarium.filter.filterType === FilterOptions.none ||
                aquarium.filter.filterType === null ||
                aquarium.filter.filterType === FilterOptions.external ||
                aquarium.filter.filterType === FilterOptions.biological))) && (
            <StepItem
              title={allText.tid_st5_28}
              helptext={allText.tid_st5_29}
              errortext={allText.tid_st5_30}
              hasIssue={stepItemInfoOverview.furniture.electraRuimte.hasIssue}
            >
              <div className="optionlist-container">
                {electraRuimteOptions(allText)
                  .filter((option) => {
                    if (isElectraRuimte(option.id)) {
                      const checkObj: Aquarium = {
                        ...aquarium,
                        furniture: {
                          ...aquarium.furniture,
                          electraRuimte: option.id,
                        },
                      };
                      return !checkElectraRuimte(checkObj);
                    }
                  })
                  .map((option) => (
                    <ConfiguratorImageOption
                      name="electra-ruimte-option"
                      option={option}
                      value={electraRuimteLocation}
                      setValue={setElectraRuimteLocation}
                      key={"electra-ruimte-option-" + option.id}
                    />
                  ))}
              </div>
            </StepItem>
          )}
        {(selectedFurnitureType === FurnitureType.decor ||
          selectedFurnitureType === FurnitureType.aluminium) && (
          <StepItem
            title={allText.tid_st5_31}
            helptext={allText.tid_st5_32}
            errortext={allText.tid_st5_33}
            hasIssue={false}
          >
            <IconToggle
              checked={aquaswitch}
              setValue={setAquaswitch}
              text={allText.tid_st5_34}
            />
          </StepItem>
        )}
        {(selectedFurnitureType === FurnitureType.decor ||
          selectedFurnitureType === FurnitureType.aluminium) && (
          <StepItem
            title={allText.tid_st5_35}
            helptext={allText.tid_st5_36}
            errortext={allText.tid_st5_37}
            hasIssue={false}
          >
            <div className="window-paint-side-options-container">
              <label
                htmlFor="removable-side-option-left"
                className={
                  "window-paint-side-option " +
                  (removableSideLeft ? "window-paint-side-option-active" : "")
                }
              >
                {allText.tid_st5_38}
                <input
                  checked={removableSideLeft}
                  type="checkbox"
                  id="removable-side-option-left"
                  onChange={() => setRemovableSideLeft(!removableSideLeft)}
                />
                <span className="checkmark"></span>
              </label>
              <label
                htmlFor="removable-side-option-right"
                className={
                  "window-paint-side-option " +
                  (removableSideRight ? "window-paint-side-option-active" : "")
                }
              >
                {allText.tid_st5_39}
                <input
                  checked={removableSideRight}
                  type="checkbox"
                  id="removable-side-option-right"
                  onChange={() => setRemovableSideRight(!removableSideRight)}
                />
                <span className="checkmark"></span>
              </label>
            </div>
          </StepItem>
        )}
      </>
    </ConfiguratorStep>
  );
}

export default FurnitureStep;
