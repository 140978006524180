import React, { useState } from "react";
import { ProductCategory } from "../../@types/product";
import { CaretRight, X } from "@phosphor-icons/react";

type Props = {
  selectedCategories: ProductCategory[];
  setSelectedCategories: React.Dispatch<
    React.SetStateAction<ProductCategory[]>
  >;
};

function SelectProductCategoryMenu({
  selectedCategories,
  setSelectedCategories,
}: Props) {
  const [showCategoryMenu, setShowCategoryMenu] = useState<boolean>(false);

  const updateCategoryList = (category: ProductCategory) => {
    if (selectedCategories.includes(category)) {
      const indexToRemove = selectedCategories.indexOf(category);
      const newCategoryList = selectedCategories.filter(
        (item, index) => index !== indexToRemove
      );
      setSelectedCategories(newCategoryList);
    } else {
      const newCategoryList = [...selectedCategories, category];
      setSelectedCategories(newCategoryList);
    }
  };

  return (
    <>
      <div className="add-product-category-dropdown">
        <button
          onClick={(e) => {
            e.preventDefault();
            setShowCategoryMenu(!showCategoryMenu);
          }}
          className="add-product-category-button"
        >
          {showCategoryMenu ? (
            <X weight="bold" />
          ) : (
            <CaretRight weight="bold" />
          )}
          Categorieën {"(" + selectedCategories.length + ")"}
        </button>
        <div
          className="add-product-dropdown-content text-input-container"
          style={{ display: showCategoryMenu ? "block" : "none" }}
        >
          <label className="add-product-category-input">
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.boosterPumpsSaltwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.boosterPumpsSaltwater}
              checked={selectedCategories.includes(
                ProductCategory.boosterPumpsSaltwater
              )}
            />
            Opvoerpompen - zeewater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.boosterPumpsFreshwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.boosterPumpsFreshwater}
              checked={selectedCategories.includes(
                ProductCategory.boosterPumpsFreshwater
              )}
            />
            Opvoerpompen - zoetwater
          </label>
          <label>
            <input
              onClick={() => updateCategoryList(ProductCategory.LEDSaltwater)}
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.LEDSaltwater}
              checked={selectedCategories.includes(
                ProductCategory.LEDSaltwater
              )}
            />
            LED verlichting - zeewater
          </label>
          <label>
            <input
              onClick={() => updateCategoryList(ProductCategory.LEDFreshwater)}
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.LEDFreshwater}
              checked={selectedCategories.includes(
                ProductCategory.LEDFreshwater
              )}
            />
            LED verlichting - zoetwater
          </label>
          <label>
            <input
              onClick={() => updateCategoryList(ProductCategory.LEDTurtles)}
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.LEDTurtles}
              checked={selectedCategories.includes(ProductCategory.LEDTurtles)}
            />
            LED verlichting - schildpad
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.heatingSaltwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.heatingSaltwater}
              checked={selectedCategories.includes(
                ProductCategory.heatingSaltwater
              )}
            />
            Verwarming - zeewater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.heatingFreshwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.heatingFreshwater}
              checked={selectedCategories.includes(
                ProductCategory.heatingFreshwater
              )}
            />
            Verwarming - zoetwater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.filtersFreshwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.filtersFreshwater}
              checked={selectedCategories.includes(
                ProductCategory.filtersFreshwater
              )}
            />
            Filtermateriaal - zoetwater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.circulationPumpsSaltwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.circulationPumpsSaltwater}
              checked={selectedCategories.includes(
                ProductCategory.circulationPumpsSaltwater
              )}
            />
            Stromingspompen - zeewater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.proteinSkimmersSaltwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.proteinSkimmersSaltwater}
              checked={selectedCategories.includes(
                ProductCategory.proteinSkimmersSaltwater
              )}
            />
            Eiwitafschuimer - zeewater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(
                  ProductCategory.automaticRefillSystemsSaltwater
                )
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.automaticRefillSystemsSaltwater}
              checked={selectedCategories.includes(
                ProductCategory.automaticRefillSystemsSaltwater
              )}
            />
            Automatisch bijvulsysteem - zeewater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.co2SystemFreshwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.co2SystemFreshwater}
              checked={selectedCategories.includes(
                ProductCategory.co2SystemFreshwater
              )}
            />
            CO2-systeem - zoetwater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.interiorSaltwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.interiorSaltwater}
              checked={selectedCategories.includes(
                ProductCategory.interiorSaltwater
              )}
            />
            Interieur - zeewater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.interiorFreshwater)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.interiorFreshwater}
              checked={selectedCategories.includes(
                ProductCategory.interiorFreshwater
              )}
            />
            Interieur - zoetwater
          </label>
          <label>
            <input
              onClick={() =>
                updateCategoryList(ProductCategory.interiorTurtles)
              }
              type="checkbox"
              name="add-product-category"
              value={ProductCategory.interiorTurtles}
              checked={selectedCategories.includes(
                ProductCategory.interiorTurtles
              )}
            />
            Interieur - schildpad
          </label>
        </div>
      </div>
    </>
  );
}

export default SelectProductCategoryMenu;
