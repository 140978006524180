import { ClipboardText } from "@phosphor-icons/react";
import CaretRight from "../../images/caret-double-right.svg";
import { GradientSubTitle, SmallText, Text } from "../atoms/StyledComponents";
import { useDispatch } from "react-redux";
import { setShowOverviewScreen } from "../../redux/configuratorSlice";
import { AllTextObject } from "../../@types/language";

type Props = {
  allText: AllTextObject;
  disabled: boolean;
};

function GoToOverviewButton({ allText, disabled }: Props) {
  const dispatch = useDispatch();

  const handleShowOverview = (disabled: boolean) => {
    if (!disabled) {
      dispatch(setShowOverviewScreen(true));
    }
  };
  return (
    <a
      className={
        "configurator-overview-button-container " +
        (disabled ? "configurator-overview-button-container-disabled" : "")
      }
      id="go-to-overview-button"
      onClick={() => handleShowOverview(disabled)}
    >
      <div>
        <img
          src={CaretRight}
          className="configurator-overview-button-arrow"
          alt="Caret right"
        />
      </div>
      <div className="configurator-overview-button-content">
        <div className="configurator-overview-button-title-status">
          <GradientSubTitle color="var(--neutral-gradient-100)">
          {allText.tid_con_01}
          </GradientSubTitle>
        </div>
        <SmallText
          className="configurator-overview-button-text"
          color="var(--neutral-100)"
        >
          {allText.tid_con_02}
        </SmallText>
      </div>
      <div className="configurator-overview-button-symbol">
        <ClipboardText size={64} color="var(--green-600)" />
      </div>
    </a>
  );
}

export default GoToOverviewButton;
