import * as THREE from "three";
import {
  colorPlankSide,
  makeCircle,
  makeGeometryMaterials,
  makePlank,
  makeRectangle,
} from "./helper";
import { DecorTexture } from "../../@types/textures";
import { store } from "../../redux/store";

const getWidthStripValues = () => {
  return store.getState().configurator.widthStripValues;
};

const scale = 25;
const PI = 3.14159265359;

const lengthstripsDepth = 5;
const lengthstripsHeight = 1;
const widthstripsDepth = 12;
const widthstripsHeight = 1;
export const lightcoverDiff = 4; //how much lower the lengthstrips should be
export const coverHeight = 10; //Height of the cover
const lidHeight = 2; //how much the cover is over the aquarium
const fingerholediameter = 2;
const fingerholediff = 5;
const griddiameter = 6;
const coverholeDiameter = 7.5; //diameter of halfcircles in coverplank behind aquarium
const coverholeSideWidthDiff = 3; //how much the half circles are from the sides

const minWidth = 60;
const minDepth = 60;
const minSideWithstripDepth = 70;

//TODO zet dit in de database en haal dit op adhv een json bestand
export const lidwidths = [60, 120, 180, 240, 260, 300, 360];

let currWidth: number;
let currHeight: number;
let currDepth: number;
let glassWidth: number;
let amountWidthStrips = 0;
let amountlids = 0;
let coverpanels = true;
let coverPanelTexture: THREE.Texture[] = [];
let coverPlateTexture = 0xdddddd;

// draws the option lightcover
export function makeLightCoverAquarium(
  scene: THREE.Scene,
  width: number,
  height: number,
  depth: number,
  newGlassWidth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  for (let i = aquariumTypePlanks.length - 1; i >= 0; i--) {
    scene.remove(aquariumTypePlanks[i]);
    aquariumTypePlanks.splice(i);
  }
  amountWidthStrips = getAmountWidthStrips(width, depth);

  amountlids = 1;
  for (let i = 0; i < lidwidths.length; i++) {
    if (width > lidwidths[i]) {
      amountlids += 1;
    }
  }

  let lengthstripsHeightdiff =
    widthstripsHeight / scale + lengthstripsHeight / scale / 2;

  //lightcover
  aquariumTypePlanks[0] = makePlank(
    scene,
    0,
    height / scale / 2 - coverHeight / scale / 2 + lidHeight / scale,
    depth / scale / 2 + 0.01,
    width / scale + 0.02,
    coverHeight / scale,
    0.01,
    0xeeeeee
  ); //front
  aquariumTypePlanks[1] = makePlank(
    scene,
    width / scale / 2 + 0.01,
    height / scale / 2 - coverHeight / scale / 2 + lidHeight / scale,
    0,
    0.01,
    coverHeight / scale,
    depth / scale + 0.02,
    0xeeeeee
  ); //right
  aquariumTypePlanks[2] = makeLightCoverWithHoles(scene, width, height, depth); //makePlank(scene, 0,height/scale/2-coverHeight/scale/2+lidHeight/scale, -depth/scale/2-0.01, width/scale+0.02, coverHeight/scale, 0.01, 0xeeeeee);//back
  aquariumTypePlanks[3] = makePlank(
    scene,
    -width / scale / 2 - 0.01,
    height / scale / 2 - coverHeight / scale / 2 + lidHeight / scale,
    0,
    0.01,
    coverHeight / scale,
    depth / scale + 0.02,
    0xeeeeee
  ); //left
  //[RIGHT, LEFT, TOP, BOTTOM, FRONT, BACK]
  //length strips
  aquariumTypePlanks[4] = colorPlankSide(
    scene,
    0,
    height / (scale * 2) - lengthstripsHeightdiff - lightcoverDiff / scale,
    depth / (scale * 2) - newGlassWidth - lengthstripsDepth / scale / 2,
    width / scale - 2 * newGlassWidth,
    lengthstripsHeight / scale,
    lengthstripsDepth / scale,
    [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0xdefcff],
    [0.3, 0.3, 0.3, 0.3, 1, 0.3]
  );
  aquariumTypePlanks[5] = colorPlankSide(
    scene,
    0,
    height / (scale * 2) - lengthstripsHeightdiff - lightcoverDiff / scale,
    -depth / (scale * 2) + newGlassWidth + lengthstripsDepth / scale / 2,
    width / scale - 2 * newGlassWidth,
    lengthstripsHeight / scale,
    lengthstripsDepth / scale,
    [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000],
    [0.3, 0.3, 0.3, 0.3, 0.3, 1]
  );

  //sidewidthstrips
  aquariumTypePlanks[6] = colorPlankSide(
    scene,
    width / scale / 2 - glassWidth - lengthstripsDepth / scale / 2,
    height / (scale * 2) - newGlassWidth - lightcoverDiff / scale,
    0,
    lengthstripsDepth / scale,
    widthstripsHeight / scale,
    depth / scale - 2 * newGlassWidth,
    [0x000000, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0x000000],
    [1, 0.3, 0.3, 0.3, 1, 1]
  );
  aquariumTypePlanks[7] = colorPlankSide(
    scene,
    -(width / scale / 2 - glassWidth - lengthstripsDepth / scale / 2),
    height / (scale * 2) - newGlassWidth - lightcoverDiff / scale,
    0,
    lengthstripsDepth / scale,
    widthstripsHeight / scale,
    depth / scale - 2 * newGlassWidth,
    [0xdefcff, 0x000000, 0xdefcff, 0xdefcff, 0x000000, 0x000000],
    [0.3, 1, 0.3, 0.3, 1, 1]
  );
  //widthstrips
  for (let i = 0; i < amountWidthStrips; i++) {
    aquariumTypePlanks[i + 8] = colorPlankSide(
      scene,
      -width / scale / 2 +
        ((i + 1) * (width / scale - 2 * newGlassWidth)) /
          (amountWidthStrips + 1),
      height / (scale * 2) -
        widthstripsHeight / scale / 2 -
        lightcoverDiff / scale,
      0,
      widthstripsDepth / scale,
      widthstripsHeight / scale,
      depth / scale - 2 * newGlassWidth,
      [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0x000000],
      [0.3, 0.3, 0.3, 0.3, 1, 1]
    );
  }

  //coverpanels
  for (let i = 0; i < amountlids; i++) {
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips] = makePlank(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      height / scale / 2 + lidHeight / scale / 2,
      0,
      width / scale / amountlids - 0.01,
      lidHeight / scale - 0.01,
      depth / scale - 0.01,
      coverPlateTexture
    );
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1] = makeCircle(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      height / scale / 2 + lidHeight / scale + 0.001,
      depth / scale / 2 - fingerholediff / scale,
      fingerholediameter / scale / 2,
      0x000000
    );
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1].rotateX(
      (PI * 270) / 180
    );
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2] = makeCircle(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      height / scale / 2 + lidHeight / scale + 0.001,
      0,
      griddiameter / scale / 2,
      0x000000
    );
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2].rotateX(
      (PI * 270) / 180
    );
  }

  stripsVisibleInvisible(scene, width, depth, aquariumTypePlanks); //hides or shows the lengthstrips and widthstrips if the width and depth is big enough

  currDepth = depth;
  currWidth = width;
  currHeight = height;
  glassWidth = newGlassWidth;
  coverpanels = true;
  changeLightCoverPanels(scene, aquariumTypePlanks, coverpanels); // by default the coverpanels are hidden
  changeLightCoverTexture("", aquariumTypePlanks, true);
}

function makeLightCoverWithHoles(
  scene: THREE.Scene,
  width: number,
  height: number,
  depth: number
) {
  let shape = new THREE.Shape();
  let amountSegments = 40;
  //x,y,z: 0,height/scale/2-coverHeight/scale/2+lidHeight/scale, -depth/scale/2-0.01
  //widht, height, depth: width/scale+0.02, coverHeight/scale, 0.01
  shape.moveTo(
    -width / scale / 2 - 0.01,
    height / scale / 2 + lidHeight / scale
  );
  shape.lineTo(
    -width / scale / 2 + coverholeSideWidthDiff / scale,
    height / scale / 2 + lidHeight / scale
  );
  let middle_x =
    -width / scale / 2 +
    coverholeSideWidthDiff / scale +
    coverholeDiameter / scale / 2;
  let middle_y = height / scale / 2 + lidHeight / scale;
  let angle = 0;

  for (let i = 0; i < amountSegments; i++) {
    angle += PI / amountSegments;
    shape.lineTo(
      middle_x - (Math.cos(angle) * coverholeDiameter) / scale / 2,
      middle_y + (Math.sin(-angle) * coverholeDiameter) / scale / 2
    );
  }
  shape.lineTo(
    width / scale / 2 -
      coverholeSideWidthDiff / scale -
      coverholeDiameter / scale,
    height / scale / 2 + lidHeight / scale
  );
  middle_x =
    width / scale / 2 -
    coverholeSideWidthDiff / scale -
    coverholeDiameter / scale / 2;
  middle_y = height / scale / 2 + lidHeight / scale;
  angle = 0;

  for (let i = 0; i < amountSegments; i++) {
    angle += PI / amountSegments;
    shape.lineTo(
      middle_x - (Math.cos(angle) * coverholeDiameter) / scale / 2,
      middle_y + (Math.sin(-angle) * coverholeDiameter) / scale / 2
    );
  }
  shape.lineTo(
    width / scale / 2 + 0.01,
    height / scale / 2 + lidHeight / scale
  );
  shape.lineTo(
    width / scale / 2 + 0.01,
    height / scale / 2 + lidHeight / scale - coverHeight / scale
  );
  shape.lineTo(
    -width / scale / 2 - 0.01,
    height / scale / 2 + lidHeight / scale - coverHeight / scale
  );

  let shapeGeo = new THREE.ExtrudeGeometry(shape, {
    depth: 0.01,
    bevelEnabled: false,
  });
  let shapeMat = new THREE.MeshPhongMaterial({
    color: 0xeeeeee,
    shininess: 50,
    emissive: 0xeeeeee,
  });
  let shapeMesh = new THREE.Mesh(shapeGeo, shapeMat);
  shapeMesh.position.z = -depth / scale / 2 - 0.01;
  scene.add(shapeMesh);
  return shapeMesh;
}

//looks if lengthstrips must be visible, if not it makes them invisible
function stripsVisibleInvisible(
  scene: THREE.Scene,
  width: number,
  depth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  if (width > minWidth || depth > minDepth) {
    scene.add(aquariumTypePlanks[4]);
    scene.add(aquariumTypePlanks[5]);
  } else {
    scene.remove(aquariumTypePlanks[4]);
    scene.remove(aquariumTypePlanks[5]);
  }

  if (depth > minSideWithstripDepth) {
    scene.add(aquariumTypePlanks[6]);
    scene.add(aquariumTypePlanks[7]);
  } else {
    scene.remove(aquariumTypePlanks[6]);
    scene.remove(aquariumTypePlanks[7]);
  }
}

export function changeLightCoverDepth(
  scene: THREE.Scene,
  depth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //put the coverlit and the lengthstrip in the right place
  aquariumTypePlanks[0].position.z += (depth - currDepth) / scale / 2;
  aquariumTypePlanks[1].geometry.scale(
    1,
    1,
    (depth / scale + 0.02) / (currDepth / scale + 0.02)
  );
  aquariumTypePlanks[2].position.z += (currDepth - depth) / scale / 2;
  aquariumTypePlanks[3].geometry.scale(
    1,
    1,
    (depth / scale + 0.02) / (currDepth / scale + 0.02)
  );
  //doesn't matter if they are drawn or not because stripsVisibleInvisible() makes sure they are visible when they should be
  aquariumTypePlanks[4].position.z += (depth - currDepth) / scale / 2;
  aquariumTypePlanks[5].position.z += (currDepth - depth) / scale / 2;

  aquariumTypePlanks[6].geometry.scale(
    1,
    1,
    (depth / scale - 2 * glassWidth) / (currDepth / scale - 2 * glassWidth)
  );
  aquariumTypePlanks[7].geometry.scale(
    1,
    1,
    (depth / scale - 2 * glassWidth) / (currDepth / scale - 2 * glassWidth)
  );
  //make widthstrips longer
  for (let i = 0; i < amountWidthStrips; i++) {
    aquariumTypePlanks[i + 8].geometry.scale(
      1,
      1,
      (depth - 2 * glassWidth) / (currDepth - 2 * glassWidth)
    );
  }

  //make the coverpanels longer
  for (let i = 0; i < amountlids; i++) {
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips].geometry.scale(
      1,
      1,
      (depth / scale - 0.01) / (currDepth / scale - 0.01)
    );
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1].position.z +=
      (depth - currDepth) / scale / 2;
  }
  let newAmountWidthStrips = getAmountWidthStrips(currWidth, depth);

  //if the amount of widthstrips is changed
  if (newAmountWidthStrips !== amountWidthStrips) {
    changeLightCoverWidth(scene, currWidth, aquariumTypePlanks); //om ervoor te zorgen dat de breedtestrips goed zijn en omdat ik te lui ben om die code hierin te zetten
  }
  //make the lengthstrips invisible if the width is too small
  stripsVisibleInvisible(scene, currWidth, depth, aquariumTypePlanks);
  currDepth = depth;
  changeLightCoverTexture("", aquariumTypePlanks, true);
  changeCoverPlateTexture(coverPlateTexture, aquariumTypePlanks);
}

export function changeLightCoverWidth(
  scene: THREE.Scene,
  width: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  //lightcover
  aquariumTypePlanks[0].geometry.scale(
    (width / scale + 0.02) / (currWidth / scale + 0.02),
    1,
    1
  );
  aquariumTypePlanks[1].position.x += (width - currWidth) / scale / 2;
  scene.remove(aquariumTypePlanks[2]);
  aquariumTypePlanks[2] = makeLightCoverWithHoles(
    scene,
    width,
    currHeight,
    currDepth
  );
  aquariumTypePlanks[3].position.x += (currWidth - width) / scale / 2;

  //lengthstrips
  aquariumTypePlanks[4].geometry.scale(
    (width / scale - 2 * glassWidth) / (currWidth / scale - 2 * glassWidth),
    1,
    1
  );
  aquariumTypePlanks[5].geometry.scale(
    (width / scale - 2 * glassWidth) / (currWidth / scale - 2 * glassWidth),
    1,
    1
  );

  //widthstrips
  aquariumTypePlanks[6].position.x += (width - currWidth) / scale / 2;
  aquariumTypePlanks[7].position.x += (currWidth - width) / scale / 2;

  let newAmountlids = 1;
  for (let i = 0; i < lidwidths.length; i++) {
    if (width > lidwidths[i]) {
      newAmountlids += 1;
    }
  }

  let newAmountWidthStrips = getAmountWidthStrips(width, currDepth);

  if (newAmountWidthStrips !== amountWidthStrips) {
    amountWidthStrips = newAmountWidthStrips;
    for (let i = aquariumTypePlanks.length - 1; i >= 8; i--) {
      scene.remove(aquariumTypePlanks[i]);
      aquariumTypePlanks.splice(i);
    }
    for (let i = 0; i < amountWidthStrips; i++) {
      //draw widthstrips
      aquariumTypePlanks[i + 8] = colorPlankSide(
        scene,
        -width / scale / 2 +
          ((i + 1) * (width / scale - 2 * glassWidth)) /
            (amountWidthStrips + 1),
        currHeight / (scale * 2) -
          widthstripsHeight / scale / 2 -
          lightcoverDiff / scale,
        0,
        widthstripsDepth / scale,
        glassWidth,
        currDepth / scale - 2 * glassWidth,
        [0xdefcff, 0xdefcff, 0xdefcff, 0xdefcff, 0x000000, 0x000000],
        [0.3, 0.3, 0.3, 0.3, 1, 1]
      );
    }
    for (let i = 0; i < amountlids; i++) {
      //draw coverpanels
      aquariumTypePlanks[i * 3 + 8 + amountWidthStrips] = makePlank(
        scene,
        -width / scale / 2 +
          i * (width / scale / amountlids) +
          width / scale / amountlids / 2,
        currHeight / scale / 2 + lidHeight / scale / 2,
        0,
        width / scale / amountlids - 0.01,
        lidHeight / scale - 0.01,
        currDepth / scale - 0.01,
        0xdddddd
      );
      aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1] = makeCircle(
        scene,
        -width / scale / 2 +
          i * (width / scale / amountlids) +
          width / scale / amountlids / 2,
        currHeight / scale / 2 + lidHeight / scale + 0.001,
        currDepth / scale / 2 - fingerholediff / scale,
        fingerholediameter / scale / 2,
        0x000000
      );
      aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1].rotateX(
        (PI * 270) / 180
      );
      aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2] = makeCircle(
        scene,
        -width / scale / 2 +
          i * (width / scale / amountlids) +
          width / scale / amountlids / 2,
        currHeight / scale / 2 + lidHeight / scale + 0.001,
        0,
        griddiameter / scale / 2,
        0x000000
      );
      aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2].rotateX(
        (PI * 270) / 180
      );
      if (!coverpanels) {
        //hide coverpanels if they aren't checked
        scene.remove(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips]);
        scene.remove(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1]);
        scene.remove(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2]);
      }
    }
    stripsVisibleInvisible(scene, width, currDepth, aquariumTypePlanks);
    changeCoverPlateTexture(coverPlateTexture, aquariumTypePlanks);
    currWidth = width;
    return;
  } else {
    //if the amount of widthstrips hasn't changed
    for (let i = 0; i < amountWidthStrips; i++) {
      aquariumTypePlanks[i + 8].position.x =
        -width / scale / 2 +
        ((i + 1) * (width / scale - 2 * glassWidth)) / (amountWidthStrips + 1);
    }
  }
  amountlids = newAmountlids;
  //remove all lids, because the planks can be in the wrong place is the aquariumtypeplanks array
  //if the amount of widthstrips changes all the aquariumtypeplanks after that are removed as well
  //this is because it began to be complicated if the amount of widthstrips got smaller or bigger and all the lids would be moved
  for (let i = aquariumTypePlanks.length - 1; i >= 8 + amountWidthStrips; i--) {
    scene.remove(aquariumTypePlanks[i]);
    aquariumTypePlanks.splice(i);
  }
  for (let i = 0; i < amountlids; i++) {
    //draw coverpanels
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips] = makePlank(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      currHeight / scale / 2 + lidHeight / scale / 2,
      0,
      width / scale / amountlids - 0.01,
      lidHeight / scale - 0.01,
      currDepth / scale - 0.01,
      0xdddddd
    );
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1] = makeCircle(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      currHeight / scale / 2 + lidHeight / scale + 0.001,
      currDepth / scale / 2 - fingerholediff / scale,
      fingerholediameter / scale / 2,
      0x000000
    );
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1].rotateX(
      (PI * 270) / 180
    );
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2] = makeCircle(
      scene,
      -width / scale / 2 +
        i * (width / scale / amountlids) +
        width / scale / amountlids / 2,
      currHeight / scale / 2 + lidHeight / scale + 0.001,
      0,
      griddiameter / scale / 2,
      0x000000
    );
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2].rotateX(
      (PI * 270) / 180
    );
    if (!coverpanels) {
      //hide coverpanels if this option isn't checked
      scene.remove(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips]);
      scene.remove(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1]);
      scene.remove(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2]);
    }
  }
  //make the lengthstrips invisible if the width or depth is too small
  stripsVisibleInvisible(scene, width, currDepth, aquariumTypePlanks);
  currWidth = width;
  changeLightCoverTexture("", aquariumTypePlanks, true);
  changeCoverPlateTexture(coverPlateTexture, aquariumTypePlanks);
}

export function changeLightCoverHeight(
  height: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  for (let i = 0; i < aquariumTypePlanks.length; i++) {
    //this works because every plank rises as fast as the top of the aquarium
    aquariumTypePlanks[i].position.y += (height - currHeight) / scale / 2;
  }
  currHeight = height;
}

export function changeLightCoverGlassWidth(
  newGlassWidth: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  if (aquariumTypePlanks.length < 8 + amountWidthStrips) {
    return;
  }
  //lengthstrips
  aquariumTypePlanks[4].geometry.scale(
    (currWidth / scale - 2 * newGlassWidth) /
      (currWidth / scale - 2 * glassWidth),
    1,
    1
  );
  aquariumTypePlanks[5].geometry.scale(
    (currWidth / scale - 2 * newGlassWidth) /
      (currWidth / scale - 2 * glassWidth),
    1,
    1
  );
  aquariumTypePlanks[4].position.z += newGlassWidth - glassWidth;
  aquariumTypePlanks[5].position.z += glassWidth - newGlassWidth;

  //widthstrips
  aquariumTypePlanks[6].geometry.scale(
    1,
    1,
    (currDepth / scale - 2 * newGlassWidth) /
      (currDepth / scale - 2 * glassWidth)
  );
  aquariumTypePlanks[7].geometry.scale(
    1,
    1,
    (currDepth / scale - 2 * newGlassWidth) /
      (currDepth / scale - 2 * glassWidth)
  );
  aquariumTypePlanks[6].position.x += newGlassWidth - glassWidth;
  aquariumTypePlanks[7].position.x += glassWidth - newGlassWidth;
  for (let i = 0; i < amountWidthStrips; i++) {
    //widthstrips
    aquariumTypePlanks[i + 8].geometry.scale(
      (currDepth / scale - 2 * newGlassWidth) /
        (currDepth / scale - 2 * glassWidth),
      1,
      1
    );
  }
  glassWidth = newGlassWidth;
}

//check in the jsonfile how many widthstrips are needed with a certain width and depth
function getAmountWidthStrips(width: number, depth: number) {
  //check where depth is at (between which values is depth)
  let horizontalIndex = getWidthStripValues()["horizontalValues"].length-1;;
  for (let i = 0; i < getWidthStripValues()["horizontalValues"].length; i++) {
    if (depth < Number(getWidthStripValues()["horizontalValues"][i])) {
      horizontalIndex = i + 1;
      break;
    }
  }

  //check where width is at (between which values is width)
  let verticalIndex = getWidthStripValues()["verticalValues"].length;;
  for (let i = 0; i < getWidthStripValues()["verticalValues"].length; i++) {
    if (width < Number(getWidthStripValues()["verticalValues"][i])) {
      verticalIndex = i + 1;
      break;
    }
  }

  //check how many widthstrips there should be
  for (let i = 0; i < getWidthStripValues()["amount_strips"].length; i++) {
    if (
      verticalIndex === Number(getWidthStripValues()["amount_strips"][i][0]) &&
      horizontalIndex === Number(getWidthStripValues()["amount_strips"][i][1])
    ) {
      return Number(getWidthStripValues()["amount_strips"][i][2]);
    }
  }
  return 0;
}

// this toggles the coverpanels, every time this function is called they are hidden if they were shown or shown if they were hidden
export function changeLightCoverPanels(
  scene: THREE.Scene,
  aquariumTypePlanks: THREE.Mesh[],
  drawPanels: boolean
) {
  coverpanels = drawPanels;
  if (coverpanels) {
    //show the panels
    coverpanels = true;
    for (let i = 0; i < amountlids; i++) {
      scene.add(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips]);
      scene.add(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1]);
      scene.add(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2]);
    }
  } else {
    //hide the panels
    coverpanels = false;
    for (let i = 0; i < amountlids; i++) {
      scene.remove(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips]);
      scene.remove(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 1]);
      scene.remove(aquariumTypePlanks[i * 3 + 8 + amountWidthStrips + 2]);
    }
  }
}

//lightcoverDrawn is for if the texture has to be loaded but not yet drawn on the planks because lightcover isn't drawn
export function changeLightCoverTexture(
  texture: String,
  aquariumTypePlanks: THREE.Mesh[],
  lightCoverDrawn: boolean
) {
  if (texture !== "") {
    coverPanelTexture = [
      new THREE.TextureLoader().load(String(texture)),
      new THREE.TextureLoader().load(String(texture)),
    ];
  }
  if (coverPanelTexture.length !== 0 && lightCoverDrawn) {
    aquariumTypePlanks[2].material = makeGeometryMaterials(
      coverPanelTexture[0],
      currWidth / scale,
      0.01,
      coverHeight / scale
    );
    aquariumTypePlanks[0].material = makeGeometryMaterials(
      coverPanelTexture[0],
      currWidth / scale,
      0.01,
      coverHeight / scale
    );

    aquariumTypePlanks[1].material = makeGeometryMaterials(
      coverPanelTexture[1],
      currWidth / scale,
      0.01,
      coverHeight / scale
    );
    aquariumTypePlanks[3].material = makeGeometryMaterials(
      coverPanelTexture[1],
      currWidth / scale,
      0.01,
      coverHeight / scale
    );
  }
}

export function changeCoverPlateTexture(
  texture: number,
  aquariumTypePlanks: THREE.Mesh[]
) {
  coverPlateTexture = texture;
  if (aquariumTypePlanks.length < 8) return;
  for (let i = 0; i < amountlids; i++) {
    aquariumTypePlanks[i * 3 + 8 + amountWidthStrips].material =
      new THREE.MeshPhongMaterial({
        color: coverPlateTexture,
        shininess: 50,
        emissive: coverPlateTexture,
      });
  }
}

export const amountStripLightCover = () => {
  return amountWidthStrips;
};
