import React from "react";
import "../../styles/iconSwitch.css";
import { Text } from "../atoms/StyledComponents";

type Props = {
  text: string;
  checked: boolean;
  setValue: React.Dispatch<React.SetStateAction<boolean>>;
  color?: string;
  disabled?: boolean;
};

function IconToggle({ text, checked, setValue, color, disabled }: Props) {
  const setStateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    setValue(!checked);
  };

  return (
    <div
      className={
        "icon-toggle-container " +
        (disabled ? "icon-toggle-container-disabled" : "")
      }
    >
      <label className="switch">
        <input
          type="checkbox"
          checked={checked}
          onChange={(e) => setStateValue(e)}
          disabled={disabled}
        />
        <span className="slider round"></span>
      </label>
      <Text color={color ? color : "var(--primary-700)"}>{text}</Text>
    </div>
  );
}

export default IconToggle;
