import { Plus } from "@phosphor-icons/react";
import React, { useEffect, useState } from "react";
import { Steps } from "../../../@types/configurator";
import ConfiguratorStep from "../../templates/ConfiguratorStep";
import StepItem from "../../templates/StepItem";
import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { AquariumType, WaterType } from "../../../@types/aquarium";
import {
  setTechnicsAutoRefillSystem,
  setTechnicsBoosterPump,
  setTechnicsCirculationPump,
  setTechnicsCo2System,
  setTechnicsFilter,
  setTechnicsHeating,
  setTechnicsInterior,
  setTechnicsLed,
  setTechnicsProteinSkimmer,
} from "../../../redux/aquariumSlice";
import WebshopItem from "../../molecules/WebshopItem";
import { ProductCategory, ProductData } from "../../../@types/product";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/css";
import "swiper/css/navigation";
import "swiper/css/pagination";
import "swiper/css/scrollbar";
import { Pagination, A11y } from "swiper/modules";
import { calcAquariumVolume } from "../../../utils/aquarium";
import { AllTextObject } from "../../../@types/language";
import { getProductDataFromDatabase } from "../../../services/api/api";

type Props = {
  allText: AllTextObject;
  setOpenStep: React.Dispatch<React.SetStateAction<Steps | null>>;
};

function TechnicsStep({ allText, setOpenStep }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const isMobile = window.innerWidth < 1024;
  const aquariumVolume = calcAquariumVolume(aquarium.aquariumDimensions);
  const products = useSelector((state: RootState) => state.dashboard.products);
  const dispatch = useDispatch();

  const [boosterPump, setBoosterPump] = useState<ProductData[]>(
    aquarium.technics.boosterPump
  );
  const [ledOption, setLedOption] = useState<ProductData[]>(
    aquarium.technics.led
  );
  const [heating, setHeating] = useState<ProductData[]>(
    aquarium.technics.heating
  );
  const [filter, setFilter] = useState<ProductData[]>(aquarium.technics.filter);
  const [circulationPump, setCirculationPump] = useState<ProductData[]>(
    aquarium.technics.circulationPump
  );
  const [proteinSkimmer, setProteinSkimmer] = useState<ProductData[]>(
    aquarium.technics.proteinSkimmer
  );
  const [autoRefillSystem, setAutoRefillSystem] = useState<ProductData[]>(
    aquarium.technics.automaticRefillSystem
  );
  const [co2System, setCo2System] = useState<ProductData[]>(
    aquarium.technics.co2System
  );
  const [interior, setInterior] = useState<ProductData[]>(
    aquarium.technics.interior
  );

  useEffect(() => {
    getProductDataFromDatabase(dispatch);
  }, []);

  useEffect(() => {
    setBoosterPump(aquarium.technics.boosterPump);
    setLedOption(aquarium.technics.led);
    setHeating(aquarium.technics.heating);
    setFilter(aquarium.technics.filter);
    setCirculationPump(aquarium.technics.circulationPump);
    setProteinSkimmer(aquarium.technics.proteinSkimmer);
    setAutoRefillSystem(aquarium.technics.automaticRefillSystem);
    setCo2System(aquarium.technics.co2System);
    setInterior(aquarium.technics.interior);
  }, [aquarium]);

  useEffect(() => {
    dispatch(setTechnicsBoosterPump(boosterPump));
  }, [boosterPump]);
  useEffect(() => {
    dispatch(setTechnicsLed(ledOption));
  }, [ledOption]);
  useEffect(() => {
    dispatch(setTechnicsHeating(heating));
  }, [heating]);
  useEffect(() => {
    dispatch(setTechnicsFilter(filter));
  }, [filter]);
  useEffect(() => {
    dispatch(setTechnicsCirculationPump(circulationPump));
  }, [circulationPump]);
  useEffect(() => {
    dispatch(setTechnicsProteinSkimmer(proteinSkimmer));
  }, [proteinSkimmer]);
  useEffect(() => {
    dispatch(setTechnicsAutoRefillSystem(autoRefillSystem));
  }, [autoRefillSystem]);
  useEffect(() => {
    dispatch(setTechnicsCo2System(co2System));
  }, [co2System]);
  useEffect(() => {
    dispatch(setTechnicsInterior(interior));
  }, [interior]);

  const filterAquariumDependencies = (item: ProductData) => {
    if (
      (item.dimensionsDependant.minLenght &&
        item.dimensionsDependant.minLenght >
          aquarium.aquariumDimensions.width) ||
      (item.dimensionsDependant.maxLenght &&
        item.dimensionsDependant.maxLenght <
          aquarium.aquariumDimensions.width) ||
      (item.dimensionsDependant.minWidth &&
        item.dimensionsDependant.minWidth >
          aquarium.aquariumDimensions.depth) ||
      (item.dimensionsDependant.maxWidth &&
        item.dimensionsDependant.maxWidth <
          aquarium.aquariumDimensions.depth) ||
      (item.dimensionsDependant.minHeight &&
        item.dimensionsDependant.minHeight >
          aquarium.aquariumDimensions.height) ||
      (item.dimensionsDependant.maxHeight &&
        item.dimensionsDependant.maxHeight <
          aquarium.aquariumDimensions.height) ||
      (item.volumeDependant.minVolume &&
        item.volumeDependant.minVolume > aquariumVolume) ||
      (item.volumeDependant.maxVolume &&
        item.volumeDependant.maxVolume < aquariumVolume)
    ) {
      return false;
    } else {
      return true;
    }
  };

  return (
    <ConfiguratorStep
      allText={allText}
      title={allText.tid_gen_08}
      step={6}
      stepId={Steps.technics}
      symbol={
        <Plus
          className="configurator-step-header-symbol"
          size={80}
          color="var(--primary-300)"
        />
      }
      setOpenStep={setOpenStep}
    >
      <>
        {aquarium.aquariumType !== AquariumType.turtle && (
          <StepItem
            title={allText.tid_st6_01}
            extraTitle={allText.tid_st6_04}
            helptext={allText.tid_st6_02}
            errortext={allText.tid_st6_03}
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={0}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {products
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.boosterPumpsFreshwater
                            : ProductCategory.boosterPumpsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            allText={allText}
                            item={option}
                            currentSelectedItems={boosterPump}
                            setCurrentSelectedItems={setBoosterPump}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {products
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.boosterPumpsFreshwater
                            : ProductCategory.boosterPumpsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          allText={allText}
                          item={option}
                          currentSelectedItems={boosterPump}
                          setCurrentSelectedItems={setBoosterPump}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        <StepItem
          title={allText.tid_st6_08}
          extraTitle={allText.tid_st6_11}
          helptext={allText.tid_st6_09}
          errortext={allText.tid_st6_10}
          hasIssue={false}
        >
          <>
            <div className="optionlist-container">
              {isMobile ? (
                <Swiper
                  spaceBetween={12}
                  slidesPerView={2}
                  modules={[Pagination, A11y]}
                  pagination={{ clickable: false }}
                >
                  {products
                    .filter((item) =>
                      item.category.includes(
                        aquarium.waterType === WaterType.salty
                          ? ProductCategory.LEDSaltwater
                          : aquarium.aquariumType === AquariumType.turtle
                          ? ProductCategory.LEDTurtles
                          : ProductCategory.LEDFreshwater
                      )
                    )
                    .filter((item) => filterAquariumDependencies(item))
                    .map((option) => (
                      <SwiperSlide>
                        <WebshopItem
                          allText={allText}
                          item={option}
                          currentSelectedItems={ledOption}
                          setCurrentSelectedItems={setLedOption}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                <>
                  {products
                    .filter((item) =>
                      item.category.includes(
                        aquarium.waterType === WaterType.salty
                          ? ProductCategory.LEDSaltwater
                          : aquarium.aquariumType === AquariumType.turtle
                          ? ProductCategory.LEDTurtles
                          : ProductCategory.LEDFreshwater
                      )
                    )
                    .filter((item) => filterAquariumDependencies(item))
                    .map((option) => (
                      <WebshopItem
                        allText={allText}
                        item={option}
                        currentSelectedItems={ledOption}
                        setCurrentSelectedItems={setLedOption}
                      />
                    ))}
                </>
              )}
            </div>
          </>
        </StepItem>
        {aquarium.aquariumType !== AquariumType.turtle && (
          <StepItem
            title={allText.tid_st6_12}
            extraTitle={allText.tid_st6_15}
            helptext={allText.tid_st6_13}
            errortext={allText.tid_st6_14}
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {products
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.heatingFreshwater
                            : ProductCategory.heatingSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            allText={allText}
                            item={option}
                            currentSelectedItems={heating}
                            setCurrentSelectedItems={setHeating}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {products
                      .filter((item) =>
                        item.category.includes(
                          aquarium.waterType === WaterType.fresh
                            ? ProductCategory.heatingFreshwater
                            : ProductCategory.heatingSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          allText={allText}
                          item={option}
                          currentSelectedItems={heating}
                          setCurrentSelectedItems={setHeating}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.waterType === WaterType.fresh &&
          aquarium.aquariumType !== AquariumType.turtle && (
            <StepItem
              title={allText.tid_st6_16}
              extraTitle={allText.tid_st6_19}
              helptext={allText.tid_st6_17}
              errortext={allText.tid_st6_18}
              hasIssue={false}
            >
              <>
                <div className="optionlist-container">
                  {isMobile ? (
                    <Swiper
                      spaceBetween={12}
                      slidesPerView={2}
                      modules={[Pagination, A11y]}
                      pagination={{ clickable: false }}
                    >
                      {products
                        .filter((item) =>
                          item.category.includes(
                            ProductCategory.filtersFreshwater
                          )
                        )
                        .filter((item) => filterAquariumDependencies(item))
                        .map((option) => (
                          <SwiperSlide>
                            <WebshopItem
                              allText={allText}
                              item={option}
                              currentSelectedItems={filter}
                              setCurrentSelectedItems={setFilter}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  ) : (
                    <>
                      {products
                        .filter((item) =>
                          item.category.includes(
                            ProductCategory.filtersFreshwater
                          )
                        )
                        .filter((item) => filterAquariumDependencies(item))
                        .map((option) => (
                          <WebshopItem
                            allText={allText}
                            item={option}
                            currentSelectedItems={filter}
                            setCurrentSelectedItems={setFilter}
                          />
                        ))}
                    </>
                  )}
                </div>
              </>
            </StepItem>
          )}
        {aquarium.waterType === WaterType.salty && (
          <StepItem
            title={allText.tid_st6_20}
            extraTitle={allText.tid_st6_23}
            helptext={allText.tid_st6_21}
            errortext={allText.tid_st6_22}
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {products
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.circulationPumpsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            allText={allText}
                            item={option}
                            currentSelectedItems={circulationPump}
                            setCurrentSelectedItems={setCirculationPump}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {products
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.circulationPumpsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          allText={allText}
                          item={option}
                          currentSelectedItems={circulationPump}
                          setCurrentSelectedItems={setCirculationPump}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.waterType === WaterType.salty && (
          <StepItem
            title={allText.tid_st6_24}
            extraTitle={allText.tid_st6_27}
            helptext={allText.tid_st6_25}
            errortext={allText.tid_st6_26}
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {products
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.proteinSkimmersSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            allText={allText}
                            item={option}
                            currentSelectedItems={proteinSkimmer}
                            setCurrentSelectedItems={setProteinSkimmer}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {products
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.proteinSkimmersSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          allText={allText}
                          item={option}
                          currentSelectedItems={proteinSkimmer}
                          setCurrentSelectedItems={setProteinSkimmer}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.waterType === WaterType.salty && (
          <StepItem
            title={allText.tid_st6_28}
            extraTitle={allText.tid_st6_31}
            helptext={allText.tid_st6_29}
            errortext={allText.tid_st6_30}
            hasIssue={false}
          >
            <>
              <div className="optionlist-container">
                {isMobile ? (
                  <Swiper
                    spaceBetween={12}
                    slidesPerView={2}
                    modules={[Pagination, A11y]}
                    pagination={{ clickable: false }}
                  >
                    {products
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.automaticRefillSystemsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <SwiperSlide>
                          <WebshopItem
                            allText={allText}
                            item={option}
                            currentSelectedItems={autoRefillSystem}
                            setCurrentSelectedItems={setAutoRefillSystem}
                          />
                        </SwiperSlide>
                      ))}
                  </Swiper>
                ) : (
                  <>
                    {products
                      .filter((item) =>
                        item.category.includes(
                          ProductCategory.automaticRefillSystemsSaltwater
                        )
                      )
                      .filter((item) => filterAquariumDependencies(item))
                      .map((option) => (
                        <WebshopItem
                          allText={allText}
                          item={option}
                          currentSelectedItems={autoRefillSystem}
                          setCurrentSelectedItems={setAutoRefillSystem}
                        />
                      ))}
                  </>
                )}
              </div>
            </>
          </StepItem>
        )}
        {aquarium.waterType === WaterType.fresh &&
          aquarium.aquariumType !== AquariumType.turtle && (
            <StepItem
              title={allText.tid_st6_36}
              extraTitle={allText.tid_st6_39}
              helptext={allText.tid_st6_37}
              errortext={allText.tid_st6_38}
              hasIssue={false}
            >
              <>
                <div className="optionlist-container">
                  {isMobile ? (
                    <Swiper
                      spaceBetween={12}
                      slidesPerView={2}
                      modules={[Pagination, A11y]}
                      pagination={{ clickable: false }}
                    >
                      {products
                        .filter((item) =>
                          item.category.includes(
                            ProductCategory.co2SystemFreshwater
                          )
                        )
                        .filter((item) => filterAquariumDependencies(item))
                        .map((option) => (
                          <SwiperSlide>
                            <WebshopItem
                              allText={allText}
                              item={option}
                              currentSelectedItems={co2System}
                              setCurrentSelectedItems={setCo2System}
                            />
                          </SwiperSlide>
                        ))}
                    </Swiper>
                  ) : (
                    <>
                      {products
                        .filter((item) =>
                          item.category.includes(
                            ProductCategory.co2SystemFreshwater
                          )
                        )
                        .filter((item) => filterAquariumDependencies(item))
                        .map((option) => (
                          <WebshopItem
                            allText={allText}
                            item={option}
                            currentSelectedItems={co2System}
                            setCurrentSelectedItems={setCo2System}
                          />
                        ))}
                    </>
                  )}
                </div>
              </>
            </StepItem>
          )}
        <StepItem
          title={allText.tid_st6_40}
          extraTitle={allText.tid_st6_43}
          helptext={allText.tid_st6_41}
          errortext={allText.tid_st6_42}
          hasIssue={false}
        >
          <>
            <div className="optionlist-container">
              {isMobile ? (
                <Swiper
                  spaceBetween={12}
                  slidesPerView={2}
                  modules={[Pagination, A11y]}
                  pagination={{ clickable: false }}
                >
                  {products
                    .filter((item) =>
                      item.category.includes(
                        aquarium.waterType === WaterType.salty
                          ? ProductCategory.interiorSaltwater
                          : aquarium.aquariumType === AquariumType.turtle
                          ? ProductCategory.interiorTurtles
                          : ProductCategory.interiorFreshwater
                      )
                    )
                    .filter((item) => filterAquariumDependencies(item))
                    .map((option) => (
                      <SwiperSlide>
                        <WebshopItem
                          allText={allText}
                          item={option}
                          currentSelectedItems={interior}
                          setCurrentSelectedItems={setInterior}
                        />
                      </SwiperSlide>
                    ))}
                </Swiper>
              ) : (
                <>
                  {products
                    .filter((item) =>
                      item.category.includes(
                        aquarium.waterType === WaterType.salty
                          ? ProductCategory.interiorSaltwater
                          : aquarium.aquariumType === AquariumType.turtle
                          ? ProductCategory.interiorTurtles
                          : ProductCategory.interiorFreshwater
                      )
                    )
                    .filter((item) => filterAquariumDependencies(item))
                    .map((option) => (
                      <WebshopItem
                        allText={allText}
                        item={option}
                        currentSelectedItems={interior}
                        setCurrentSelectedItems={setInterior}
                      />
                    ))}
                </>
              )}
            </div>
          </>
        </StepItem>
      </>
    </ConfiguratorStep>
  );
}

export default TechnicsStep;
