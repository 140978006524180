import React from "react";

type Props = { reminderDate: any };

function ReminderButton({ reminderDate }: Props) {
  return (
    <button
      className={
        "send-reminder-button " +
        (reminderDate ? "send-reminder-button-disabled" : "")
      }
      disabled={reminderDate ? true : false}
    >
      {reminderDate ? `${reminderDate}` : "Stuur reminder"}
    </button>
  );
}

export default ReminderButton;
