import {
  DeliveryOption,
  DoorType,
  ElectraRuimte,
  ExtraLEDFreshWaterOption,
  ExtraLEDSaltWaterOption,
  FilterLocation,
  FilterOptions,
  FullGlassCoverPanels,
  FurnitureType,
  PvcPipeworkPackage,
  TurtleIsland,
} from "../@types/aquarium";
import {
  CountryOptions,
  ImageOption,
  SelectOption,
  TextOption,
} from "../@types/configurator";
import none from "../images/fullglasscoveroptions/none.png";
import sliding from "../images/fullglasscoveroptions/sliding.png";
import nonsliding from "../images/fullglasscoveroptions/nonsliding.png";
import trutleisland_none from "../images/turtleislandoptions/none.png";
import left from "../images/turtleislandoptions/left.png";
import right from "../images/turtleislandoptions/right.png";
import twosided from "../images/turtleislandoptions/twosided.png";
import biological from "../images/filteroptions/biological.png";
import biologist from "../images/filteroptions/biologist.png";
import sump from "../images/filteroptions/sump.png";
import external from "../images/filteroptions/external.png";
import option_none from "../images/option_none.jpg";
import leftback from "../images/filterlocationoptions/leftback.png";
import leftmid from "../images/filterlocationoptions/leftmid.png";
import midback from "../images/filterlocationoptions/backmid.png";
import midmid from "../images/filterlocationoptions/midmid.png";
import rightback from "../images/filterlocationoptions/rightback.png";
import rightmid from "../images/filterlocationoptions/rightmid.png";
import placeholder from "../images/textures/placeholder.jpg";
import frameOnly from "../images/furnituretypeoptions/frameOnly.png";
import decor from "../images/furnituretypeoptions/decor.png";
import aluminium from "../images/furnituretypeoptions/aluminium.png";
import marine from "../images/furnituretypeoptions/marine.webp";
import arrowleft from "../images/arrow-left.png";
import arrowright from "../images/arrow-right.png";
import slidingdoors from "../images/doortypeoptions/slidingdoors.png";
import pushdoors from "../images/doortypeoptions/pushdoors.png";
import removablepanels from "../images/doortypeoptions/removablepanels.png";
import cichlids from "../images/extraledoptions/cichlids.png";
import companionaquarium from "../images/extraledoptions/companionaquarium.png";
import discusaltumsamazone from "../images/extraledoptions/discusaltumsamazone.png";
import fishsoftlpscoral from "../images/extraledoptions/fishsoftlpscoral.png";
import fishsoftlpsstonecoral from "../images/extraledoptions/fishsoftlpsstonecoral.png";
import manyplants from "../images/extraledoptions/manyplants.png";
import onlyfish from "../images/extraledoptions/onlyfish.png";
import pvcPipeworkPlaceholder from "../images/pvcPipeworkPlaceholder.jpg";
import { AllTextObject } from "../@types/language";

export const fullGlassCoverOptions = (
  allText: AllTextObject
): Array<ImageOption> => {
  return [
    {
      id: FullGlassCoverPanels.noPanels,
      img: none,
      title: allText.tid_gen_16,
    },
    {
      id: FullGlassCoverPanels.nonSliding,
      img: nonsliding,
      title: allText.tid_st2_14,
    },
    {
      id: FullGlassCoverPanels.sliding,
      img: sliding,
      title: allText.tid_st2_15,
    },
  ];
};

export const turtleIslandOptions = (
  allText: AllTextObject
): Array<ImageOption> => {
  return [
    {
      id: TurtleIsland.noIsland,
      img: trutleisland_none,
      title: allText.tid_gen_16,
    },
    {
      id: TurtleIsland.left,
      img: left,
      title: allText.tid_gen_17,
    },
    {
      id: TurtleIsland.right,
      img: right,
      title: allText.tid_gen_18,
    },
    {
      id: TurtleIsland.twosided,
      img: twosided,
      title: allText.tid_gen_19,
    },
  ];
};

export const filterOptions = (allText: AllTextObject): Array<ImageOption> => {
  return [
    {
      id: FilterOptions.none,
      img: option_none,
      title: allText.tid_gen_16,
    },
    {
      id: FilterOptions.biological,
      img: biological,
      title: allText.tid_st4_04,
    },
    {
      id: FilterOptions.bio,
      img: biologist,
      title: allText.tid_st4_05,
    },
    {
      id: FilterOptions.external,
      img: external,
      title: allText.tid_st4_06,
    },
    {
      id: FilterOptions.sump,
      img: sump,
      title: allText.tid_st4_07,
    },
  ];
};

export const filterLocationOptions = (
  allText: AllTextObject
): Array<ImageOption> => {
  return [
    {
      id: FilterLocation.leftBack,
      img: leftback,
      title: allText.tid_st4_16,
    },
    {
      id: FilterLocation.leftMid,
      img: leftmid,
      title: allText.tid_st4_17,
    },
    {
      id: FilterLocation.midBack,
      img: midback,
      title: allText.tid_st4_18,
    },
    {
      id: FilterLocation.midMid,
      img: midmid,
      title: allText.tid_st4_19,
    },
    {
      id: FilterLocation.rightBack,
      img: rightback,
      title: allText.tid_st4_20,
    },
    {
      id: FilterLocation.rightMid,
      img: rightmid,
      title: allText.tid_st4_21,
    },
  ];
};
export const furnitureTypeOptions = (
  allText: AllTextObject
): Array<ImageOption> => {
  return [
    {
      id: FurnitureType.none,
      img: option_none,
      title: allText.tid_st5_04,
    },
    {
      id: FurnitureType.frameOnly,
      img: frameOnly,
      title: allText.tid_st5_05,
    },
    {
      id: FurnitureType.decor,
      img: decor,
      title: allText.tid_st5_06,
    },
    {
      id: FurnitureType.aluminium,
      img: aluminium,
      title: allText.tid_st5_07,
    },
    {
      id: FurnitureType.marine,
      img: marine,
      title: allText.tid_st5_08,
    },
  ];
};
export const electraRuimteOptions = (
  allText: AllTextObject
): Array<ImageOption> => {
  return [
    {
      id: ElectraRuimte.none,
      img: option_none,
      title: allText.tid_gen_16,
    },
    {
      id: ElectraRuimte.left,
      img: arrowleft,
      title: allText.tid_gen_17,
    },
    {
      id: ElectraRuimte.right,
      img: arrowright,
      title: allText.tid_gen_18,
    },
  ];
};
export const doorTypeOptions = (allText: AllTextObject): Array<ImageOption> => {
  return [
    {
      id: DoorType.slidingDoors,
      img: slidingdoors,
      title: allText.tid_st5_25,
    },
    {
      id: DoorType.pushDoors,
      img: pushdoors,
      title: allText.tid_st5_26,
    },
    {
      id: DoorType.removablePanels,
      img: removablepanels,
      title: allText.tid_st5_27,
    },
  ];
};

// export const extraLEDFreshWaterOptions: Array<ImageOption> = [
//   {
//     id: ExtraLEDFreshWaterOption.none,
//     img: option_none,
//     title: allText.tid_gen_16,
//   },
//   {
//     id: ExtraLEDFreshWaterOption.companionAquarium,
//     img: companionaquarium,
//     title: "Gezeldschaps verlichting",
//   },
//   {
//     id: ExtraLEDFreshWaterOption.manyPlants,
//     img: manyplants,
//     title: "Vis met veel planten",
//   },
//   {
//     id: ExtraLEDFreshWaterOption.cichlids,
//     img: cichlids,
//     title: "Cichliden",
//   },
//   {
//     id: ExtraLEDFreshWaterOption.discusAltumsAmazone,
//     img: discusaltumsamazone,
//     title: "Discus / altums amazone",
//   },
// ];

// export const extraLEDSaltWaterOptions: Array<ImageOption> = [
//   {
//     id: ExtraLEDSaltWaterOption.none,
//     img: option_none,
//     title: allText.tid_gen_16,
//   },
//   {
//     id: ExtraLEDSaltWaterOption.onlyFish,
//     img: onlyfish,
//     title: "Enkel vissen",
//   },
//   {
//     id: ExtraLEDSaltWaterOption.fishSoftLpsCoral,
//     img: fishsoftlpscoral,
//     title: "Vis, soft en lps koraal",
//   },
//   {
//     id: ExtraLEDSaltWaterOption.fishSoftLpsStoneCoral,
//     img: fishsoftlpsstonecoral,
//     title: "Vis, soft en lps steenkoralen",
//   },
// ];
export const extraPvcPipeworkPackageOptions = (
  allText: AllTextObject
): Array<ImageOption> => {
  return [
    {
      id: PvcPipeworkPackage.diy,
      img: option_none,
      title: allText.tid_st4_32,
    },
    {
      id: PvcPipeworkPackage.byAqualife,
      img: pvcPipeworkPlaceholder,
      title: allText.tid_st4_33,
    },
  ];
};

export const filterLocationPlaceholder = (
  allText: AllTextObject
): ImageOption => {
  return {
    id: FilterLocation.placeholder,
    title: allText.tid_st4_23,
    img: placeholder,
  };
};

export const deliveryOptions: TextOption[] = [
  {
    id: DeliveryOption.pickup,
    title: "Zelf afhalen",
  },
  {
    id: DeliveryOption.onlyDeliver,
    title: "Enkel leveren door Aqualife",
  },
  {
    id: DeliveryOption.deliverHalfInstallation,
    title: "Leveren en halve installatie door Aqualife",
  },
  {
    id: DeliveryOption.deliverCompleteInstallation,
    title: "Volledige installatie door Aqualife",
  },
];

export const countryOptions: SelectOption[] = [
  { value: CountryOptions.belgium, title: "België" },
  { value: CountryOptions.netherlands, title: "Nederland" },
  { value: CountryOptions.germany, title: "Duitsland" },
  { value: CountryOptions.france, title: "Frankrijk" },
  { value: CountryOptions.luxembourg, title: "Luxemburg" },
  { value: CountryOptions.bulgaria, title: "Bulgarije" },
  { value: CountryOptions.czechia, title: "Tsjechië" },
  { value: CountryOptions.denmark, title: "Denemarken" },
  { value: CountryOptions.estonia, title: "Estland" },
  { value: CountryOptions.ireland, title: "Ierland" },
  { value: CountryOptions.greece, title: "Griekenland" },
  { value: CountryOptions.spain, title: "Spanje" },
  { value: CountryOptions.croatia, title: "Kroatië" },
  { value: CountryOptions.italy, title: "Italië" },
  { value: CountryOptions.cyprus, title: "Cyprus" },
  { value: CountryOptions.latvia, title: "Letland" },
  { value: CountryOptions.lithuania, title: "Litouwen" },
  { value: CountryOptions.hungary, title: "Hongarije" },
  { value: CountryOptions.malta, title: "Malta" },
  { value: CountryOptions.austria, title: "Oostenrijk" },
  { value: CountryOptions.poland, title: "Polen" },
  { value: CountryOptions.portugal, title: "Portugal" },
  { value: CountryOptions.romania, title: "Roemenië" },
  { value: CountryOptions.slovenia, title: "Slovenië" },
  { value: CountryOptions.slovakia, title: "Slowakije" },
  { value: CountryOptions.finland, title: "Finland" },
  { value: CountryOptions.sweden, title: "Zweden" },
  { value: CountryOptions.norway, title: "Noorwegen" },
];
