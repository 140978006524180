import { PayloadAction, createAction, createSlice } from "@reduxjs/toolkit";
import { User, UserRole } from "../@types/user";

const initialState: User = {
  email: null,
  agreedToConditions: false,
  loggedIn: false,
  firstname: null,
  role: null,
};

export const resetUser = createAction("REVERT_ALL");

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    setUserEmail: (state, action: PayloadAction<string>) => {
      state.email = action.payload;
    },
    setUserFirstname: (state, action: PayloadAction<string>) => {
      state.firstname = action.payload;
    },
    setUserRole: (state, action: PayloadAction<UserRole>) => {
      state.role = action.payload;
    },
    setAgreedToConditions: (state, action: PayloadAction<boolean>) => {
      state.agreedToConditions = action.payload;
    },
    setUserLoggedIn: (state, action: PayloadAction<boolean>) => {
      state.loggedIn = action.payload;
    },
  },
  extraReducers: (builder) => builder.addCase(resetUser, () => initialState),
});

export const {
  setUserEmail,
  setAgreedToConditions,
  setUserFirstname,
  setUserRole,
  setUserLoggedIn,
} = userSlice.actions;

export default userSlice.reducer;
