import {
  Aquarium,
  AquariumDimensions,
  AquariumType,
  CoverPanelsColor,
  Side,
  SiliconColor,
  WindowPaintOption,
} from "../@types/aquarium";

const defaultDimensions: AquariumDimensions = {
  width: 120,
  depth: 50,
  height: 60,
};

const aquariumSides = {
  left: {
    sideName: Side.left,
    colorPainted: false,
    optiWhite: false,
  },
  right: {
    sideName: Side.right,
    colorPainted: false,
    optiWhite: false,
  },
  front: {
    sideName: Side.front,
    colorPainted: false,
    optiWhite: false,
  },
  back: {
    sideName: Side.back,
    colorPainted: false,
    optiWhite: false,
  },
};

export const defaultAquarium: Aquarium = {
  aquariumDimensions: defaultDimensions,
  sharpenGlass: true,
  waterType: null,
  aquariumType: null,
  aquariumTexture: null,
  coverPanels: false,
  coverPanelsColor: CoverPanelsColor.black,
  fullGlassCoverPanels: null,
  turtleIsland: null,
  lightCover: false,
  grindlist: false,
  windowPaintColor: WindowPaintOption.black,
  aquariumSides,
  filter: {
    filterType: null,
    externalDryroom: false,
    filterLocation: null,
    bioCoverPanels: false,
    osmoseCompartiment: false,
    boreholeLeft: false,
    boreholeRight: false,
  },
  furniture: {
    furnitureType: null,
    dimensions: {
      baseHeight: 85,
      topHeight: 0,
    },
    betonPlex: false,
    seeThrough: false,
    backWall34: false,
    electraRuimte: null,
    removableSideLeft: false,
    removableSideRight: false,
    doorType: null,
    aquaswitch: false,
  },
  technics: {
    boosterPump: [],
    led: [],
    heating: [],
    filter: [],
    circulationPump: [],
    proteinSkimmer: [],
    automaticRefillSystem: [],
    co2System: [],
    interior: [],
  },
  extra: {
    siliconeColor: SiliconColor.black,
    waterproofPlate: true,
    pvcPipeworkPackage: null,
  },
  delivery: null,
};

export const defaultDrawingAquarium: Aquarium = {
  aquariumDimensions: defaultDimensions,
  sharpenGlass: true,
  waterType: null,
  aquariumType: AquariumType.rimless,
  aquariumTexture: null,
  coverPanels: false,
  coverPanelsColor: CoverPanelsColor.black,
  fullGlassCoverPanels: null,
  turtleIsland: null,
  lightCover: false,
  grindlist: false,
  windowPaintColor: WindowPaintOption.black,
  aquariumSides,
  filter: {
    filterType: null,
    externalDryroom: false,
    filterLocation: null,
    bioCoverPanels: false,
    osmoseCompartiment: false,
    boreholeLeft: false,
    boreholeRight: false,
  },
  furniture: {
    furnitureType: null,
    dimensions: {
      baseHeight: 85,
      topHeight: 0,
    },
    betonPlex: false,
    seeThrough: false,
    backWall34: false,
    electraRuimte: null,
    removableSideLeft: false,
    removableSideRight: false,
    doorType: null,
    aquaswitch: false,
  },
  technics: {
    boosterPump: [],
    led: [],
    heating: [],
    filter: [],
    circulationPump: [],
    proteinSkimmer: [],
    automaticRefillSystem: [],
    co2System: [],
    interior: [],
  },
  extra: {
    siliconeColor: SiliconColor.black,
    waterproofPlate: true,
    pvcPipeworkPackage: null,
  },
  delivery: null,
};
