import { useEffect, useState } from "react";
import { GradientSubTitle, Text } from "../../atoms/StyledComponents";
import PriceMenu from "../../molecules/PriceMenu";
import { useSelector } from "react-redux";
import { RootState } from "../../../redux/store";
import { StepStatus } from "../../../@types/configurator";
import { AllTextObject } from "../../../@types/language";

type Props = { allText: AllTextObject; text?: string; title?: string };

function ConfiguratorHeader({allText, text, title }: Props) {
  const stepInfoListRedux = useSelector(
    (state: RootState) => state.configurator.stepInfoList
  );
  const [stepsCompleted, setStepsCompleted] = useState<number>(0);
  const getStepsCompleted = () => {
    const completedSteps = stepInfoListRedux.filter(
      (item) => item.status === StepStatus.isCompleted
    );
    return completedSteps.length;
  };

  useEffect(() => {
    setStepsCompleted(getStepsCompleted());
  }, [stepInfoListRedux]);

  return (
    <div className="configurator-header-container">
      <div id="configurator-header-title">
        <GradientSubTitle color="var(--primary-gradient-200)">
          {title ? title : allText.tid_gen_01}
        </GradientSubTitle>
        {text ? (
          <Text>{text}</Text>
        ) : (
          <Text>{stepsCompleted}{allText.tid_gen_02}</Text>
        )}
      </div>
      <PriceMenu />
    </div>
  );
}

export default ConfiguratorHeader;
