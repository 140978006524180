import { useDispatch } from "react-redux";
import {
  StepInfo,
  StepItemInfoList,
  StepStatus,
  Steps,
} from "../@types/configurator";
import {
  setStepInfoList,
  setStepItemInfoOverview,
} from "../redux/configuratorSlice";
import { Dispatch } from "react";
import { UnknownAction } from "redux";
import {
  checkAquariumDimensions,
  checkAquariumTexture,
  checkAquariumType,
  checkElectraRuimte,
  checkFilterLocation,
  checkFilterType,
  checkFurnitureType,
  checkTurtleIslandLocation,
  getStepIsDisabled,
  getStepProgress,
} from "./configuratorRules";
import { Aquarium } from "../@types/aquarium";
import { autoAdjustAquarium } from "./configurator";
import { AllTextObject } from "../@types/language";

const stepList = (allText: AllTextObject) => {
  return [
    {
      name: Steps.aquariumType,
      step: 1,
      title: allText.tid_gen_04,
    },
    {
      name: Steps.dimensions,
      step: 2,
      title: allText.tid_gen_03,
    },
    {
      name: Steps.windowPaint,
      step: 3,
      title: allText.tid_gen_05,
    },
    {
      name: Steps.filter,
      step: 4,
      title: allText.tid_gen_06,
    },
    {
      name: Steps.furniture,
      step: 5,
      title: allText.tid_gen_07,
    },
    {
      name: Steps.technics,
      step: 6,
      title: allText.tid_gen_08,
    },
  ];
};

export const renderPrevStep = (
  allText: AllTextObject,
  currentStep: number,
  stepInfoList: StepInfo[]
) => {
  const currentStepObj = stepList(allText).filter(
    (e) => e.step === currentStep
  );
  const prevStepObj = stepList(allText).filter(
    (e) => e.step === currentStepObj[0].step - 1
  );
  if (prevStepObj.length > 0) {
    const isAllowedToShow = stepInfoList.find(
      (step) => step.title === prevStepObj[0].name && !step.disabled
    );
    return isAllowedToShow ? prevStepObj[0] : null;
  } else {
    return null;
  }
};

export const renderNextStep = (
  allText: AllTextObject,
  currentStep: number,
  stepInfoList: StepInfo[]
) => {
  const currentStepObj = stepList(allText).filter(
    (e) => e.step === currentStep
  );
  const nextStepObj = stepList(allText).filter(
    (e) => e.step === currentStepObj[0].step + 1
  );
  if (nextStepObj.length > 0) {
    const isAllowedToShow = stepInfoList.find(
      (step) => step.title === nextStepObj[0].name && !step.disabled
    );
    return isAllowedToShow ? nextStepObj[0] : null;
  } else {
    return null;
  }
};

export const updateStepInfoList = (
  dispatch: Dispatch<UnknownAction>,
  stepInfoListRedux: StepInfo[],
  aquarium: Aquarium,
  stepItemInfoOverview: StepItemInfoList
) => {
  autoAdjustAquarium(aquarium, dispatch);
  const stepInfoList: StepInfo[] = stepInfoListRedux.map((step) => {
    return {
      title: step.title,
      status: getStepProgress(step.title, step.opened, aquarium),
      disabled: getStepIsDisabled(step.title, aquarium),
      opened: step.opened,
    };
  });
  dispatch(setStepInfoList(stepInfoList));
  const updatedStepItemInfo = constructUpdatedStepItemList(
    stepItemInfoOverview,
    aquarium
  );
  dispatch(setStepItemInfoOverview(updatedStepItemInfo));
  return stepInfoList;
};

export const getIndividualStepInfo = (
  stepId: Steps,
  stepInfoList: StepInfo[]
) => {
  const stepInfo = stepInfoList.find((step) => step.title === stepId);
  return stepInfo
    ? { status: stepInfo.status, disabled: stepInfo.disabled }
    : { status: StepStatus.toDo, disabled: false };
};

// hier aanduiden welke stappen een issue hebben
const constructUpdatedStepItemList = (
  currentList: StepItemInfoList,
  aquarium: Aquarium
) => {
  const updatedList: StepItemInfoList = {
    ...currentList,
    dimensions: {
      ...currentList.dimensions,
      aquariumDimensions: {
        hasIssue: checkAquariumDimensions(aquarium),
      },
    },
    aquariumType: {
      ...currentList.aquariumType,
      aquariumType: {
        hasIssue: checkAquariumType(aquarium),
      },
      turtleIsland: {
        hasIssue: checkTurtleIslandLocation(aquarium),
      },
      aquariumTexture: {
        hasIssue: checkAquariumTexture(aquarium),
      },
    },
    filter: {
      ...currentList.filter,
      filterType: {
        hasIssue: checkFilterType(aquarium),
      },
      filterLocation: {
        hasIssue: checkFilterLocation(aquarium),
      },
    },
    furniture: {
      ...currentList.furniture,
      furnitureType: {
        hasIssue: checkFurnitureType(aquarium),
      },
      aquariumTexture: {
        hasIssue: checkAquariumTexture(aquarium),
      },
      electraRuimte: {
        hasIssue: checkElectraRuimte(aquarium),
      },
    },
  };
  return updatedList;
};
