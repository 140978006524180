import { Check, X } from "@phosphor-icons/react";

export const getIsPaidIcon = (isPaid: any) => {
  return isPaid ? (
    <Check
      size={18}
      color="var(--green-500)"
      weight="bold"
      className="ispaid-icon"
    />
  ) : (
    <X size={18} color="var(--red-500)" weight="bold" className="ispaid-icon" />
  );
};
export const getIsAdvancedPaidIcon = (isPaid: any) => {
  return isPaid ? (
    <Check
      size={16}
      color="var(--green-500)"
      weight="bold"
      className="isadvancedpaid-icon"
    />
  ) : (
    <X size={16} color="var(--red-500)" weight="bold" className="isadvancedpaid-icon" />
  );
};
