import React from "react";
import "../../styles/dashboard.css";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {};

function AdminMenu({}: Props) {
  const user = useSelector((state: RootState) => state.user);
  const getWelcomeMessage = () => {
    const date = new Date();
    const time = date.getHours();
    if (time >= 6 && time < 12) {
      return "Goedemorgen";
    } else if (time === 12) {
      return "Goedemiddag";
    } else if (time > 12 && time < 18) {
      return "Goedenamiddag";
    } else if (time >= 18 && time <= 22) {
      return "Goedenavond";
    } else if (time > 22 || time < 6) {
      return "Ga eens slapen";
    } else {
      return "Goededag";
    }
  };

  return (
    <section className="admin-menu-container">
      <div className="admin-menu-header">
        <div>
          {getWelcomeMessage()} {user.firstname}
        </div>
        <h2>Admin Dashboard</h2>
      </div>
      <div className="admin-menu-lists-container">
        <ul className="admin-menu-list">
          <li className="admin-menu-list-item">
            <a href="/dashboard/offertes">Offertes</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/products">Producten</a>
            <ul className="admin-menu-list">
              <li className="admin-menu-list-item">
                <a href="/dashboard/products#led-menu">LED's</a>
              </li>
              <li className="admin-menu-list-item">
                <a href="/dashboard/products#heating-menu">Verwarming</a>
              </li>
              <li className="admin-menu-list-item">
                <a href="/dashboard/products#interior-menu">Interieur</a>
              </li>
            </ul>
          </li>
        </ul>
        <ul className="admin-menu-list">
          <li className="admin-menu-list-item">
            <a href="/dashboard/translations">Vertalingen</a>
          </li>
          <li className="admin-menu-list-item">
            <a href="/dashboard/users">Gebruikers</a>
          </li>
        </ul>
        <ul className="admin-menu-list">
          
        </ul>
      </div>
    </section>
  );
}

export default AdminMenu;
