import React, { useEffect } from "react";
import { SubTitle } from "../atoms/StyledComponents";
import { ArrowLeft, Plus, Minus, Trash } from "@phosphor-icons/react";
import { setOpenProductInfoModal } from "../../redux/configuratorSlice";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {};

function ProductInfoModal({}: Props) {
  const productInfo = useSelector(
    (state: RootState) => state.configurator.openProductInfoModal
  );
  const dispatch = useDispatch();
  const formatPrice = (price: number) => {
    return price.toFixed(2).toString().replace(".", ",");
  };

  return (
    <>
      {productInfo && (
        <div className="textures-modal-container">
          <div className="textures-modal-content-container">
            <div className="textures-modal-header">
              <ArrowLeft
                size={32}
                weight="bold"
                onClick={() => dispatch(setOpenProductInfoModal(null))}
                className="texture-modal-arrow"
              />
              <SubTitle color="var(--neutral-800)">{productInfo.name}</SubTitle>
            </div>
            <div className="product-info-content-container">
              <div className="product-info-text-container">
              <div>
                <div className="webshop-item-price">
                  {"€" + formatPrice(productInfo.price)}
                </div>
                <div className="webshop-item-price-excl-btw">
                  {"€" + formatPrice(productInfo.price / 1.21) + " excl. BTW"}
                </div>
              </div>
                {productInfo.isRecommanded && (
                  <p className="product-info-recommanded-text">
                    {productInfo.recommandedText}
                  </p>
                )}
                <p>{productInfo.description}</p>
              </div>
              <figure className="product-info-figure">
                <img src={productInfo.image} alt={productInfo.name} />
              </figure>
            </div>
          </div>
        </div>
      )}
    </>
  );
}

export default ProductInfoModal;
