import React from "react";

type Props = {
  title: string;
  action: any;
  disabled?: boolean;
  width?: string;
};

function SecondaryButton({ title, action, disabled, width }: Props) {
  return (
    <button
      onClick={action}
      className={
        "secondary-button " + (disabled ? "secondary-button-disabled" : "")
      }
      disabled={disabled}
      style={{ width: width ? width : "100%" }}
    >
      {title}
    </button>
  );
}

export default SecondaryButton;
