import React from "react";
import { CoverPanelsColor } from "../../@types/aquarium";
import { AllTextObject } from "../../@types/language";

type Props = {
  allText: AllTextObject;
  checked: CoverPanelsColor;
  setValue: React.Dispatch<React.SetStateAction<CoverPanelsColor>>;
};

function CoverPanelsColorMenu({ allText, checked, setValue }: Props) {
  return (
    <div className="window-paint-menu-container">
      <div
        className={
          "paint-option-container " +
          (checked === CoverPanelsColor.black ? "paint-option-active" : "")
        }
        onClick={() => setValue(CoverPanelsColor.black)}
      >
        <input
          type="radio"
          name="coverpanels-color-option"
          className="window-paint-option"
          id="coverpanels-color-option-black"
        />
        <label htmlFor="coverpanels-color-option-black">{allText.tid_gen_14}</label>
        <div className="coverpanels-color-preview-black"></div>
      </div>
      <div
        className={
          "paint-option-container " +
          (checked === CoverPanelsColor.white ? "paint-option-active" : "")
        }
        onClick={() => setValue(CoverPanelsColor.white)}
      >
        <input
          type="radio"
          name="coverpanels-color-option"
          className="window-paint-option"
          id="coverpanels-color-option-white"
        />
        <label htmlFor="coverpanels-color-option-white">{allText.tid_gen_20}</label>
        <div className="coverpanels-color-preview-white"></div>
      </div>
      <div
        className={
          "paint-option-container " +
          (checked === CoverPanelsColor.gray ? "paint-option-active" : "")
        }
        onClick={() => setValue(CoverPanelsColor.gray)}
      >
        <input
          type="radio"
          name="coverpanels-color-option"
          className="window-paint-option"
          id="coverpanels-color-option-gray"
        />
        <label htmlFor="coverpanels-color-option-gray">{allText.tid_gen_21}</label>
        <div className="coverpanels-color-preview-gray"></div>
      </div>
      <div
        className={
          "paint-option-container " +
          (checked === CoverPanelsColor.antraciet ? "paint-option-active" : "")
        }
        onClick={() => setValue(CoverPanelsColor.antraciet)}
      >
        <input
          type="radio"
          name="coverpanels-color-option"
          className="window-paint-option"
          id="coverpanels-color-option-antraciet"
        />
        <label htmlFor="coverpanels-color-option-antraciet">{allText.tid_gen_22}</label>
        <div className="coverpanels-color-preview-antraciet"></div>
      </div>
    </div>
  );
}

export default CoverPanelsColorMenu;
