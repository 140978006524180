import React, { useState } from "react";
import { ProductData } from "../../@types/product";
import { Checks, Info, Minus, Plus, Trash } from "@phosphor-icons/react";
import { useDispatch } from "react-redux";
import { setOpenProductInfoModal } from "../../redux/configuratorSlice";
import { AllTextObject } from "../../@types/language";

type Props = {
  allText: AllTextObject;
  item: ProductData;
  currentSelectedItems: ProductData[];
  setCurrentSelectedItems: React.Dispatch<React.SetStateAction<ProductData[]>>;
};

function WebshopItem({
  allText,
  item,
  currentSelectedItems,
  setCurrentSelectedItems,
}: Props) {
  const dispatch = useDispatch();

  const formatPrice = (price: number) => {
    return price.toFixed(2).toString().replace(".", ",");
  };

  const getNumberOfItems = () => {
    return currentSelectedItems.filter((currItem) => currItem.id === item.id)
      .length;
  };

  const handleAddItem = () => {
    const newItemList = [...currentSelectedItems, item];
    setCurrentSelectedItems(newItemList);
  };
  const handleRemoveItem = () => {
    const index = currentSelectedItems.indexOf(item);
    const newItemList = currentSelectedItems.filter(
      (item, idx) => idx !== index
    );
    setCurrentSelectedItems(newItemList);
  };
  const handleRemoveAll = () => {
    const newItemList = currentSelectedItems.filter(
      (currItem) => currItem.id !== item.id
    );
    setCurrentSelectedItems(newItemList);
  };
  const handleOpenProductInfo = () => {
    dispatch(setOpenProductInfoModal(item));
  };

  return (
    <div className="webshop-item-container">
      <figure className="webshop-item-figure">
        <img src={item.image} alt="" className="webshop-item-image" />
        {item.isRecommanded && (
          <div className="webshop-item-tag">
            <Checks size={16} />
            <div>{allText.tid_st6_05}</div>
          </div>
        )}
      </figure>
      <div className="webshop-item-content-container">
        <h3 className="webshop-item-title">{item.name}</h3>
        <div className="webshop-item-extra-content">
          <div>
            <div className="webshop-item-price">
              {"€" + formatPrice(item.price)}
            </div>
            <div className="webshop-item-price-excl-btw">
              {"€" + formatPrice(item.price / 1.21) + " " + allText.tid_st6_44}
            </div>
          </div>
          <div
            className="webshop-item-info"
            onClick={() => handleOpenProductInfo()}
          >
            <Info />
            <div>{allText.tid_st6_06}</div>
          </div>
        </div>
      </div>
      {getNumberOfItems() === 0 ? (
        <div
          className="webshop-item-add-button"
          onClick={() => handleAddItem()}
        >
          <Plus weight="bold" size={24} />
          <div>{allText.tid_st6_07}</div>
        </div>
      ) : (
        <div className="webshop-item-adding-container">
          <div className="webshop-item-adding-content-container">
            <div className="webshop-item-adding-button-container">
              <div
                className="webshop-item-adding-button"
                onClick={() => handleRemoveItem()}
              >
                <Minus weight="bold" />
              </div>
              <div>{getNumberOfItems()}</div>
              <div
                className="webshop-item-adding-button"
                onClick={() => handleAddItem()}
              >
                <Plus weight="bold" />
              </div>
            </div>
          </div>
          <div
            className="webshop-item-button-trash"
            onClick={() => handleRemoveAll()}
          >
            <Trash size={24} />
          </div>
        </div>
      )}
    </div>
  );
}

export default WebshopItem;
