import {
  PricePerUnitOptions,
  ProductCategory,
  ProductData,
} from "../@types/product";
import { OfferteData } from "../@types/user";
import dummyFilterImage from "../images/filteroptions/biologist.png";
import boosterPumpDummy from "../images/boosterPumpDummy.jpg";
import boo_fresh_001 from "../images/demoProducts/boo-fresh-001.jpg";
import boo_fresh_002 from "../images/demoProducts/boo-fresh-002.png";
import boo_fresh_003 from "../images/demoProducts/boo-fresh-003.png";
import led_fresh_001 from "../images/demoProducts/led-fresh-001.png";
import hea_fresh_001 from "../images/demoProducts/hea-fresh-001.jpg";
import hea_fresh_002 from "../images/demoProducts/hea-fresh-002.jpg";
import hea_fresh_003 from "../images/demoProducts/hea-fresh-003.png";
import fil_fresh_001 from "../images/demoProducts/fil-fresh-001.jpg";
import fil_fresh_002 from "../images/demoProducts/fil-fresh-002.jpg";
import co2_fresh_001 from "../images/demoProducts/co2-fresh-001.png";
import co2_fresh_002 from "../images/demoProducts/co2-fresh-002.jpg";
import int_fresh_001 from "../images/demoProducts/int-fresh-001.jpg";
import int_fresh_002 from "../images/demoProducts/int-fresh-002.jpg";
import int_fresh_003 from "../images/demoProducts/int-fresh-003.jpg";
import led_salty_001 from "../images/demoProducts/led-salty-001.jpg";
import cir_salty_001 from "../images/demoProducts/cir-salty-001.png";
import cir_salty_003 from "../images/demoProducts/cir-salty-003.png";
import pro_salty_001 from "../images/demoProducts/pro-salty-001.jpg";
import pro_salty_003 from "../images/demoProducts/pro-salty-003.jpg";
import ars_salty_001 from "../images/demoProducts/ars-salty-001.jpg";
import int_salty_001 from "../images/demoProducts/int-salty-001.jpg";
import int_salty_002 from "../images/demoProducts/int-salty-002.jpg";

const testDate: string = new Date().toLocaleString();

export const testRowData: OfferteData[] = [
  {
    offerteNumber: 25487,
    firstname: "Dries",
    lastname: "Vanmierlo",
    email: "dries.vanmierlo@telenet.be",
    price: 1652.65,
    isPaid: true,
    date: testDate,
    reminderSend: testDate,
    phonenumber: "0470 63 75 28",
    postalcode: "3950",
    city: "Bocholt",
    street: "Tuinstraat",
    houseNumber: "2",
    additionalAdressInfo: "",
    country: "BE",
    advancePaymentPaid: false,
    offerteLink: "",
    configuratorLink: "",
  },
  {
    offerteNumber: 9999,
    firstname: "Daan",
    lastname: "Ezendam",
    email: "daezendam@gmail.com",
    price: 890.33,
    isPaid: false,
    date: testDate,
    reminderSend: null,
    phonenumber: "0470 63 75 28",
    postalcode: "3950",
    city: "Bocholt",
    street: "Tuinstraat",
    houseNumber: "2",
    additionalAdressInfo: "",
    country: "BE",
    advancePaymentPaid: true,
    offerteLink: "",
    configuratorLink: "",
  },
];

export const testProductData: ProductData[] = [
  {
    id: "boo-fresh-001",
    name: "Syncra circulatiepomp 2.0 2150 L/H",
    description:
      "Syncra Silent: een pomplijn voor de nieuwe generatie. Deze pompen zijn gemaakt om hoge prestaties te koppelen aan extreme stilte en een laag energie verbruik. Dankzij de innovatieve technieken kunnen deze pompen gebruikt worden voor verschillende toepassingen. Syncra Silent pompen zijn te gebruiken voor zoetwater en zeewater aquariums, terrariums, schildpaddenbakken, eiwitafschuimers, water-koelers, buiten fonteinen en iedere andere toepassing waar een hoge prestatie met extreme stilte is gewenst. De volledige Syncra Silent lijn is zowel droog als nat opstel baar, de thermische beveiliging zorgt ervoor dat oververhitting voorkomen wordt zodra de pomp droog draait. De pompen worden standaard geleverd met slangtules voor een goede verbinding. Syncra Silent : Design, betrouwbaarheid, 100% stil, gecombineerd met de superieure technologie van Sicce.",
    category: [
      ProductCategory.boosterPumpsFreshwater,
      ProductCategory.boosterPumpsSaltwater,
    ],
    price: 66.91,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: boo_fresh_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "boo-fresh-002",
    name: "Aqualife - Sine Wave 4000 (SWP-4000)",
    description:
      "Deze ontwikkelde pompen zijn zowel geschikt voor zoet– en zeewateraquaria alsook voor vijver toepassingen. In deze pomp is gebruik gemaakt van hoogwaardig materiaal, zowel de pomp en de meegeleverde besturingscomputer. Elke pomp is voorzien van een serie nummer en heeft een fabrieksgarantie van 2 ja Nieuwigheden De nieuwe generatie pompen met Sine Wave technologie. Keramische rotor inclusief beveiliging tegen het droog draaien Speciaal ontworpen rotor voor een krachtige flow en hoge opvoerhoogte Pomp is voorzien van voeding modus, instelbaar debiet,... Energiezuinig en functioneert dankzij de SW-technologie enorm stil Ideaal voor: vijversystemen, zee en zoetwater aquaria Optioneel ook droog opstelbaar Pompen met een naam en serie nr. , fabrieksgarantie: 2 jaar op de pomp Incl. adapter van hoge kwaliteit Meegeleverde slangtules van 20 tot 40 mm (model 4000: 16 , 20 & 25 mm)",
    category: [
      ProductCategory.boosterPumpsFreshwater,
      ProductCategory.boosterPumpsSaltwater,
    ],
    price: 119.9,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: boo_fresh_002,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "boo-fresh-003",
    name: "Aqualife - Sine Wave 6500 (SWP-6500)",
    description:
      "Deze ontwikkelde pompen zijn zowel geschikt voor zoet– en zeewateraquaria alsook voor vijver toepassingen. In deze pomp is gebruik gemaakt van hoogwaardig materiaal, zowel de pomp en de meegeleverde besturingscomputer. Elke pomp is voorzien van een serie nummer en heeft een fabrieksgarantie van 2 jaar. Nieuwigheden De nieuwe generatie pompen met Sine Wave technologie. Keramische rotor inclusief beveiliging tegen het droog draaien Speciaal ontworpen rotor voor een krachtige flow en hoge opvoerhoogte Pomp is voorzien van voeding modus, instelbaar debiet,... Energiezuinig en functioneert dankzij de SW-technologie enorm stil Ideaal voor: vijversystemen, zee en zoetwater aquaria Optioneel ook droog opstelbaar Pompen met een naam en serie nr. , fabrieksgarantie: 2 jaar op de pomp Incl. adapter van hoge kwaliteit Meegeleverde slangtules van 20 tot 40 mm (model 4000: 16 , 20 & 25 mm)",
    category: [
      ProductCategory.boosterPumpsFreshwater,
      ProductCategory.boosterPumpsSaltwater,
    ],
    price: 152.89,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: boo_fresh_003,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "led-fresh-001",
    name: "Zetlight Lancia LED - TROPICAL 895 mm (WIFI)",
    description:
      "De Lancia is de nieuwste generatie LED verlichting met een elegant en stijlvol ontwerp. Door de slanke afmetingen en omdat deze verlichting waterbestendig is, is de verlichting eenvoudig vlak boven het wateroppervlak te installeren, alsof deze in het aquarium is geïntegreerd. De Lancia is via Wifi met behulp van de gratis Horizon-app op uw smartphone te bedienen. Je kunt de tijd van de zonsopkomst en zonsondergang instellen, de intensiteit aanpassen en de kleur van het licht in een breed spectrum regelen. Dit alles maakt het mogelijk om de beste lichtomstandigheden voor de vissen en planten in te stellen.",
    category: [ProductCategory.LEDFreshwater],
    price: 142.72,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: led_fresh_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "led-fresh-002",
    name: "Zetlight Lancia LED - TROPICAL 1200 mm (WIFI)",
    description:
      "De Lancia is de nieuwste generatie LED verlichting met een elegant en stijlvol ontwerp. Door de slanke afmetingen en omdat deze verlichting waterbestendig is, is de verlichting eenvoudig vlak boven het wateroppervlak te installeren, alsof deze in het aquarium is geïntegreerd. De Lancia is via Wifi met behulp van de gratis Horizon-app op uw smartphone te bedienen. Je kunt de tijd van de zonsopkomst en zonsondergang instellen, de intensiteit aanpassen en de kleur van het licht in een breed spectrum regelen. Dit alles maakt het mogelijk om de beste lichtomstandigheden voor de vissen en planten in te stellen.",
    category: [ProductCategory.LEDFreshwater],
    price: 174.18,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: led_fresh_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "led-fresh-003",
    name: "Zetlight Lancia LED - TROPICAL 1500 mm (WIFI)",
    description:
      "De Lancia is de nieuwste generatie LED verlichting met een elegant en stijlvol ontwerp. Door de slanke afmetingen en omdat deze verlichting waterbestendig is, is de verlichting eenvoudig vlak boven het wateroppervlak te installeren, alsof deze in het aquarium is geïntegreerd. De Lancia is via Wifi met behulp van de gratis Horizon-app op uw smartphone te bedienen. Je kunt de tijd van de zonsopkomst en zonsondergang instellen, de intensiteit aanpassen en de kleur van het licht in een breed spectrum regelen. Dit alles maakt het mogelijk om de beste lichtomstandigheden voor de vissen en planten in te stellen.",
    category: [ProductCategory.LEDFreshwater],
    price: 210.48,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: led_fresh_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "hea-fresh-001",
    name: "Sicce scuba 200W (verwarmer) incl. thermostaat",
    description:
      "Verwarming is een essentieel onderdeel van een tropisch aquarium, vooral omdat de schommelingen tussen dag- en nachttemperatuur in ons gematigde klimaat veel te groot zijn en we geen constante watertemperatuur tussen 23°C en 27°C kunnen garanderen. Deze Scuba verwarmer van Sicce maakt verwarmen kinderspel. De thermostaat in het verwarmingselement kan ingesteld worden van 18°C tot 32°C. Het verwarmingselement is waterdicht en kan volledig ondergedompeld worden.",
    category: [
      ProductCategory.heatingFreshwater,
      ProductCategory.heatingSaltwater,
    ],
    price: 20.5,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: hea_fresh_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "hea-fresh-002",
    name: "SHEGO - Titanium verwarmer 300W",
    description:
      "Titanium verwarmingselementen zijn het veilige alternatief voor glazen verwarmingselementen. Het titanium is zeewaterbestendig en onbreekbaar. Uiteraard zijn de verwarmingselementen voorzien voor een oververhittingsbeveiliging en een droogloop-beveiliging. De elementen moeten gebruikt worden in combinatie met een thermostaat. Wij adviseren hiervoor de Aqualife Thermo controller Tot 800 liter aquaria - 24,5 graden (kamer temperatuur 20 graden)",
    category: [
      ProductCategory.heatingFreshwater,
      ProductCategory.heatingSaltwater,
    ],
    price: 47,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: hea_fresh_002,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "hea-fresh-003",
    name: "Thermo Controller (cool & heat) - nauwkeurig temperatuur regelen",
    description:
      "Merk: AQUALIFESpeciaal ontworpen controller voor het nauwkeurig meten van temperatuur. Ideaal voor zoet- en zeeaquaria Kenmerken: LCD-scherm Automatische uitschakeling met achtergrondverlichting zonder bediening gedurende 10 minuten. Kan temperatuur verschil van koeling en verwarming afzonderlijk instellen Converteert automatisch tussen koeling en verwarmingParameters: Meetbereik temperatuur: -40 ~ 100 ° C Bereik van temperatuurregeling (INSTELLING): 16 ~ 40 ° C Resolutie: 0,1 ° C; Nauwkeurigheid: ± 1 ° C Vermogen: 220VAC ± 15%, 50 / 60Hz Ingang: 1 NTC-sensor (waterdicht); Sensordraad: ca. 1m Uitgang: twee relaisuitgangen (relaiscapaciteit: 16ARELAY) max. 1600W Stekker en stekkerdoos: AU-standaard (optioneel: EU, VS, VK); Op de stekkerdoos betekent 'verwarming' I en koeling II. Verbruik: ≤3 w Omgevingsvereisten: temperatuur: -10 ~ 60 ° C; vochtigheid: 20 ~ 85% (geen condensaat) Netto gewicht: ca. 600 g; Gewicht pakket: ca. 700 gIndicatielampje Status Beschrijving:AAN: Koeling is in werkende staat; FLASH: Compressorvertraging; UIT: werkt niet.AAN: verwarming is in werkende staat; UIT: werkt niet.Schakel in de instelmodus naar het menu van het koeldifferentieel, flits; overschakelen naar menu van verwarming differentia, knipperen.",
    category: [
      ProductCategory.heatingFreshwater,
      ProductCategory.heatingSaltwater,
    ],
    price: 65,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: hea_fresh_003,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "fil-fresh-001",
    name: "Oase BioMaster 600 THERMO (buitenfilter)",
    description:
      "Nieuwste model met ingebouwde instelbare verwarmer en voorfilter. De beste keuze extern filter Veilig: Afsluitmechanisme en veiligheidsvergrendeling voorkomen ongewenst waterverlies, bijvoorbeeld bij het verwijderen van de voorfiltermodule EasyClean-voorfiltermodule: Geen lastig uitbouwen van het filter meer - de voorfiltermodule wordt eenvoudig uitgenomen, gespoeld en weer teruggeplaatst Praktisch: Ontluchtingsknop voor effectieve wateraanzuiging - voor een snelle en eenvoudige start bij de installatie en na de reiniging Optimale filtratie: Fijne 45 ppi voorfiltermousse voor optimale mechanische filtering van het grove vuil en een langere levensduur van de biologische media Op aanvraag: BioMaster Thermo inclusief geïntegreerde HeatUp regelbare verwarming – ook achteraf uitbreidbaar Goed geregeld: Doorstroomregeling op het filter en ook via adapterset Groot filtervolume: De combinatie van mechanische en zeer effectieve biologische filtering door Hel-X zorgt voor gezond en helder aquariumwater Zuinig: Rustig lopende en energiezuinige pomp zonder storende geluiden Groot filtervolume: De optimaal op elkaar afgestemde combinatie van zeer effectieve biologische filtering door Hel-X en mechanisch-biologische reiniging door filtermousses zorgt voor gezond en helder aquariumwater. Complete set voor een snelle start: Inclusief filtermedia, slangen, adapter, doorstromingsregelaar, aanzuig- en uitstroombuis en waterdiffusor.",
    category: [ProductCategory.filtersFreshwater],
    price: 314.95,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: fil_fresh_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "fil-fresh-002",
    name: "Oase BioMaster 850 THERMO (buitenfilter)",
    description:
      "Nieuwste model met ingebouwde instelbare verwarmer en voorfilter. De beste keuze extern filter Veilig: Afsluitmechanisme en veiligheidsvergrendeling voorkomen ongewenst waterverlies, bijvoorbeeld bij het verwijderen van de voorfiltermodule EasyClean-voorfiltermodule: Geen lastig uitbouwen van het filter meer - de voorfiltermodule wordt eenvoudig uitgenomen, gespoeld en weer teruggeplaatst Praktisch: Ontluchtingsknop voor effectieve wateraanzuiging - voor een snelle en eenvoudige start bij de installatie en na de reiniging Optimale filtratie: Fijne 45 ppi voorfiltermousse voor optimale mechanische filtering van het grove vuil en een langere levensduur van de biologische media Op aanvraag: BioMaster Thermo inclusief geïntegreerde HeatUp regelbare verwarming – ook achteraf uitbreidbaar Goed geregeld: Doorstroomregeling op het filter en ook via adapterset Groot filtervolume: De combinatie van mechanische en zeer effectieve biologische filtering door Hel-X zorgt voor gezond en helder aquariumwater Zuinig: Rustig lopende en energiezuinige pomp zonder storende geluiden Groot filtervolume: De optimaal op elkaar afgestemde combinatie van zeer effectieve biologische filtering door Hel-X en mechanisch-biologische reiniging door filtermousses zorgt voor gezond en helder aquariumwater. Complete set voor een snelle start: Inclusief filtermedia, slangen, adapter, doorstromingsregelaar, aanzuig- en uitstroombuis en waterdiffusor.",
    category: [ProductCategory.filtersFreshwater],
    price: 374.75,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: fil_fresh_002,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "co2-fresh-001",
    name: "Aqualife BIO CO2 - 5 LITER (inclusief drukregelaar, bellenteller en automatische tijd sturing)",
    description:
      "Aqualife BIO CO2 - 5 LITER (inclusief drukregelaar, bellenteller en automatische tijd sturing) Zéér handig en makkelijk in gebruik om zelf koolzuur te maken. Ideaal voor goede plantengroei. Afmetingen compleet systeem met drukregelaar: 14x14x57 cm Compleet systeem inclusief: - RVS CO2 fles 5 liter - Drukregelaar met fijn afregelventiel (ook ontluchtingsschroef om druk af te laten) - Professioneel magneetventiel 12V - Bellenteller - Automatische of manuele tijdsturing wanneer het magneetventiel aan en uit moet gaan - Grondstoffen citroenzuur en bicarbonaat niet meegeleverd",
    category: [ProductCategory.co2SystemFreshwater],
    price: 269,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: co2_fresh_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "co2-fresh-002",
    name: "Aqualife CO2 Regulator met magneetventiel 12V DC",
    description:
      "- Makkelijk en handig complete CO2 regulator voor standaard gasfles W21,8 aansluiting - Zéér degelijke uitvoering met hoogwaardige materialen - Dubbele drukmeter (zodat u kan zien de inhoud in de fles en de druk richting de bubbel teller) - Inclusief magneetventiel 12V DC inclusief adapter - Heel fijn af te regelen naaldventiel zodat u de bellen kan tellen in de 'bubble counter' - Zeer praktische bubbel teller met terugslag ventiel, de bubbel teller is ook draaibaar. Afmetingen +/- 15x15x5 cm",
    category: [ProductCategory.co2SystemFreshwater],
    price: 89,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: co2_fresh_002,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "int-fresh-001",
    name: "Landscape steen (afmetingen 10-40 cm) per KG",
    description:
      "Landscape steen (afmetingen 10-40 cm) per KG Bij het afrekenen kan u laten weten welke grootte u precies zoekt, wij proberen deze dan selecteren zodat ze passend zijn voor uw aquarium. Hoeveel heb ik nodig voor mijn aquarium? Voor u een idee te geven heeft u voor een aquascape aquarium met afmetingen 100x50x50 cm (250 liter) gecombineerd met spiderwood +/- 20-25 KG hardscape materiaal nodig. Als u de nadruk legt op planten met drijfhout/ mangrove in het aquarium van 100x50x50 cm (250 liter) heeft u ongeveer 10-15 KG hardscape materiaal nodig. ",
    category: [ProductCategory.interiorFreshwater],
    price: 3.3,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perKilo,
    isRecommanded: false,
    recommandedText: "",
    image: int_fresh_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "int-fresh-002",
    name: "Spiderwood 41-50 cm per stuk",
    description:
      "Spiderwood of Redmoor Wood is hout voor het aquarium met zeer fijne vertakkingen. Mossoorten, Java-varens en Anubias kunnen aan de kronkelige takken worden bevestigd voor een extra decoratieve waarde. De vele gekronkelde takken bieden tevens mooie schuilplaatsen voor uw vissen.",
    category: [ProductCategory.interiorFreshwater],
    price: 21.95,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: int_fresh_002,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "int-fresh-003",
    name: "Rivierzand hoge kwaliteit 0,8 - 1,2 mm - ZAK 25 KG",
    description: "Kleur: grijs/bruin Spoel het zand met water alvorens gebruik",
    category: [ProductCategory.interiorFreshwater],
    price: 17.5,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: int_fresh_003,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "led-salty-001",
    name: "Zetlight Lancia LED - MARINE 895 mm (WIFI)",
    description:
      "De Lancia is de nieuwste generatie LED verlichting met een elegant en stijlvol ontwerp. Door de slanke afmetingen en omdat deze verlichting waterbestendig is, is de verlichting eenvoudig vlak boven het wateroppervlak te installeren, alsof deze in het aquarium is geïntegreerd. De Lancia is via Wifi met behulp van de gratis Horizon-app op uw smartphone te bedienen. Je kunt de tijd van de zonsopkomst en zonsondergang instellen, de intensiteit aanpassen en de kleur van het licht in een breed spectrum regelen. Dit alles maakt het mogelijk om de beste lichtomstandigheden voor de vissen en koralen in te stellen.",
    category: [ProductCategory.LEDSaltwater],
    price: 142.72,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: led_salty_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "led-salty-002",
    name: "Zetlight Lancia LED - MARINE 1200 mm (WIFI)",
    description:
      "De Lancia is de nieuwste generatie LED verlichting met een elegant en stijlvol ontwerp. Door de slanke afmetingen en omdat deze verlichting waterbestendig is, is de verlichting eenvoudig vlak boven het wateroppervlak te installeren, alsof deze in het aquarium is geïntegreerd. De Lancia is via Wifi met behulp van de gratis Horizon-app op uw smartphone te bedienen. Je kunt de tijd van de zonsopkomst en zonsondergang instellen, de intensiteit aanpassen en de kleur van het licht in een breed spectrum regelen. Dit alles maakt het mogelijk om de beste lichtomstandigheden voor de vissen en koralen in te stellen.",
    category: [ProductCategory.LEDSaltwater],
    price: 174.18,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: led_salty_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "led-salty-003",
    name: "Zetlight Lancia LED - MARINE 1500 mm (WIFI)",
    description:
      "De Lancia is de nieuwste generatie LED verlichting met een elegant en stijlvol ontwerp. Door de slanke afmetingen en omdat deze verlichting waterbestendig is, is de verlichting eenvoudig vlak boven het wateroppervlak te installeren, alsof deze in het aquarium is geïntegreerd. De Lancia is via Wifi met behulp van de gratis Horizon-app op uw smartphone te bedienen. Je kunt de tijd van de zonsopkomst en zonsondergang instellen, de intensiteit aanpassen en de kleur van het licht in een breed spectrum regelen. Dit alles maakt het mogelijk om de beste lichtomstandigheden voor de vissen en koralen in te stellen.",
    category: [ProductCategory.LEDSaltwater],
    price: 210.48,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: led_salty_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "cir-salty-001",
    name: "Aqualife Reef Streamer 8000 L/H",
    description:
      "Eigenschappen: De Aqualife Reef Streamers zijn de ultieme pompen in het zeewater aquarium. De pompen beschikken over de nieuwste technologie, zijn enorm krachtig en energiezuinig.De pompen zijn ook voorzien van een zelf onderhoudende functie. Verschillende mogelijke golf simulaties zijn met de meegeleverde controller instelbaar. Elke pomp is voorzien van een serie nummer en heeft een fabrieksgarantie van 2 jaar. Glasdikte max. 17 mm. Nieuwigheden: Zelf onderhoudende functie! Pomp is voorzien van verschillende opties: golf simulatie, voeding pauze,… De pomp natuurlijk ook regelbaar in debiet Enorm energiezuinig! Slave control (indien je beschikt over meerdere pompen kan je een master & slave instellen)",
    category: [ProductCategory.circulationPumpsSaltwater],
    price: 99,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: cir_salty_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "cir-salty-002",
    name: "Aqualife Reef Streamer 13 000 L/H",
    description:
      "Eigenschappen: De Aqualife Reef Streamers zijn de ultieme pompen in het zeewater aquarium. De pompen beschikken over de nieuwste technologie, zijn enorm krachtig en energiezuinig.De pompen zijn ook voorzien van een zelf onderhoudende functie. Verschillende mogelijke golf simulaties zijn met de meegeleverde controller instelbaar. Elke pomp is voorzien van een serie nummer en heeft een fabrieksgarantie van 2 jaar. Glasdikte max. 17 mm. Nieuwigheden: Zelf onderhoudende functie! Pomp is voorzien van verschillende opties: golf simulatie, voeding pauze,… De pomp natuurlijk ook regelbaar in debiet Enorm energiezuinig! Slave control (indien je beschikt over meerdere pompen kan je een master & slave instellen)",
    category: [ProductCategory.circulationPumpsSaltwater],
    price: 119,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: cir_salty_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "cir-salty-003",
    name: "Aqualife Ocean Simulator 18 000 L/H",
    description:
      "Eigenschappen: De Aqualife Ocean Simulator zijn de ultieme STILLE pompen in het zeewater aquarium. De pompen beschikken over de nieuwste Sine Wave technologie, zijn enorm krachtig en energiezuinig. Verschillende mogelijke golf simulaties zijn met de meegeleverde touch screen controller instelbaar. Elke pomp is voorzien van een serie nummer en heeft een fabrieksgarantie van 2 jaar. Glasdikte max. 19 mm. Nieuwigheden Programmeerbare nachtmodus! Uniek. Master & slave besturing (handig bij meerdere pompen) LCD touch screen display Pomp is voorzien van verschillende opties: golf simulatie, voeding pauze,… De pomp natuurlijk ook regelbaar in debiet Enorm energiezuinig! ",
    category: [ProductCategory.circulationPumpsSaltwater],
    price: 169,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: cir_salty_003,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "pro-salty-001",
    name: "Deltec eiwitafschuimer 600i pomp 24V met controller",
    description:
      "De Deltec Skimmer 600i heeft een nieuw ontwikkelde noodoverloop. Dit voorkomt het “overkoken” van de afschuimer wanneer het waterniveau in de afschuimer plotseling stijgt. Dit kan bijvoorbeeld gebeuren omdat de opvoerpomp stopt en het waterniveau in de sump stijgt.",
    category: [ProductCategory.proteinSkimmersSaltwater],
    price: 428.4,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: pro_salty_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "pro-salty-002",
    name: "Deltec eiwitafschuimer 1000i pomp 24V met controller",
    description:
      "De Deltec Skimmer 600i heeft een nieuw ontwikkelde noodoverloop. Dit voorkomt het “overkoken” van de afschuimer wanneer het waterniveau in de afschuimer plotseling stijgt. Dit kan bijvoorbeeld gebeuren omdat de opvoerpomp stopt en het waterniveau in de sump stijgt.",
    category: [ProductCategory.proteinSkimmersSaltwater],
    price: 487.63,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: pro_salty_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "pro-salty-003",
    name: "Blue Marine Sump Skimmer 2000",
    description:
      "De Blue Marine Skim 600 en 2000 zijn hoogwaardige Sump Skimmers met een hoge luchtopname. De luchtinlaat is het belangrijkste kenmerk voor een eiwitafschuimer, deze skimmers hebben een luchtinname van respectievelijk 450 en 1200 liter per uur! De contacttijd tussen lucht en water is het op een na belangrijkste punt, het venturisysteem en de Needle Wheel rotor zorgen samen met de bubbeldiffusorplaat voor een geoptimaliseerde contacttijd tussen lucht en water in het huis van de skimmer. Deze Blue Marine-skimmers van de 3e generatie zijn uitgerust met een nieuwe DC pomp met de nieuwste inverter-technologie die energiebesparing, geluidreductie en een nauwkeurig instelbare waterstroom mogelijk maakt. De skimmers beschikken over een grote schuim opvangbeker met handige aftapmogelijkheid kompleet met slang en kraan.",
    category: [ProductCategory.proteinSkimmersSaltwater],
    price: 399,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: pro_salty_003,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "ars-salty-001",
    name: "Compleet bijvulsysteem voor osmose water 16 Liter (bijvulsysteem en jerrycan 16 liter)",
    description:
      "De Jerrycan Standaard van Aqualife is dankzij het gebruikte materiaal geschikt voor osmose of zeewater. In deze jerrycan van 37,5 x 32 x 17 cm (h x b x d) is ruimte voor 16 liter vloeistof.",
    category: [ProductCategory.automaticRefillSystemsSaltwater],
    price: 92.5,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: ars_salty_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "int-salty-001",
    name: "Indonesia - Base Rock (doos 18-22 KG)",
    description:
      "Verschillende stukken Dry base Rock om een prachtige aquascape te maken voor je zee aquarium Er zitten geen ongewenste fosfaten of ongewenste plagen op deze droge stenen.um Dry base Rock fungeert als zowel aquascape-materiaal als biofiltermedium, het is veilig om te zeggen dat live rock behoorlijk belangrijk is. We willen dat het er zo natuurlijk mogelijk uitziet en zich “gedraagt”. Echt, “live” rock kan alleen maar zo goed zijn als de Dry base rock waarop je het bouwt. Voor een zeeaquarium is de beste basis, een steen waar koralen in het wild op groeien: natuurlijke basis rots.um Natuurlijke base rock bestaat voornamelijk uit calciumcarbonaat skeletten die vorige bewoners van steenachtig koraal achterlieten. Koraalriffen bestaan eigenlijk vrijwel volledig uit base rock, met een relatief dunne laag levende koralen over het oppervlak. Vanwege de vele kleine holletjess en minuscule poriën kan vers afgezet materiaal veel ecologisch nuttige organismen herbergen, variërend van nitrificerende en denitrificerende bacteriën tot micro schaaldieren zoals copepoden.um Een doos bevat 18-20kg rotsen van 12-30cm. Kan gemakkelijk worden gelijmd met StoneFix",
    category: [ProductCategory.interiorSaltwater],
    price: 85,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: int_salty_001,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
  {
    id: "int-salty-002",
    name: "Calcean Oolitic Aragonite 9 kg (1-2 mm)",
    description:
      "Bahamiaans zand is ruw, natuurlijk hernieuwbaar oolitisch aragonietzand dat tot 98% puur calciumcarbonaat bevat. De korrels van coarse zijn grof en ongeveer 1-2 mm. Het is smaakloos, geurloos, stofvrij en duurzaam.",
    category: [ProductCategory.interiorSaltwater],
    price: 27.85,
    discountReseller1: 35,
    discountReseller2: 40,
    pricePerUnit: PricePerUnitOptions.perPiece,
    isRecommanded: false,
    recommandedText: "",
    image: int_salty_002,
    dimensionsDependant: {
      minHeight: null,
      maxHeight: null,
      minWidth: null,
      maxWidth: null,
      minLenght: null,
      maxLenght: null,
    },
    volumeDependant: {
      minVolume: null,
      maxVolume: null,
    },
  },
];
