import { useEffect, useState } from "react";
import ConfiguratorHeader from "./configurator/ConfiguratorHeader";
import { StepInfo, StepStatus, Steps } from "../../@types/configurator";
import ConfiguratorStepMenu from "./configurator/ConfiguratorStepMenu";
import GoToOverviewButton from "../molecules/GoToOverviewButton";
import ConfiguratorMenuFooter from "../molecules/ConfiguratorMenuFooter";
import DimensionsStep from "./steps/DimensionsStep";
import AquariumTypeStep from "./steps/AquariumTypeStep";
import WindowsStep from "./steps/WindowsStep";
import FilterStep from "./steps/FilterStep";
import UserDisclaimer from "./configurator/UserDisclaimer";
import FurnitureStep from "./steps/FurnitureStep";
import TechnicsStep from "./steps/TechnicsStep";
import { useDispatch, useSelector } from "react-redux";
import { RootState, checkNewAquariumDrawing } from "../../redux/store";
import { updateStepInfoList } from "../../utils/stepConfigurator";
import { setOpenStep as setOpenStepRedux } from "../../redux/configuratorSlice";
import { useSearchParams } from "react-router-dom";
import { setURLAquarium as setURLAquariumRedux } from "../../utils/aquarium";
import { constructParamsURL } from "../../utils/configurator";
import { AllTextObject } from "../../@types/language";

type Props = { allText: AllTextObject };

function ControlCenter({ allText }: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const configurator = useSelector((state: RootState) => state.configurator);
  const URLAquariumLoaded = useSelector(
    (state: RootState) => state.configurator.URLAquariumLoaded
  );
  const openStepRedux = useSelector(
    (state: RootState) => state.configurator.openStep
  );
  const user = useSelector((state: RootState) => state.user);
  const stepInfoListRedux = useSelector(
    (state: RootState) => state.configurator.stepInfoList
  );
  const stepItemInfoOverview = useSelector(
    (state: RootState) => state.configurator.stepItemInfoOverview
  );
  const dispatch = useDispatch();
  const [openStep, setOpenStep] = useState<Steps | null>(openStepRedux);

  const [searchParams] = useSearchParams();

  const getOverviewButtonDisabled = () => {
    const disableButton = stepInfoListRedux.filter(
      (item) => item.status !== StepStatus.isCompleted
    );
    return disableButton.length !== 0;
  };

  const [overviewButtonDisabled, setOverviewButtonDisabled] = useState<boolean>(
    getOverviewButtonDisabled()
  );

  window.onload = () => {
    if (searchParams.size > 0 && !URLAquariumLoaded) {
      // check here if a step is opened
      const updatedStepInfoList: StepInfo[] = stepInfoListRedux.map((step) => {
        return { ...step, opened: true };
      });
      updateStepInfoList(
        dispatch,
        updatedStepInfoList,
        aquarium,
        stepItemInfoOverview
      );
      setURLAquariumRedux(searchParams, dispatch);
    }
  };

  // Deze useEffect is om de openStep op null te zetten, dit gebeurd wanneer het aquarium gereset wordt zodat een open stap gesloten wordt
  useEffect(() => {
    if (openStepRedux === null) {
      setOpenStep(openStepRedux);
    }
  }, [openStepRedux]);

  // Deze useEffect zorgt ervoor dat de stappen juist gemarkeerd zijn (todo, compleet, fout)
  useEffect(() => {
    if (openStepRedux !== openStep) {
      dispatch(setOpenStepRedux(openStep));
    }

    const updatedStepInfoList: StepInfo[] = stepInfoListRedux.map((step) =>
      step.title === openStep ? { ...step, opened: true } : step
    );
    updateStepInfoList(
      dispatch,
      updatedStepInfoList,
      aquarium,
      stepItemInfoOverview
    );

    constructParamsURL(aquarium);
    checkNewAquariumDrawing(false);
  }, [aquarium, openStep]);

  useEffect(() => {
    setOverviewButtonDisabled(getOverviewButtonDisabled());
  }, [stepInfoListRedux]);

  return (
    <div className="control-center-container">
      <ConfiguratorHeader allText={allText} />
      <div
        id="control-center-body"
        style={{
          overflow:
            openStep === null && user.agreedToConditions ? "auto" : "hidden",
        }}
      >
        <UserDisclaimer allText={allText} />
        <ConfiguratorStepMenu allText={allText} setOpenStep={setOpenStep} />
        <div className="control-center-fill"></div>
        <GoToOverviewButton
          allText={allText}
          disabled={overviewButtonDisabled}
        />
          {openStep === Steps.aquariumType ? (
            <AquariumTypeStep allText={allText} setOpenStep={setOpenStep} />
          ) : (
            ""
          )}
        {openStep === Steps.dimensions ? (
          <DimensionsStep allText={allText} setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
        {openStep === Steps.windowPaint ? (
          <WindowsStep allText={allText} setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
        {openStep === Steps.filter ? (
          <FilterStep allText={allText} setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
        {openStep === Steps.furniture ? (
          <FurnitureStep allText={allText} setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
        {openStep === Steps.technics ? (
          <TechnicsStep allText={allText} setOpenStep={setOpenStep} />
        ) : (
          ""
        )}
      </div>
      <ConfiguratorMenuFooter allText={allText} />
    </div>
  );
}

export default ControlCenter;
