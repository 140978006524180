export type User = {
  email: string | null;
  agreedToConditions: boolean;
  loggedIn: boolean;
  firstname: string | null;
  role: UserRole | null; 
};

export type OfferteData = {
  offerteNumber: number;
  firstname: string;
  lastname: string;
  email: string;
  price: number;
  isPaid: boolean;
  date: string;
  reminderSend: string | null;
  phonenumber: string;
  postalcode: string;
  city: string;
  street: string;
  houseNumber: string;
  additionalAdressInfo: string;
  country: string;
  advancePaymentPaid: boolean;
  offerteLink: string;
  configuratorLink: string;
};

export type SuperUser = {
  firstname: string;
  lastname: string;
  company: string;
  email: string;
  password: string;
  role: UserRole;
};

export enum UserRole {
  owner = "owner",
  resellerOne = "reseller1",
  resellerTwo = "reseller2",
}

export const UserRoleOptions = [
  UserRole.owner,
  UserRole.resellerOne,
  UserRole.resellerTwo,
];
