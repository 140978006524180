import React from "react";
import { SmallText } from "../atoms/StyledComponents";
import { SelectOption } from "../../@types/configurator";
type Props = {
  title: string;
  value: any;
  setValue: React.Dispatch<React.SetStateAction<any>>;
  options: SelectOption[];
  column?: boolean;
  width?: string;
  onBlur?: any;
  showError?: boolean;
  errorMessage?: string;
  color?: string;
};

function RadioSelectionMenu({
  title,
  value,
  options,
  setValue,
  column,
  width,
  onBlur,
  showError,
  errorMessage,
  color,
}: Props) {
  return (
    <div
      className="text-input-container"
      style={{
        flexDirection: column ? "column" : "row",
        width: width ? width : "100%",
      }}
    >
      <label
        htmlFor={title + "-input"}
        className="text-input-label"
        style={{ color: color ? color : "var(--primary-700)" }}
      >
        {title}
      </label>
      <div className="text-input-container">
        <select
          id={title + "-input"}
          value={value}
          onChange={(e) => setValue(e.target.value)}
          className="text-input"
          onBlur={onBlur}
        >
          <option value="" disabled defaultChecked>
            selecteer een optie
          </option>

          {options.map((option) => (
            <option value={option.value} key={title + option.value}>
              {option.title}
            </option>
          ))}
        </select>
        {value && showError && (
          <SmallText color="var(--red-500)">{errorMessage}</SmallText>
        )}
      </div>
    </div>
  );
}

export default RadioSelectionMenu;
