import {
  Envelope,
  FacebookLogo,
  InstagramLogo,
} from "@phosphor-icons/react";
import React from "react";

type Props = {};

function Socials({}: Props) {
  return (
    <div className="socials-container">
      <a href="https://www.instagram.com/aqualifebelgium/" target="_blank">
        <InstagramLogo size={20} /> aqualifebelgium
      </a>
      <a href="https://www.facebook.com/AqualifeBelgium" target="_blank">
        <FacebookLogo size={20} /> Aqualife Aquarium
      </a>
      <a href="mailto:raf@aqualife-nv.be" target="_blank">
        <Envelope size={20} /> info@aqualife-nv.be
      </a>
    </div>
  );
}

export default Socials;
