export type ProductData = {
  id: string;
  name: string;
  description: string;
  category: ProductCategory[];
  price: number;
  discountReseller1: number;
  discountReseller2: number;
  pricePerUnit: PricePerUnitOptions;
  isRecommanded: boolean;
  recommandedText: string;
  image: string;
  dimensionsDependant: DimensionsDependant;
  volumeDependant: VolumeDependant;
};

export enum PricePerUnitOptions {
  perPiece = "perPiece",
  perSquareMeter = "perSquareMeter",
  perLiter = "perLiter",
  perKilo = "perKilo",
}

export type DimensionsDependant = {
  minLenght: number | null;
  maxLenght: number | null;
  minWidth: number | null;
  maxWidth: number | null;
  minHeight: number | null;
  maxHeight: number | null;
};

export type VolumeDependant = {
  minVolume: number | null;
  maxVolume: number | null;
};

export enum ProductCategory {
  boosterPumpsFreshwater = "boosterpumpsFreshwater",
  boosterPumpsSaltwater = "boosterpumpsSaltwater",
  filtersFreshwater = "freshwaterFilters",
  LEDFreshwater = "freshwaterLEDs",
  LEDSaltwater = "saltwaterLEDs",
  LEDTurtles = "turtlesLEDs",
  heatingFreshwater = "freshwaterHeating",
  heatingSaltwater = "saltwaterHeating",
  circulationPumpsSaltwater = "circulationPumpsSaltwater",
  proteinSkimmersSaltwater = "proteinSkimmersSaltwater",
  automaticRefillSystemsSaltwater = "automaticRefillSystemsSaltwater",
  co2SystemFreshwater = "CO2-systemFreshwater",
  interiorFreshwater = "interiorFreshwater",
  interiorSaltwater = "interiorSaltwater",
  interiorTurtles = "interiorTurtles",

  textures = "textures",
  // glass = "glass",
}
