import React from "react";
import { BigText, SmallText, Text } from "../atoms/StyledComponents";
import { getPrice } from "../aquariumComponents/priceCalculator";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";

type Props = {};

function PriceMenu({}: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  let price = Math.round(getPrice(aquarium) * 100) / 100;

  return (
    <div id="price-menu-container">
      {/* <Text id="first-price">€ 185,88</Text> */}
      {/* <Text id="discount-amount">- € 5,68</Text> */}
      <BigText id="final-price">€ {price.toFixed(2).toString().replace(".", ",")}</BigText>
    </div>
  );
}

export default PriceMenu;
