import React, { useEffect, useState } from "react";
import {
  changeLowerClosetHeight,
  changeUpperClosetHeight,
} from "../aquariumComponents/tank";
import { Text } from "../atoms/StyledComponents";
import { FurnitureDimensions, FurniturePart } from "../../@types/aquarium";
import IconToggle from "./IconToggle";
import DimensionSlider from "./DimensionSlider";
import { useSelector } from "react-redux";
import { RootState } from "../../redux/store";
import { useDebounce } from "use-debounce";
import { AllTextObject } from "../../@types/language";
const minHeightLower = 20;
const defaultHeightLower = 85;
const maxHeightLower = 135;
const minHeightTop = 32;
const defaultHeightTop = 45;
const maxHeightTop = 135;

type Props = {
  allText: AllTextObject
  furnitureDimensions: FurnitureDimensions;
  setFurnitureDimensions: React.Dispatch<
    React.SetStateAction<FurnitureDimensions>
  >;
};

function FurnitureHeightSliders({
  allText,
  setFurnitureDimensions,
  furnitureDimensions,
}: Props) {
  const aquarium = useSelector((state: RootState) => state.aquarium);
  const [actualValues, setActualValues] =
    useState<FurnitureDimensions>(furnitureDimensions);
  const [debounceValues] = useDebounce(actualValues, 500);

  useEffect(() => {
    setFurnitureDimensions(debounceValues);
  }, [debounceValues]);

  const updateShowTopSlider = (furnitureDimensions: FurnitureDimensions) => {
    return furnitureDimensions.topHeight > 0 ? true : false;
  };
  const [baseHeight, setBaseHeight] = useState<number>(
    furnitureDimensions.baseHeight
  );
  const [topHeight, setTopHeight] = useState<number>(
    furnitureDimensions.topHeight
  );

  const [showTopSlider, setShowTopSlider] = useState<boolean>(
    updateShowTopSlider(furnitureDimensions)
  );

  const correctFurnitureLowerHeight = (value: number): number => {
    if (value > maxHeightLower) {
      return maxHeightLower;
    } else if (value < minHeightLower) {
      return minHeightLower;
    } else {
      return value;
    }
  };
  const correctFurnitureUpperHeight = (value: number): number => {
    if (value > maxHeightTop) {
      return maxHeightTop;
    } else if (value === 0) {
      return 0;
    } else if (value < minHeightTop) {
      return minHeightTop;
    } else {
      return value;
    }
  };

  useEffect(() => {
    setBaseHeight(aquarium.furniture.dimensions.baseHeight);
    setTopHeight(aquarium.furniture.dimensions.topHeight);
  }, [aquarium]);

  useEffect(() => {
    changeLowerClosetHeight(correctFurnitureLowerHeight(baseHeight));
    updateFurnitureDimensions(
      correctFurnitureLowerHeight(baseHeight),
      FurniturePart.base
    );
  }, [baseHeight]);
  useEffect(() => {
    changeUpperClosetHeight(correctFurnitureUpperHeight(topHeight));
    updateFurnitureDimensions(
      correctFurnitureUpperHeight(topHeight),
      FurniturePart.top
    );
  }, [topHeight]);

  useEffect(() => {
    if (showTopSlider === false) {
      const newTopDimensions: FurnitureDimensions = {
        baseHeight: furnitureDimensions.baseHeight,
        topHeight: 0,
      };
      setActualValues(newTopDimensions);
      changeUpperClosetHeight(0);
    } else if (showTopSlider === true) {
      const newTopDimensions: FurnitureDimensions = {
        baseHeight: furnitureDimensions.baseHeight,
        topHeight: furnitureDimensions?.topHeight || defaultHeightTop,
      };
      setActualValues(newTopDimensions);
      changeUpperClosetHeight(furnitureDimensions.topHeight);
    }
  }, [showTopSlider]);

  const updateFurnitureDimensions = (value: number, part: FurniturePart) => {
    if (furnitureDimensions) {
      switch (part) {
        case FurniturePart.base:
          const correctLowerValue = correctFurnitureLowerHeight(value);
          const newBaseDimensions: FurnitureDimensions = {
            baseHeight: correctLowerValue,
            topHeight: furnitureDimensions.topHeight,
          };
          setActualValues(newBaseDimensions);
          break;
        case FurniturePart.top:
          const correctUpperValue = correctFurnitureUpperHeight(value);
          const newTopDimensions: FurnitureDimensions = {
            baseHeight: furnitureDimensions.baseHeight,
            topHeight: correctUpperValue,
          };
          setActualValues(newTopDimensions);
          break;
        default:
          break;
      }
    }
  };

  return (
    <div className="furniture-sliders-container">
      <div className="dimension-slider-container">
        <DimensionSlider
          title={allText.tid_st5_12}
          aquariumDimension={baseHeight}
          isValid={true}
          minValue={minHeightLower}
          maxValue={maxHeightLower}
          setValue={setBaseHeight}
          id="baseheight"
        />
      </div>
      <IconToggle
        checked={showTopSlider}
        setValue={setShowTopSlider}
        text={allText.tid_st5_14}
      />
      {showTopSlider && (
        <div className="dimension-slider-container">
          <DimensionSlider
            title={allText.tid_st5_13}
            aquariumDimension={topHeight}
            isValid={true}
            minValue={minHeightTop}
            maxValue={maxHeightTop}
            setValue={setTopHeight}
            id="topheight"
          />
        </div>
      )}
    </div>
  );
}

export default FurnitureHeightSliders;
